import { AxiosResponse } from 'axios';
import {
  apiPrinterModelsPath,
  apiPrinterPath,
  apiPrinterPrintPath,
  getVisitorBadgeImagePath,
} from './constants';
import { instance } from './client';
import { fileToBase64 } from '../utils';
import { IPrinterModel } from '../typings';
import { convertToSnakeCase, createPath } from '../utils';

export const getPrinter = (printerId: string): Promise<AxiosResponse> =>
  instance.client.get(createPath(apiPrinterPath, { printerId }));

export const updatePrinter = (
  printerId: string,
  data: object
): Promise<AxiosResponse> =>
  instance.client.put(
    createPath(apiPrinterPath, { printerId }),
    convertToSnakeCase(data)
  );

export const deletePrinter = (printerId: string): Promise<AxiosResponse> =>
  instance.client.delete(createPath(apiPrinterPath, { printerId }));

export const getPrinterModels = (): Promise<IPrinterModel[]> =>
  instance.client.get(createPath(apiPrinterModelsPath));

export const sendPrintRequest = (
  organizationId: number,
  imageData: string,
  printerId: string,
  pmaCode: string
): Promise<AxiosResponse> =>
  instance.client.post(
    createPath(apiPrinterPrintPath, { organizationId, printerId }),
    {
      deviceName: pmaCode,
      labelImageData: imageData.toString(),
    }
  );

export const getVisitorBadgeBlob = async (
  visitId: number,
  outputFormat: 'image' | 'pdf' = 'image',
  paperType: 'label' | 'a4' = 'label'
) => {
  const res = (await instance.client.get(
    createPath(getVisitorBadgeImagePath, { visitId }),
    {
      params: convertToSnakeCase({ outputFormat, paperType }),
      responseType: 'blob',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      convertResponseToCamelCase: false,
    }
  )) as Blob;

  return res;
};

export const getVisitorBadgeImageBase64 = async (visitId: number) => {
  const imageBlob = await getVisitorBadgeBlob(visitId);
  const base64Image = await fileToBase64(imageBlob);
  if (!base64Image) throw new Error('Failed to print visitor badge!');
  return base64Image;
};

export const getVisitorBadgePdfObjectUrl = async (visitId: number) => {
  const badgeBlob = await getVisitorBadgeBlob(visitId, 'pdf', 'a4');
  const pdfUrl = URL.createObjectURL(badgeBlob);
  return pdfUrl;
};
