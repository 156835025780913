import { useEffect } from 'react';
import {
  useProfileContext,
  useRouter,
  useProcessDataContext,
  ProcessType,
} from '@datapeace/vms-web-models';
import {
  BUTTON_TEXT_MAX_LENGTH,
  ROUTES,
  VMS_MODES,
  getRestrictedVMSModeFromStorage,
  getSettingsFromLocalStorage,
  ISettings,
  saveSettingsIntoLocalStorage,
} from '@datapeace/vms-web-utils';
import {
  getRestrictedModeFromStorage,
  trimExtraText,
} from '@datapeace/1up-frontend-shared-api';
import { useConfig } from '@datapeace/vms-web-hooks';

export const useWelcomeContainer = () => {
  const { setCurrentRoute } = useRouter();
  const { email } = useProfileContext();
  const {
    portalLogoUrl,
    checkinButtonLabel,
    checkoutButtonLabel,
    checkoutEnabled,
    portalTitle,
    visitInvitationEnabled,
    isMobilePlan,
    currentOrganization,
    visitorAppEnabled,
  } = useConfig();

  const { handleEnteredWelcomeScreen, setProcessData } =
    useProcessDataContext();
  let { vmsMode = VMS_MODES.NORMAL } = getSettingsFromLocalStorage(email);
  const restrictedMode = getRestrictedModeFromStorage();
  const restrictedModeVMSMode = getRestrictedVMSModeFromStorage();
  const features = currentOrganization?.features;
  if (
    (vmsMode === 'AUTO_CAPTURE' && !features?.spacesVmsAppAutoCaptureMode) ||
    (vmsMode === 'CONTACT_LESS' && !features?.spacesVmsAppContactlessMode) ||
    (vmsMode === 'QR_ONLY' && !features?.spacesVmsAppInvitedOnlyMode) ||
    (vmsMode === 'QUICK' && !features?.spacesVmsAppQuickMode) ||
    (restrictedMode && restrictedModeVMSMode === 'NORMAL')
  ) {
    vmsMode = VMS_MODES.NORMAL;
    saveSettingsIntoLocalStorage(email, {
      ...(getSettingsFromLocalStorage(email) as ISettings),
      vmsMode: VMS_MODES.NORMAL,
    });
  }

  useEffect(() => handleEnteredWelcomeScreen(), [handleEnteredWelcomeScreen]);

  const handleSelectProcessType = (processType: ProcessType) => {
    setProcessData({ processType });
    if (isMobilePlan) {
      setCurrentRoute(ROUTES.CHECKIN_BY_MOBILE);
    } else {
      setCurrentRoute(ROUTES.CAPTURE);
    }
  };

  const handleQrButtonClick = () => {
    setProcessData({ isUsingQr: true });
    setCurrentRoute(ROUTES.CAPTURE);
  };

  useEffect(() => {
    if (vmsMode === 'CONTACT_LESS' && visitorAppEnabled) {
      setCurrentRoute(ROUTES.CONTACT_LESS_WELCOME);
      return;
    }

    if (vmsMode === 'QUICK' && isMobilePlan) {
      setCurrentRoute(ROUTES.CHECKIN_BY_MOBILE);
      return;
    }

    if (
      (
        [VMS_MODES.AUTO_CAPTURE, VMS_MODES.QUICK, VMS_MODES.QR_ONLY] as string[]
      ).includes(vmsMode)
    ) {
      setProcessData({
        isUsingQr: vmsMode === VMS_MODES.QR_ONLY && visitInvitationEnabled,
      });
      setCurrentRoute(ROUTES.CAPTURE);
    }
  });

  return {
    portalTitle,
    portalLogoUrl,
    checkinButtonLabel: trimExtraText(
      checkinButtonLabel,
      BUTTON_TEXT_MAX_LENGTH
    ),
    checkoutButtonLabel: trimExtraText(
      checkoutButtonLabel,
      BUTTON_TEXT_MAX_LENGTH
    ),
    checkoutEnabled,
    handleCheckinSelect: () => handleSelectProcessType(ProcessType.Checkin),
    handleCheckoutSelect: () => handleSelectProcessType(ProcessType.Checkout),
    onSettingsButtonClick: () => setCurrentRoute(ROUTES.SETTINGS),
    onQrButtonClick: visitInvitationEnabled && handleQrButtonClick,
  };
};
