import {
  getVMSConfig,
  getProfile,
  getVisitorByFace,
  getVisitorByMobile,
  getMyOrganizations,
  sendVisitRequestOTP as sendVisitRequestOTPApi,
  verifyVisitRequestOTP as verifyVisitRequestOTPApi,
  verifyInvitation,
  separateCallingCodeAndPhone,
  verifyVisitById,
  checkinVisitorByVisitRequest,
  checkoutVisitorByVisitRequest,
  createVisitorVisitRequest,
  getVisitorVisitRequest,
  waitForTemperatureRecord,
  uploadFileInVmsApp,
  sendVisitorOTP as sendVisitorOTPApi,
  resendVisitorOTP as resendVisitorOTPApi,
  verifyVisitorOTP,
  sendOtpSpaceVisitor,
  verifyOtpSpaceVisitor,
  getSpaceVisitorsByVerifiedMobileNumber,
  getSpaceVisitor,
} from '@datapeace/1up-frontend-shared-api';
import { UnsupportedCallingCodeError } from './error';

const validateMobileNumber = async (
  mobile: string,
  orgCallingCode: string,
  isMobilePlan: boolean
) => {
  const [prefix] = separateCallingCodeAndPhone(mobile);

  // throw error if country code is other than orgCallingCode (not set as calling code in organization settings)
  if (orgCallingCode && prefix !== orgCallingCode && !isMobilePlan) {
    throw new UnsupportedCallingCodeError(
      `Country code (${prefix}) is not supported!`
    );
  }
};

const sendVisitRequestOTP = async (
  isMobilePlan: boolean,
  visitRequestId: number,
  mobile: string,
  orgCallingCode = '',
  currentProcessType: 'checkin' | 'checkout'
) => {
  await validateMobileNumber(mobile, orgCallingCode, isMobilePlan);
  return sendVisitRequestOTPApi(visitRequestId, mobile, currentProcessType);
};

const resendVisitRequestOTP = async (
  isMobilePlan: boolean,
  visitRequestId: number,
  mobile: string,
  orgCallingCode = '',
  ticketId: string,
  currentProcessType?: 'checkin' | 'checkout'
) => {
  await validateMobileNumber(mobile, orgCallingCode, isMobilePlan);

  return sendVisitRequestOTPApi(
    visitRequestId,
    mobile,
    currentProcessType,
    true,
    ticketId
  );
};

const verifyVisitRequestOTP = async (
  visitRequestId: number,
  mobile: string,
  otp: string,
  ticketId: string,
  currentProcessType?: 'checkin' | 'checkout'
) => {
  await verifyVisitRequestOTPApi(
    visitRequestId,
    mobile,
    otp,
    ticketId,
    currentProcessType
  );
};

const sendVisitorOTP = async (
  isMobilePlan: boolean,
  spaceId: number,
  mobile: string,
  orgCallingCode = '',
  otpType?: 'checkin' | 'checkout'
) => {
  await validateMobileNumber(mobile, orgCallingCode, isMobilePlan);

  return sendVisitorOTPApi(spaceId, mobile, otpType);
};

const resendVisitorOTP = async (
  isMobilePlan: boolean,
  spaceId: number,
  mobile: string,
  orgCallingCode = '',
  otpType?: 'checkin' | 'checkout'
) => {
  await validateMobileNumber(mobile, orgCallingCode, isMobilePlan);

  return resendVisitorOTPApi(spaceId, mobile, otpType);
};

const sendSpaceVisitorOTP = async (
  isMobilePlan: boolean,
  spaceId: number,
  mobile: string,
  orgCallingCode = ''
) => {
  await validateMobileNumber(mobile, orgCallingCode, isMobilePlan);
  return sendOtpSpaceVisitor({
    spaceId,
    mobile,
    resend: false,
    ticketId: undefined,
  });
};

const resendSpaceVisitorOTP = async (
  isMobilePlan: boolean,
  spaceId: number,
  mobile: string,
  orgCallingCode = '',
  ticketId: string
) => {
  await validateMobileNumber(mobile, orgCallingCode, isMobilePlan);
  return sendOtpSpaceVisitor({ spaceId, mobile, resend: true, ticketId });
};

const verifySpaceVisitorOTP = async (
  spaceId: number,
  mobile: string,
  otp: string,
  ticketId: string
) => {
  await verifyOtpSpaceVisitor({ mobile, otp, spaceId, ticketId });
};

const Api = {
  getConfig: getVMSConfig,
  getProfile,
  searchByFace: getVisitorByFace,
  getVisitorByMobile,
  sendVisitRequestOTP,
  resendVisitRequestOTP,
  verifyVisitRequestOTP,
  sendVisitorOTP,
  resendVisitorOTP,
  verifyVisitorOTP,
  getOrganizations: getMyOrganizations,
  verifyInvitation,
  verifyVisitById,
  checkinVisitorByVisitRequest,
  checkoutVisitorByVisitRequest,
  createVisitorVisitRequest,
  getVisitorVisitRequest,
  waitForTemperatureRecord,
  uploadFileInVmsApp,
  sendSpaceVisitorOTP,
  resendSpaceVisitorOTP,
  verifySpaceVisitorOTP,
  getSpaceVisitorsByVerifiedMobileNumber,
  getSpaceVisitor,
};

export { Api };
