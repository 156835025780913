/* eslint-disable @typescript-eslint/no-explicit-any */
import { asyncDelay, createPath } from '../utils';
import { instance } from './client';
import { apiTemperatureDeviceNewRecordPath } from './constants';

export const waitForTemperatureRecord = async (
  temperatureDeviceId: number,
  timeoutInSeconds = 5
) => {
  if (timeoutInSeconds < 3) {
    throw new Error('temperature timeout should be atleast 3 seconds');
  }

  const startTime = new Date();
  const endTimeMs = +startTime + timeoutInSeconds * 1000;

  let lastException;

  // keep making api call till timed out
  while (Date.now() < endTimeMs) {
    try {
      // return with data if resolved successfully
      return await instance.client.get<{
        bodyTemp: string;
        bodyTempUnit: 'C' | 'F';
      }>(
        createPath(apiTemperatureDeviceNewRecordPath, {
          deviceId: temperatureDeviceId,
        }),
        { params: { since: startTime.toISOString() } }
      );
    } catch (err) {
      if ((err as any)?.response?.status !== 400) {
        throw err; // throw if statuscode not 400
      }

      // suppress and store error if status code is 400
      lastException = err;
    }

    await asyncDelay(2000);
  }

  // if timed out, throw last api error
  throw lastException;
};
