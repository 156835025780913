import { useMemo, useCallback, useEffect, useRef } from 'react';
import { IFormField, IFormFieldData } from '@datapeace/1up-frontend-shared-api';
import { generateContext } from '../../utils/generate-context';

export const [GroupInputContext, useGroupInputContext] = generateContext<{
  fields: IFormField[];
  value: {
    data: IFormFieldData[];
  }[];
  handleAdd: () => void;
  handleChange: (index: number, val: IFormFieldData[]) => void;
  settings: {
    minItemsLimit: number;
    maxItemsLimit: number;
  };
  handleRemove: (index: number) => void;
}>();

interface Props {
  name: string;
  children: JSX.Element;
  fields: IFormField[];
  settings: {
    minItemsLimit: number;
    maxItemsLimit: number;
  };
  value: {
    data: IFormFieldData[];
  }[];
  onChange: (
    name: string,
    value: {
      data: IFormFieldData[];
    }[]
  ) => void;
}

export function GroupInputProvider({
  name,
  children,
  fields,
  settings,
  value,
  onChange,
}: Props) {
  // Initialize
  const initialValueRef = useRef(value || []);
  const onChangeRef = useRef(onChange);
  const fieldsRef = useRef(fields);
  const minItemsRef = useRef(settings.minItemsLimit);
  const nameRef = useRef(name);
  useEffect(() => {
    while (initialValueRef.current.length < minItemsRef.current) {
      initialValueRef.current.push({
        data: fieldsRef.current.map((field) => ({
          name: field.name,
          type: field.type,
          label: field.label,
          value: [{ key: null, label: '' }],
        })),
      });
    }
    onChangeRef.current(nameRef.current, initialValueRef.current);
  }, []);

  const handleAdd = useCallback(() => {
    onChangeRef.current(nameRef.current, [
      ...value,
      {
        data: fields.map((field) => ({
          name: field.name,
          type: field.type,
          label: field.label,
          value: [{ key: null, label: '' }],
        })),
      },
    ]);
  }, [fields, value]);

  const handleChange = useCallback(
    (index: number, val: IFormFieldData[]) => {
      const updatedValue = [...value];
      updatedValue[index] = { data: val };
      onChangeRef.current(nameRef.current, updatedValue);
    },
    [value]
  );

  const handleRemove = useCallback(
    (index: number) => {
      const updatedValue = [...value];
      updatedValue.splice(index, 1);
      onChangeRef.current(nameRef.current, updatedValue);
    },
    [value]
  );

  const contextValues = useMemo(
    () => ({
      fields,
      value,
      settings,
      handleAdd,
      handleChange,
      handleRemove,
    }),
    [fields, value, settings, handleAdd, handleChange, handleRemove]
  );
  return (
    <GroupInputContext.Provider value={contextValues}>
      {children}
    </GroupInputContext.Provider>
  );
}
