import { FC, FormEvent, useState } from 'react';
import { useCustomFormContainer } from '@datapeace/vms-web-containers';
import {
  Layout,
  Header,
  Content,
  NavButtons,
  Form,
  DynamicForm,
} from '@datapeace/vms-web-components';
import { Api } from '@datapeace/vms-web-utils';
import { useProcessDataContext } from '@datapeace/vms-web-models';
import * as styles from './custom-form-screen.module.scss';

export const CustomForm: FC = () => {
  const { setFormData, onSubmit, values, visibleFields, handleGoBack } =
    useCustomFormContainer();
  const [isDisabled, setIsDisabled] = useState(false);

  const { visitRequestId } = useProcessDataContext();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <Layout>
      <Header />

      <Content>
        <div className={styles.Form}>
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <DynamicForm
              values={values}
              items={visibleFields}
              onUploadFile={async (file: File) => {
                setIsDisabled(true);
                try {
                  if (visitRequestId) {
                    return await Api.uploadFileInVmsApp(visitRequestId, {
                      file,
                      type: 'web',
                    });
                  }
                } catch (error) {
                  console.error(error);
                } finally {
                  setIsDisabled(false);
                }
                return {
                  fileUrl: '',
                };
              }}
              onChange={setFormData}
            />
            <NavButtons onBack={handleGoBack} isDisabled={isDisabled} />
          </Form>
        </div>
      </Content>
    </Layout>
  );
};
