import React, { useEffect, useState } from 'react';
import {
  configure,
  getCountriesData,
  getDeviceFingerprint,
  getRestrictedModeFromStorage,
  instance,
  removeDeprecatedDeviceFingerprintId,
  storage,
} from '@datapeace/1up-frontend-shared-api';
import * as Sentry from '@sentry/react';
import {
  API_BASE_URL,
  ENVIRONMENT,
  VMS_LOGGED_OUT_EVENT,
} from '@datapeace/vms-web-utils';
import { Loader } from '@datapeace/1up-frontend-web-ui';
import * as serviceWorker from '../service-worker';

Sentry.configureScope(async (scope) => {
  scope.setUser({
    username: await getDeviceFingerprint(),
    deviceId: await getDeviceFingerprint(),
  });
});

configure({
  apiBaseURL: API_BASE_URL,
  siteId: 'web-vms',
  siteVersion: __VERSION__,
  onLogout: () => window.dispatchEvent(new Event(VMS_LOGGED_OUT_EVENT)),
  deviceIdGenerator: getDeviceFingerprint,
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

export function AppInitializerContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  const isRestrictedMode = getRestrictedModeFromStorage();
  const [isInitialized, setIsInitialized] = useState(false);

  // if not developing locally, remove any old auth token. should refetch new token on refresh
  if (ENVIRONMENT !== 'local' && !isRestrictedMode) {
    instance.auth.removeAccessToken();
    instance.auth.removeRefreshToken();
  }

  useEffect(() => {
    async function initialize() {
      const authToken = await storage.getItem('auth_token');
      if (authToken) {
        await storage.setItem('access_token', authToken);
        await storage.removeItem('auth_token');
      }
      setIsInitialized(true);
    }
    initialize();
    // remove the deprecated device fingerprint key
    removeDeprecatedDeviceFingerprintId();
    // fetch country codes data for caching
    getCountriesData().catch(() => null);
  }, []);

  if (!isInitialized) return <Loader text="Loading" />;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
