import { snakeCase } from 'lodash-es';

export {
  isEqual,
  isObject,
  transform,
  flattenDeep,
  isArray,
  camelCase,
  reduce,
  snakeCase,
  capitalize,
  debounce,
  round,
  xorBy,
  without,
} from 'lodash-es';

export function startCase(text: string) {
  return `${(text[0] || '').toUpperCase()}${text.substr(1)}`;
}

/** converts string to snake case only if it's in camel case (doesn't include underscores) */
export function camelToSnakeCase(text: string) {
  if (text.includes('_')) {
    // if contains udnerscore i.e already snakecase, return as it is
    return text;
  }

  return snakeCase(text);
}
