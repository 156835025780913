import { Button } from '@datapeace/1up-frontend-web-ui';
import { ReloadOutlined } from '@ant-design/icons';
import { CustomStatusCard } from '@datapeace/vms-web-components';
import { useTemperatureContainer } from '@datapeace/vms-web-containers';

export function Temperature() {
  const { status, message, onSubmit, fetchTemperature, handleGoBack } =
    useTemperatureContainer();

  const actions = [
    <Button icon={<ReloadOutlined />} onClick={fetchTemperature}>
      Retry
    </Button>,
  ];

  return (
    <CustomStatusCard
      avatar="/temperature.gif"
      meta="Please place your hands in-front of thermometer device"
      status={status}
      message={message}
      actions={status === 'error' ? actions : undefined}
      handleGoBack={status !== 'loading' ? handleGoBack : undefined}
      showNext
      onNext={onSubmit}
    />
  );
}
