import { createContext, useContext } from 'react';
import { IProfile } from '@datapeace/1up-frontend-shared-api';

const ProfileContext = createContext<IProfile | null>(null);

export const ProfileProvider = ProfileContext.Provider;

export const useProfileContext = () => {
  const profile = useContext(ProfileContext);

  if (!profile) {
    throw new Error("ProfileContext should be consumed inside it's Provider");
  }

  return profile;
};
