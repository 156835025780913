import { separateCallingCodeAndPhone } from '@datapeace/1up-frontend-shared-api';
import { ProcessType } from '../models/process-data-provider';

interface IOtpRequiredFields {
  isCheckinOtpRequired: boolean;
  isCheckoutOtpRequired: boolean;
}

export type ICustomErrorScreen = {
  title: string;
  displayText?: string | React.ReactNode;
} | null;

export const FREQUENCY_OPTIONS = {
  never: 'NEVER',
  once: 'ONCE',
  always: 'ALWAYS',
};

export const checkIfVerificationRequired = (
  isMobilePlan: boolean,
  checkinVerified: boolean | null,
  checkoutVerified: boolean | null,
  processType: ProcessType | null,
  { isCheckinOtpRequired, isCheckoutOtpRequired }: IOtpRequiredFields,
  isMobilePlanQuickMode: boolean,
  mobileNumber: string,
  supportedCallingCodes: string[]
) => {
  const [prefix] = separateCallingCodeAndPhone(mobileNumber);

  if (
    isMobilePlan &&
    mobileNumber &&
    !supportedCallingCodes?.includes(prefix)
  ) {
    // in verification not required if isUnsupportedCallingCode
    return false;
  }

  if (
    (processType === ProcessType.Checkin && checkinVerified) ||
    (processType === ProcessType.Checkout && checkoutVerified)
  ) {
    // in verification not required if already verfied in previous step
    return false;
  }

  switch (processType) {
    // During checkin
    case ProcessType.Checkin:
      return isCheckinOtpRequired;

    // During checkout
    case ProcessType.Checkout:
      return isCheckoutOtpRequired;

    default:
      return isMobilePlanQuickMode ? isCheckinOtpRequired : true;
  }
};
