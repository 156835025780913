import { StatusCard, StatusCardProps } from '@datapeace/1up-frontend-web-ui';
import {
  Layout,
  Content,
  Header,
  NavButtons,
} from '@datapeace/vms-web-components';
import styles from './status-error-card.module.scss';
interface IStatusErrorCard
  extends Pick<
    StatusCardProps,
    'title' | 'meta' | 'actions' | 'message' | 'avatar' | 'children' | 'status'
  > {
  content?: StatusCardProps['children'];
  handleGoBack?: () => void;
  showNext?: boolean;
  onNext?: () => void;
}
export const CustomStatusCard = ({
  title,
  avatar,
  content,
  message,
  meta,
  actions,
  handleGoBack,
  children,
  status,
  showNext = false,
  onNext,
}: IStatusErrorCard) => {
  return (
    <Layout>
      <Header />
      <Content>
        <div className={styles.StatusDiv}>
          <StatusCard
            highlight
            status={status}
            title={title}
            meta={meta}
            actions={actions}
            message={message}
            avatar={avatar}
          >
            {content}
          </StatusCard>
          {children}
          {handleGoBack && (
            <NavButtons
              showNext={showNext}
              onBack={handleGoBack}
              onNext={onNext}
            />
          )}
        </div>
      </Content>
    </Layout>
  );
};
