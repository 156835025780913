import { useState } from 'react';
import {
  IInvitationVerified,
  IVisitVerify,
} from '@datapeace/1up-frontend-shared-api';
import {
  IBlobWithDataURL,
  useIsMounted,
} from '@datapeace/1up-frontend-web-utils';
import { IErrorResponse, toast } from '@datapeace/1up-frontend-web-ui';
import {
  useProfileContext,
  useRouter,
  useProcessDataContext,
  ProcessType,
} from '@datapeace/vms-web-models';
import {
  getSettingsFromLocalStorage,
  ROUTES,
  INVITATION_CODE_PREFIX,
  VISIT_QR_CODE_PREFIX,
  Api,
} from '@datapeace/vms-web-utils';
import { useConfig } from '@datapeace/vms-web-hooks';

export const useContactLessWelcomeContainer = () => {
  const { setCurrentRoute } = useRouter();
  const { email } = useProfileContext();
  const { currentSpace, portalTitle } = useConfig();
  const isMobilePlan = true; // temporarily supports only mobile plan

  const isMountedRef = useIsMounted();

  const { setProcessData } = useProcessDataContext();
  const { compressImage, detectionDistance, detectionScoreThreshold } =
    getSettingsFromLocalStorage(email);

  const [capturedImage, setCapturedImage] = useState<IBlobWithDataURL | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleFaceCapture = async (
    capturedImageData: IBlobWithDataURL | null
  ) => {
    if (!currentSpace?.id) return;

    try {
      setIsLoading(true);

      if (!capturedImageData) {
        throw new Error('Failed to capture! Please try again');
      }

      setCapturedImage(capturedImageData);

      const { fileUrl, people, activeVisit } = await Api.searchByFace(
        currentSpace?.id,
        {
          faceImage: capturedImageData,
          type: 'web',
        }
      );
      if (!isMountedRef.current) return;

      if (!people) {
        throw new Error(
          'Face not recognized! Please scan QR for one time registration.'
        );
      }

      const processType = activeVisit
        ? ProcessType.Checkout
        : ProcessType.Checkin;

      setProcessData({
        faceData: {
          imageUrl: fileUrl,
          dataUrl: capturedImageData.dataURL,
        },
        processType,
        activeVisit,
        personData: { ...people },
        registerData: people,
        // isTemperatureRequired
      });

      if (activeVisit && activeVisit.visitInvitation) {
        setProcessData({
          invitationId: activeVisit.visitInvitation.id,
          visitInvitation: activeVisit.visitInvitation,
        });
      }

      setCurrentRoute(ROUTES.TEMPERATURE);
    } catch (err) {
      setIsLoading(false);
      setCapturedImage(null);
      toast.error(err as IErrorResponse);
    }
  };

  const handleVisitQrFound = (res: IInvitationVerified | IVisitVerify) => {
    try {
      const {
        visit,
        visitRequiredFields,
        visitStateFlow,
        people: personData,
      } = res;
      const {
        isCheckinFaceRequired,
        isCheckoutFaceRequired,
        isTemperatureRequired,
      } = visitRequiredFields;
      const { firstName, lastName, mobileNumber, peopleId, visitRequestId } =
        visit;
      const { nextAllowedState, error } = visitStateFlow;
      const visitInvitation =
        (res as IInvitationVerified)?.visitInvitation || null;
      if (error) {
        throw new Error(error.message);
      }

      if (
        !(nextAllowedState === 'checkin' || nextAllowedState === 'checkout')
      ) {
        throw new Error('Visit QR is invalid or has expired!');
      }

      if (visitInvitation?.preCheckinFlow === false) {
        throw new Error(
          'This invitation is not supported in contactless mode. Please contact the concerned person.'
        );
      }

      const isCheckinProcess = nextAllowedState === 'checkin';
      const processType = isCheckinProcess
        ? ProcessType.Checkin
        : ProcessType.Checkout;
      const people = { firstName, lastName, mobileNumber, id: peopleId };

      if (
        isCheckinProcess &&
        visit?.visitInvitation &&
        !visit?.visitInvitation?.checkinAllowedNow
      ) {
        throw new Error(
          'You are not allowed to visit now. Please visit at your scheduled invited time'
        );
      }

      setProcessData({
        isUsingQr: true,
        processType,
        personData: { ...personData, ...people },
        registerData: people,
        activeVisit: !isCheckinProcess ? visit : null,
        visitRequestId,
        isTemperatureRequired,
        visitInvitation,
      });

      const isFaceRequired =
        processType === ProcessType.Checkin
          ? isCheckinFaceRequired
          : isCheckoutFaceRequired;

      if (isFaceRequired) {
        setCurrentRoute(ROUTES.CAPTURE);
        return;
      }

      setCurrentRoute(ROUTES.TEMPERATURE);
    } catch (err) {
      console.error(err);
      toast.error(err as IErrorResponse);
    }
    setIsLoading(false);
  };

  const handleQrFound = async (qrContent?: string) => {
    if (!currentSpace?.id || isLoading) return;
    try {
      setIsLoading(true);
      if (qrContent?.startsWith(INVITATION_CODE_PREFIX)) {
        const invitationCode = qrContent.substring(
          INVITATION_CODE_PREFIX.length
        );
        const res = await Api.verifyInvitation(currentSpace.id, {
          invitationCode,
        });
        if (!isMountedRef.current) return;
        handleVisitQrFound(res);
      }
      if (qrContent?.startsWith(VISIT_QR_CODE_PREFIX)) {
        const visitId = qrContent.substring(VISIT_QR_CODE_PREFIX.length);
        const res = await Api.verifyVisitById(visitId);
        if (res.space?.id !== currentSpace.id) {
          throw new Error('Visit QR is invalid or has expired!');
        }
        if (!isMountedRef.current) return;
        handleVisitQrFound(res);
      }
    } catch (err) {
      console.error(err);
      if (!isMountedRef.current) return;
      toast.error(err as IErrorResponse);
    }
    setIsLoading(false);
  };

  return {
    portalTitle,
    onSettingsButtonClick: () => setCurrentRoute(ROUTES.SETTINGS),
    detectionDistance,
    detectionScoreThreshold,
    onFaceCapture: !isMobilePlan ? handleFaceCapture : null,
    onQrFound: handleQrFound,
    quality: compressImage ? 0.7 : 0.92,
    capturedImage,
    isLoading,
  };
};
