import { FC } from 'react';
import {
  Layout,
  Content,
  Header,
  NavButtons,
  Form,
} from '@datapeace/vms-web-components';
import { useVisitorSelectionContainer } from '@datapeace/vms-web-containers';
import {
  Avatar,
  Button,
  List,
  Typography,
} from '@datapeace/1up-frontend-web-ui';
import styles from './visitor-selection-screen.module.scss';

export const VisitorSelection: FC = () => {
  const {
    visitorsOption,
    mobileNumber,
    handleSelectVisitor,
    handleGoBack,
    handlContinueAsNewVisitor,
  } = useVisitorSelectionContainer();

  return (
    <Layout>
      <Header />
      <Content>
        {visitorsOption && visitorsOption?.length > 0 && (
          <Form className={styles.Form}>
            <Typography.Title level={4}>
              We found multiple profiles with the mobile number&nbsp;
              {mobileNumber}
            </Typography.Title>
            <Typography.Title level={5}>
              Select one of the following profile to proceed
            </Typography.Title>
            <div
              id="scrollableDiv"
              style={{
                height: 400,
                overflow: 'auto',
                padding: '0 16px',
                border: '1px solid rgba(140, 140, 140, 0.35)',
              }}
            >
              <List
                dataSource={visitorsOption}
                renderItem={(item) => (
                  <List.Item key={item.email}>
                    <List.Item.Meta
                      avatar={<Avatar src={item.pictureUrl} />}
                      title={item.fullName}
                      description={item.email}
                    />
                    <Button onClick={() => handleSelectVisitor(item)}>
                      Select
                    </Button>
                  </List.Item>
                )}
              />
            </div>
            <NavButtons showNext={false} onBack={handleGoBack} />
          </Form>
        )}
        {visitorsOption?.length === 0 && (
          <Form className={styles.Form}>
            <Typography.Title level={4}>
              No profile found with the mobile number&nbsp;
              {mobileNumber}
            </Typography.Title>
            <Button
              type="primary"
              onClick={() => handlContinueAsNewVisitor()}
              className={styles.VisitorBtn}
            >
              Continue as new visitor
            </Button>
            <NavButtons showNext={false} onBack={handleGoBack} />
          </Form>
        )}
      </Content>
    </Layout>
  );
};
