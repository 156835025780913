import { useState, useEffect } from 'react';

export function useIsOnline() {
  // if navigator.onLine is not supported (undefined) then consider as online
  const [isOnline, setIsOnline] = useState(navigator.onLine !== false);

  useEffect(() => {
    const onOnline = () => setIsOnline(true);
    const onOffline = () => setIsOnline(false);

    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);

    return () => {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  }, []);

  return isOnline;
}
