import React from 'react';

export function generateContext<T>() {
  const context = React.createContext<T | undefined>(undefined);
  function useContext() {
    const contextValues = React.useContext(context);
    if (contextValues === undefined) {
      throw new Error("Context being used outside it's provider!");
    }
    return contextValues as Exclude<T, undefined>;
  }

  return [context, useContext] as const;
}
