import { ROUTES } from '@datapeace/vms-web-utils';
import {
  Welcome,
  ContactLessWelcome,
  Capture,
  Register,
  MobileVerification,
  CustomForm,
  ScreeningForm,
  Temperature,
  Confirm,
  CheckinByMobile,
  VisitErrorScreen,
  VisitorSelection,
  VaccinationForm,
  Terms,
  Settings,
} from '@datapeace/vms-web-screens';
import { useRouter } from '@datapeace/vms-web-models';

const routes = {
  [ROUTES.HOME]: Welcome,
  [ROUTES.CONTACT_LESS_WELCOME]: ContactLessWelcome,
  [ROUTES.CAPTURE]: Capture,
  [ROUTES.REGISTER]: Register,
  [ROUTES.MOBILE_VERIFY]: MobileVerification,
  [ROUTES.CHECK_IN_FORM]: CustomForm,
  [ROUTES.SCREENING_FORM]: ScreeningForm,
  [ROUTES.VACCINATION_FORM]: VaccinationForm,
  [ROUTES.TERMS]: Terms,
  [ROUTES.TEMPERATURE]: Temperature,
  [ROUTES.CHECK_IN_SUCCESS]: Confirm,
  [ROUTES.CHECKIN_BY_MOBILE]: CheckinByMobile,
  [ROUTES.SETTINGS]: Settings,
  [ROUTES.VISIT_ERROR_SCREEN]: VisitErrorScreen,
  [ROUTES.VISITOR_SELECTION]: VisitorSelection,
};

export const Routes = () => {
  const { currentRoute } = useRouter();
  const RouteToRender = routes[currentRoute];

  return <RouteToRender />;
};
