/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useCallback, useRef } from 'react';
import { IErrorResponse, toast } from '@datapeace/1up-frontend-web-ui';
import {
  validatePhoneWithPrefix,
  getCountriesData,
  IVisit,
  convertToCamelCase,
} from '@datapeace/1up-frontend-shared-api';
import {
  useProcessDataContext,
  ProcessType,
  IRegisterData,
  useRouter,
  useProfileContext,
} from '@datapeace/vms-web-models';
import {
  getSettingsFromLocalStorage,
  Api,
  checkIfVerificationRequired,
  ROUTES,
  VMS_MODES,
  UnsupportedCallingCodeError,
  ICustomErrorScreen,
} from '@datapeace/vms-web-utils';
import { useConfig } from '@datapeace/vms-web-hooks';
import { startCase, useIsMounted } from '@datapeace/1up-frontend-web-utils';

export function useRegisterContainer() {
  const { setCurrentRoute } = useRouter();
  const { email } = useProfileContext();
  const config = useConfig();
  const { supportedCallingCodes } = config;
  const [customErrorScreen, setCustomErrorScreen] =
    useState<ICustomErrorScreen>(null);

  const {
    personData,
    registerData,
    processType,
    activeVisit,
    setProcessData,
    isUsingQr,
    faceData,
    notYouRegisterData,
    getBackNavigationRoute,
  } = useProcessDataContext();

  const isMountedRef = useIsMounted();

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<IRegisterData>({
    firstName: '',
    lastName: '',
    mobileNumber: '',
    ...personData,
    ...registerData,
    ...notYouRegisterData,
  });
  const [hasAutoSubmitted, setHasAutoSubmitted] = useState(false);

  const isCheckinProcess = processType === ProcessType.Checkin;

  const validateProcess = useCallback(
    (currentActiveVisit: IVisit | null) => {
      const shouldCheckout =
        currentActiveVisit?.nextAllowedState === 'checkout';

      // should not be already checked-in if checkin process
      if (
        isCheckinProcess &&
        (shouldCheckout || currentActiveVisit?.checkinAt)
      ) {
        throw new Error('You are already checked-in!');
      }

      // should already be checked-in if checkout process
      if (!isCheckinProcess && !shouldCheckout) {
        if (currentActiveVisit?.nextAllowedState === null) {
          if (currentActiveVisit.isCheckInApproved === false) {
            throw new Error('Your visit has been rejected');
          }

          if (currentActiveVisit.isCheckoutApprovalPending) {
            throw new Error(
              'Checkout approval is pending, Please contact the concerned person'
            );
          }

          if (currentActiveVisit.isCheckoutApproved === false) {
            throw new Error('Checkout approver has rejected your check-out.');
          }

          throw new Error(
            'You cannot do checkout. Please contact the concerned person.'
          );
        }

        throw new Error('You are not checked in!');
      }
    },
    [isCheckinProcess]
  );

  const sendOTPForMobileVerification = async (visitRequestId: number) => {
    const { currentSpace, isMobilePlan } = config;
    const { mobileNumber } = formData;
    if (currentSpace && visitRequestId) {
      const res = await Api.sendVisitRequestOTP(
        isMobilePlan,
        visitRequestId,
        mobileNumber,
        config.countryCallingCode,
        processType === ProcessType.Checkin ? 'checkin' : 'checkout'
      );
      setProcessData({ otpTicket: res.verificationTicket.id });
    }
  };

  async function getVisitRequest(visitRequestId: number) {
    const res = await Api.getVisitorVisitRequest(visitRequestId);
    if (!isMountedRef.current) return;
    const { vmsMode } = getSettingsFromLocalStorage(email);

    if (res) {
      const {
        id,
        visitRequiredFields,
        checkinVerified,
        checkoutVerified,
        visit: { visitInvitation },
      } = res;
      const {
        isCheckoutOtpRequired: isOutOTPRequired,
        isCheckinOtpRequired: isInOTPRequired,
        isScreeningRequired,
        isVaccinationVerificationRequired,
        isCheckinFormRequired,
        isCheckoutFormRequired,
        isTncRequired,
        isTemperatureRequired,
        vaccinationVerificationSkipAllowed,
        partiallyVaccinatedVisitorCheckinAllowed,
        isCheckinFaceRequired,
        isCheckoutFaceRequired,
      } = visitRequiredFields;
      setProcessData({
        visitRequestId: id,
        personData,
        isCheckinOtpRequired: isInOTPRequired,
        isCheckoutOtpRequired: isOutOTPRequired,
        checkinVerified,
        checkoutVerified,
        isScreeningRequired,
        isVaccinationVerificationRequired,
        vaccinationVerificationSkipAllowed,
        partiallyVaccinatedVisitorCheckinAllowed,
        shouldFillForm: isCheckinProcess
          ? isCheckinFormRequired
          : isCheckoutFormRequired,
        isTncRequired,
        isTemperatureRequired,
        visitInvitation,
        isFaceRequired:
          config.isMobilePlan && vmsMode !== VMS_MODES.QR_ONLY
            ? isCheckinProcess
              ? isCheckinFaceRequired
              : processType === 'Checkout'
              ? isCheckoutFaceRequired
              : false
            : false,
      });
      if (
        checkIfVerificationRequired(
          config.isMobilePlan,
          checkinVerified,
          checkoutVerified,
          processType,
          {
            isCheckinOtpRequired: isInOTPRequired,
            isCheckoutOtpRequired: isOutOTPRequired,
          },
          vmsMode === VMS_MODES.QUICK && config.isMobilePlan,
          formData?.mobileNumber,
          supportedCallingCodes
        )
      ) {
        await sendOTPForMobileVerification(id);
      }
      setCurrentRoute(ROUTES.MOBILE_VERIFY);
    }
  }

  const handleSubmit = async () => {
    const { currentSpace } = config;

    if (!currentSpace) {
      console.error('currentSpace not found in Register', currentSpace);
      return;
    }

    try {
      setIsLoading(true);
      if (activeVisit) {
        validateProcess(activeVisit);
      }

      const {
        firstName: firstNameRaw,
        lastName: lastNameRaw,
        mobileNumber,
      } = formData;

      const firstName = startCase(firstNameRaw);
      const lastName = startCase(lastNameRaw);

      setFormData({ ...formData, firstName, lastName });

      // VALIDATE FORM DATA

      if (!firstName) {
        throw new Error('Please enter your first name!');
      }

      if (!lastName) {
        throw new Error('Please enter your last name!');
      }

      const countriesData = await getCountriesData();
      if (!isMountedRef.current) return;
      if (
        !mobileNumber ||
        !validatePhoneWithPrefix(mobileNumber, countriesData)
      ) {
        throw new Error('Please enter a valid mobile number!');
      }

      setProcessData({
        registerData: { firstName, lastName, mobileNumber },
        checkinByMobile: false,
      });

      // ROUTE TO NEXT STEP (after obtaining visit id - not in case of QR)

      if (formData && processType === 'Checkin') {
        // if already have visitRequestId
        if (isUsingQr && activeVisit?.visitRequestId) {
          try {
            await getVisitRequest(activeVisit.visitRequestId);
          } catch (e) {
            if (!isMountedRef.current) return;
            console.error(e);
            // redirect to error screen
            setProcessData({ visitErrorMessage: e as IErrorResponse });
            setCurrentRoute(ROUTES.VISIT_ERROR_SCREEN);
          }
          return;
        }
        try {
          const visitRequestRes = await Api.createVisitorVisitRequest(
            currentSpace.id,
            personData?.id
              ? {
                  people: {
                    id: personData?.id,
                    firstName,
                    lastName,
                    mobileNumber,
                  },
                }
              : {
                  people: {
                    firstName,
                    lastName,
                    mobileNumber,
                  },
                  faceImageUrl: faceData?.imageUrl || undefined,
                }
          );
          if (!isMountedRef.current) return;
          if (visitRequestRes) {
            const { vmsMode } = getSettingsFromLocalStorage(email);

            const {
              id,
              visitRequiredFields,
              checkinVerified: checkinVerifiedData,
              checkoutVerified: checkoutVerifiedData,
              people,
            } = visitRequestRes;
            const {
              isCheckoutOtpRequired: isOutOTPRequired,
              isCheckinOtpRequired: isInOTPRequired,
              isScreeningRequired,
              isVaccinationVerificationRequired,
              vaccinationVerificationSkipAllowed,
              partiallyVaccinatedVisitorCheckinAllowed,
              isCheckinFormRequired,
              isCheckoutFormRequired,
              isTemperatureRequired,
              isTncRequired,
              isCheckinFaceRequired,
            } = visitRequiredFields;
            setProcessData({
              visitRequestId: id,
              isTemperatureRequired,
              isTncRequired,
              isScreeningRequired,
              personData,
              isCheckoutOtpRequired: isOutOTPRequired,
              isCheckinOtpRequired: isInOTPRequired,
              isVaccinationVerificationRequired,
              vaccinationVerificationSkipAllowed,
              partiallyVaccinatedVisitorCheckinAllowed,
              checkinVerified: checkinVerifiedData,
              checkoutVerified: checkoutVerifiedData,
              shouldFillForm: isCheckinProcess
                ? isCheckinFormRequired
                : isCheckoutFormRequired,
              isFaceRequired:
                config.isMobilePlan && vmsMode !== VMS_MODES.QR_ONLY
                  ? isCheckinFaceRequired
                  : false,
            });

            let currentActiveVisit = activeVisit;

            if (personData?.id || people?.id) {
              const spaceVisitorRes = await Api.getSpaceVisitor({
                spaceId: currentSpace.id,
                visitorId: personData?.id || people.id,
              });

              if (spaceVisitorRes?.item) {
                const { activeVisit: activeVisitNew, lastVisit } =
                  spaceVisitorRes.item;
                setProcessData({ activeVisit: activeVisitNew, lastVisit });
                currentActiveVisit = activeVisitNew;
              }
            }

            validateProcess(currentActiveVisit);

            if (
              checkIfVerificationRequired(
                config.isMobilePlan,
                checkinVerifiedData,
                checkoutVerifiedData,
                processType,
                {
                  isCheckoutOtpRequired: isOutOTPRequired,
                  isCheckinOtpRequired: isInOTPRequired,
                },
                vmsMode === VMS_MODES.QUICK && config.isMobilePlan,
                mobileNumber,
                supportedCallingCodes
              )
            ) {
              await sendOTPForMobileVerification(id);
            }
            setCurrentRoute(ROUTES.MOBILE_VERIFY);
          }
        } catch (err) {
          if (!isMountedRef.current) return;
          console.error(err);
          const displayText = {
            ...convertToCamelCase((err as IErrorResponse)?.fields),
          }?.displayText as string | undefined;
          const title = (err as any)?.fields?.title;
          if ((err as any)?.response?.status === 400 && displayText && title) {
            setCustomErrorScreen({ title, displayText });
          } else {
            // redirect to error screen
            setProcessData({ visitErrorMessage: err as IErrorResponse });
            setCurrentRoute(ROUTES.VISIT_ERROR_SCREEN);
          }
        }
      } else {
        if (!personData?.id) {
          throw new Error('Person id not found during checkout!');
        }
        let currentActiveVisit = activeVisit;
        if (!activeVisit) {
          const res = await Api.getSpaceVisitor({
            spaceId: currentSpace.id,
            visitorId: personData?.id,
          });
          setProcessData({
            activeVisit: res.item.activeVisit,
            lastVisit: res.item.lastVisit,
          });
          currentActiveVisit = res.item.activeVisit;
        }

        validateProcess(currentActiveVisit);

        if (!currentActiveVisit?.visitRequestId) {
          throw new Error('Visit request id not found during checkout!');
        }

        try {
          await getVisitRequest(currentActiveVisit.visitRequestId);
        } catch (err) {
          if (!isMountedRef.current) return;
          console.error(err);
          // redirect to error screen
          setProcessData({ visitErrorMessage: err as IErrorResponse });
          setCurrentRoute(ROUTES.VISIT_ERROR_SCREEN);
        }
      }
    } catch (err) {
      if (!isMountedRef.current) return;
      setFormData({ ...formData });
      // if calling code not supported skip otp screen and redirect to form screen
      if (err instanceof UnsupportedCallingCodeError) {
        console.warn(
          `Unsupported country calling code in mobile number: ${formData.mobileNumber}!`
        );
        setCurrentRoute(ROUTES.SCREENING_FORM);
        return;
      }
      toast.error(err as IErrorResponse);
      setIsLoading(false);
    }
  };

  const handleSubmitRef = useRef(handleSubmit);
  handleSubmitRef.current = handleSubmit;

  useEffect(() => {
    if (!hasAutoSubmitted) {
      const { vmsMode = VMS_MODES.NORMAL } = getSettingsFromLocalStorage(email);

      // when in quick mode, if all fields are filled continue to next
      if (
        ([VMS_MODES.QUICK, VMS_MODES.QR_ONLY] as string[]).includes(vmsMode) &&
        personData
      ) {
        const { firstName, lastName, mobileNumber } = personData;
        if (firstName && lastName && mobileNumber) {
          handleSubmitRef.current();
          setHasAutoSubmitted(true);
        }
      }
    }
  }, [personData, email, hasAutoSubmitted]);

  // if already registered and has data, keep fields readOnly (non-editable)
  const firstNameReadOnly =
    !isCheckinProcess ||
    !!personData?.firstName ||
    notYouRegisterData?.firstName;
  const lastNameReadOnly =
    !isCheckinProcess || !!personData?.lastName || notYouRegisterData?.lastName;
  const mobileReadOnly =
    !isCheckinProcess ||
    !!personData?.mobileNumber ||
    notYouRegisterData?.mobileNumber;

  const detailsFormSchema = [
    {
      type: 'text',
      name: 'firstName',
      label: 'First Name',
      required: true,
      disabled: firstNameReadOnly,
      multiline: false,
    },
    {
      type: 'text',
      name: 'lastName',
      label: 'Last Name',
      required: true,
      disabled: lastNameReadOnly,
      multiline: false,
    },
    {
      type: 'tel',
      defaultPrefix: config.countryCallingCode,
      name: 'mobileNumber',
      label: 'Mobile Number',
      disabled: mobileReadOnly,
      required: true,
    },
  ];

  function handleGoBack() {
    setCurrentRoute(getBackNavigationRoute(ROUTES.HOME));
  }
  const retry = () => {
    if (!isMountedRef.current) return;
    setIsLoading(false);
    setCustomErrorScreen(null);
    setHasAutoSubmitted(false);
  };

  return {
    isLoading,
    detailsFormSchema,
    formData,
    setFormData,
    handleNotYouClicked: () => {
      setCurrentRoute(ROUTES.CHECKIN_BY_MOBILE);
      setProcessData({ isNotYouFlow: true });
    },
    onSubmit: handleSubmit,
    showNotYouButton:
      !isUsingQr && (!!personData?.id || notYouRegisterData?.id),
    handleGoBack,
    customErrorScreen,
    retry,
  };
}
