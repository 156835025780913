import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import {
  Button,
  Alert,
  toast,
  IErrorResponse,
  ArrowLeftOutlined,
  QrcodeOutlined,
  SwapOutlined,
  Camera,
  Loader,
} from '@datapeace/1up-frontend-web-ui';
import { Content, Footer, Layout } from '@datapeace/vms-web-components';
import styles from './vaccination-form-screen.module.scss';
import { useVaccinationFormContainer } from '@datapeace/vms-web-containers';
import {
  useCountdown,
  useIsWindowFocused,
  useVaccinationCertificateQRReader,
} from '@datapeace/1up-frontend-web-utils';

export function VaccinationForm() {
  const {
    handleSubmitVaccinationForm,
    videoFacingMode,
    handleSetVideoFacingMode,
    resetCameraView,
    handleSkipVaccinationForm,
    vaccinationVerificationSkipAllowed,
    handleGoBack,
  } = useVaccinationFormContainer();

  const [isVerifying, setIsVerifying] = useState(false);

  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(
    null
  );

  const { width, height } = useWindowSize();
  const canvasWidth = Math.round(width / 2);
  const canvasHeight = Math.round(height / 2);
  const minWidth = 1280;
  const minHeight = 720;
  const isWindowFocused = useIsWindowFocused(true);
  const [qrReaderCanvasElement, setQrReaderCanvasElement] =
    useState<HTMLCanvasElement | null>(null);

  async function handleQrFound(screenshotBase64: string) {
    if (isVerifying) return;
    if (!screenshotBase64) return;
    setIsVerifying(true);
    try {
      await handleSubmitVaccinationForm(screenshotBase64);
    } catch (e) {
      console.error(e);
      toast.error(e as IErrorResponse);
    } finally {
      setIsVerifying(false);
    }
  }

  useVaccinationCertificateQRReader(
    videoElement || null,
    qrReaderCanvasElement,
    handleQrFound,
    isVerifying
  );

  const [transitionDelay, resetTransitionDelay] = useCountdown(1, 200);
  useEffect(() => {
    resetTransitionDelay();
  }, [resetTransitionDelay]);

  return (
    <Layout>
      <div
        className={clsx(
          styles.HintText,
          !!transitionDelay && styles.HintTextInitial
        )}
      >
        <div>
          <span>
            {isVerifying ? (
              <Loader text="Verifying..." />
            ) : (
              <QrcodeOutlined className={styles.HintTextIcon} />
            )}
            Show Vaccination Certificate QR
          </span>
        </div>
      </div>
      <Content className={styles.Content}>
        {isWindowFocused ? (
          <>
            {!resetCameraView ? (
              <>
                <Camera
                  videoConstraints={{ facingMode: videoFacingMode }}
                  loading={isVerifying}
                  captureAreaBoxSize={0}
                  videoElementRef={(el) => setVideoElement(el)}
                  info=""
                  hideCaptureButton
                />
                <canvas
                  width={canvasWidth > minWidth ? canvasWidth : minWidth}
                  height={canvasHeight > minHeight ? canvasHeight : minHeight}
                  ref={setQrReaderCanvasElement}
                />
                <div className={styles.QrFrame} />
              </>
            ) : (
              <span>Loading...</span>
            )}
            <Button
              className={styles.ToggleViewModeBtn}
              shape="circle"
              icon={<SwapOutlined />}
              onClick={handleSetVideoFacingMode}
            />
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '1rem',
            }}
          >
            <Alert
              message="Tap on screen to see camera view!"
              description="This window is out of focus. Tap here to see the camera view."
              type="warning"
              showIcon
            />
          </div>
        )}
      </Content>
      <Footer className={styles.Footer}>
        <Button
          onClick={handleGoBack}
          className={styles.BackButton}
          size="large"
          icon={<ArrowLeftOutlined />}
          shape="circle"
        />
        {vaccinationVerificationSkipAllowed && (
          <>
            <div />
            <Button
              onClick={handleSkipVaccinationForm}
              className={styles.BackButton}
              size="large"
              title="Skip"
            >
              Skip
            </Button>
          </>
        )}
      </Footer>
    </Layout>
  );
}
