import { FC, useEffect } from 'react';
import {
  Dropdown,
  Toggle,
  Radio,
  Checkbox,
  Camera,
  DateTime,
  VerificationCode,
  PhoneNumber,
  Group,
  People,
  ITextProps,
  Text,
  IDropdownProps,
  IDateTimeProps,
  IToggleProps,
  IRadioProps,
  ICheckboxProps,
  ICameraProps,
  IVerificationCodeProps,
  IPeopleProps,
  IPhoneNumberProps,
  IFileProps,
  IGroupProps,
  File,
} from '../dynamic-form-fields';
import { FormField } from '../form-field/form-field';
interface IInputTypes {
  text: FC<ITextProps>;
  dropdown: FC<IDropdownProps>;
  date: FC<IDateTimeProps>;
  time: FC<IDateTimeProps>;
  datetime: FC<IDateTimeProps>;
  toggle: FC<IToggleProps>;
  radio: FC<IRadioProps>;
  checkbox: FC<ICheckboxProps>;
  camera: FC<ICameraProps>;
  verificationCode: FC<IVerificationCodeProps>;
  people: FC<IPeopleProps>;
  tel: FC<IPhoneNumberProps>;
  file: FC<IFileProps>;
  group: FC<IGroupProps>;
}

const InputTypes: IInputTypes = {
  text: Text,
  dropdown: Dropdown,
  date: DateTime,
  time: DateTime,
  datetime: DateTime,
  toggle: Toggle,
  radio: Radio,
  checkbox: Checkbox,
  camera: Camera,
  verificationCode: VerificationCode,
  people: People,
  tel: PhoneNumber,
  file: File,
  group: Group,
};

interface Props {
  onChange: (v: any) => void;
  items: any[];
  autoFocusFirstField?: boolean;
  values: any;
  onUploadFile?: (file: File) => Promise<{
    fileUrl: string;
  }>;
}
export function DynamicForm({
  items: fields,
  onChange,
  values,
  autoFocusFirstField = true,
  onUploadFile = undefined,
}: Props) {
  useEffect(() => {
    const initialValues = fields.reduce((acc, { name, defaultValue, type }) => {
      let value: string | boolean = defaultValue || '';
      if (type === 'toggle') {
        value = defaultValue === 'true' || defaultValue === true;
      }

      acc[name] = value;
      return acc;
    }, {});

    onChange({ ...initialValues, ...values });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValueChange = (name: string, value: any) => {
    const updatedValues = { ...values, [name]: value };
    onChange(updatedValues);
  };

  return (
    <>
      {fields.map(({ type, required, name, label, ...rest }, i) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const Field: any = InputTypes[type] || InputTypes.text;
        const value = values[name] || '';

        return (
          <FormField key={name} required={required} label={label}>
            <Field
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...rest}
              type={type}
              name={name}
              label={label}
              value={value}
              onUploadFile={onUploadFile}
              onChange={handleValueChange}
              variant="outlined"
              margin="normal"
              autoFocus={autoFocusFirstField && i === 0}
            />
          </FormField>
        );
      })}
    </>
  );
}
