import { memo, useEffect, useRef, useState } from 'react';
import { string } from 'prop-types';
import clsx from 'clsx';
import styles from './image-loader.module.scss';
import { Loader } from '@datapeace/1up-frontend-web-ui';

interface IProps {
  className: string;
  fit: 'cover' | 'contain';
  loaderText: string;
  src: string;
}

function MemoImageLoader({
  className = '',
  fit = 'cover',
  loaderText = '',
  src,
}: IProps) {
  const [isLoading, setIsLoading] = useState(true);
  const imageRef = useRef<HTMLImageElement>(null);

  const onImageLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    const image = imageRef.current;
    if (src && image) {
      image.addEventListener('load', onImageLoad);
      image.addEventListener('error', onImageLoad);
      image.src = src;
    }

    return () => {
      if (image) {
        image.removeEventListener('load', onImageLoad);
        image.removeEventListener('error', onImageLoad);
      }
    };
  }, [src]);

  return (
    <>
      {isLoading && (
        <div className={clsx(className, styles.Loader)}>
          <Loader text={loaderText} />
        </div>
      )}
      <img
        src={isLoading ? undefined : src}
        alt=""
        ref={imageRef}
        className={clsx(styles.Image, isLoading && styles.Loading)}
        style={{
          objectFit: fit,
        }}
      />
    </>
  );
}

MemoImageLoader.propTypes = {
  src: string.isRequired,
  className: string,
  loaderText: string,
  fit: string,
};

MemoImageLoader.defaultProps = {
  className: '',
  loaderText: '',
  fit: 'cover',
};
const ImageLoader = memo(MemoImageLoader);
export { ImageLoader };
