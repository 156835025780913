import React, { FC } from 'react';
import clsx from 'clsx';
import { useConfig } from '@datapeace/vms-web-hooks';
import styles from './layout.module.scss';

interface Props {
  className?: string;
  showBackground?: boolean;
  children?: React.ReactNode;
}

export const Layout: FC<Props> = ({
  className,
  children,
  showBackground = false,
}) => {
  const config = useConfig();
  const { portalBackgroundImageUrl = '' } = config || {};

  return (
    <div
      className={clsx(styles.Layout, className)}
      style={{
        ...(showBackground
          ? { backgroundImage: `url(${portalBackgroundImageUrl})` }
          : null),
      }}
    >
      {children}
    </div>
  );
};
