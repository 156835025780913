import React from 'react';
import { Card as AntdCard } from 'antd';
import { CardProps as AntdCardProps } from 'antd/lib/card';
import clsx from 'clsx';
import { Avatar } from '..';
import styles from './card.module.scss';

export type BasicCardProps = AntdCardProps;
export const BasicCard = AntdCard;

export interface CardProps
  extends Pick<
    AntdCardProps,
    'title' | 'extra' | 'bordered' | 'loading' | 'actions' | 'size'
  > {
  hasTopColoredBorder?: boolean;
  /** Either image src or icon */
  avatar?: string | React.ReactNode;
  type?: 'default' | 'success' | 'danger';
  bodyStyle?: React.CSSProperties;
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const Card: React.FC<CardProps> = ({
  actions,
  hasTopColoredBorder = true,
  title,
  extra,
  bordered = false,
  loading,
  avatar,
  type = 'default',
  size,
  bodyStyle,
  className,
  style,
  children,
}) => {
  return (
    <AntdCard
      size={size}
      actions={actions}
      title={title}
      extra={extra}
      bordered={bordered}
      loading={loading}
      className={clsx(
        styles.Card,
        hasTopColoredBorder && styles.CardWithTopBorder,
        avatar && styles.CardWithAvatar,
        styles[`Card-${type}`],
        className
      )}
      style={style}
      bodyStyle={bodyStyle}
    >
      {avatar && (
        <Avatar
          className={clsx(
            styles.CardAvatar,
            typeof avatar !== 'string' && styles.CardAvatarWithIcon
          )}
          icon={typeof avatar !== 'string' ? avatar : undefined} // if react node, use avatar as icon
          src={typeof avatar === 'string' ? avatar : undefined} // if string, use avatar as image src
        />
      )}
      {children}
    </AntdCard>
  );
};
