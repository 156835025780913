import { IBodyTemperature } from './common';
import { IInvitationDetailed } from './invitation';
import { IPerson, IScreeningDataFormSchema } from './people';
import { IPrinter } from './printer';
import { IScreenSaverConfig } from './screensaver';
import { ISpace } from './space';
import { ITemperatureDevice } from './temperature-device';
import { VaccinationVerificationConfig } from './vaccination';

export interface IFormField {
  name: string;
  type: string;
  label: string;
  required: boolean;
  /**
   * To fulfil a condition:
   *
   * any of the items in outer array can be true (OR comparison)
   *
   * all the items in inner array should be true (AND comparison)
   */
  options?: {
    key: string;
    label: string;
  }[];
  conditions?: {
    name: string;
    key: string;
    operator: string;
    value: string;
  }[][];
  settings?: {
    promotedToSystemFields?: boolean;
    hidden?: boolean;
    readOnly?: boolean;
  };
  fields?: IFormField[];
}

export interface IFormFieldData {
  name: string;
  type: string;
  label: string;
  value: {
    key: null | string;
    label: string;
  }[];
}

export interface IForm {
  identifier?: any;
  fields: IFormField[];
}

export interface IFormData {
  identifier?: any;
  data: IFormFieldData[];
  formSchemaRevisionId?: number;
}

export interface IGeoCoord {
  lat: number;
  long: number;
  accuracyM: number;
}

export interface IClientData {
  deviceId: string | null;
  geoCoord?: IGeoCoord;
  vmsMode?:
    | 'NORMAL'
    | 'QUICK'
    | 'AUTO_CAPTURE'
    | 'INVITED_ONLY'
    | 'CONTACTLESS'
    | null;
}

export interface IClientMetaData {
  client: IClientData;
}

export enum FREQUENCY {
  NEVER = 'NEVER',
  ONCE = 'ONCE',
  ALWAYS = 'ALWAYS',
  ONCEADAY = 'ONCE_A_DAY',
  ONCEAWEEK = 'ONCE_A_WEEK',
  ONCEAMONTH = 'ONCE_A_MONTH',
}

export interface IVMSConfig extends IScreenSaverConfig {
  checkinButtonLabel: string;
  checkoutButtonLabel: string;
  portalTitle: string;
  portalLogoUrl: string;
  portalBackgroundImageUrl: string;

  visitInvitationEnabled: boolean;
  checkinVerifyOtpLimit: number;

  checkoutEnabled: boolean;
  otpLength: number;

  checkinOtpFrequency: FREQUENCY;
  checkoutOtpFrequency: FREQUENCY.NEVER | FREQUENCY.ALWAYS;
  checkoutOtpHostApprovalEnabled: boolean;

  checkinFaceFrequency: FREQUENCY;
  checkoutFaceFrequency: FREQUENCY.NEVER | FREQUENCY.ALWAYS;

  tncContent: string;
  tncFrequency: FREQUENCY;

  checkinFormSchema: IForm;
  checkoutFormSchema: IForm;
  visitFormSchema: IForm;
  checkinFormSchemaRevisionId: number;
  checkoutFormSchemaRevisionId: number;
  visitFormSchemaRevisionId: number;

  printer: IPrinter | null;
  printerTemplate: 'default' | 'custom';
  printFrequency: FREQUENCY.ALWAYS | FREQUENCY.NEVER;

  temperatureFrequency: FREQUENCY.ALWAYS | FREQUENCY.NEVER | FREQUENCY.ONCE;
  temperatureDevices: ITemperatureDevice[] | null;

  visitorAppEnabled: boolean;
  visitorBadgeAutoDownloadEnabled: boolean;

  printerLabelCustomFields: {
    peopleFormFields: string[];
    checkinFormFields: string[];
  } | null;

  checkinFormAutofillEnabled: boolean;
  checkoutFormAutofillEnabled: boolean;

  checkinFaceIndexFrequency:
    | FREQUENCY.ALWAYS
    | FREQUENCY.ONCEADAY
    | FREQUENCY.ONCEAWEEK
    | FREQUENCY.ONCEAMONTH
    | FREQUENCY.NEVER;

  checkoutFaceIndexFrequency:
    | FREQUENCY.ALWAYS
    | FREQUENCY.ONCEADAY
    | FREQUENCY.ONCEAWEEK
    | FREQUENCY.ONCEAMONTH
    | FREQUENCY.NEVER;

  vaccinationVerificationInCheckinEnabled: boolean;
  partiallyVaccinatedVisitorCheckinAllowed: boolean;
  vaccinationVerificationSkipAllowed: boolean;
}

export interface ISpaceVmsConfig {
  gatepassEnabled: boolean;
  verificationOtpSkipLimit: number | null;
  verificationResendOtpLimit: number | null;
  verificationResendOtpTimeout: number | null;
}

export interface IApprovalData {
  isCheckinApproved: boolean;
  isCheckoutApproved: boolean;
  isCheckinApprovalPending: boolean;
  isCheckoutApprovalPending: boolean;
  checkinApprovalStatus:
    | 'approved'
    | 'waitfor'
    | 'notAvailable'
    | 'rejected'
    | ''
    | null;
  checkinApprovalStatusMessage: string | null;
  checkoutApprovalStatus: 'approved' | 'rejected' | '' | null;
  checkoutApprovalStatusMessage: string | null;
}

export interface IApprovers {
  approvedAt: string;
  event: string;
  id: number;
  isPending: boolean;
  status: string;
  statusMessage: string;
  person: Partial<IPerson>;
}

export type VisitStatus =
  | 'pending'
  | 'pre-checkin'
  | 'checkin'
  | 'checkin-approval'
  | 'checkin-approved'
  | 'checkin-approved'
  | 'checkin-rejected'
  | 'checkout-approval'
  | 'checkout-approved'
  | 'checkout-rejected'
  | 'checkout';

export interface IStateJourney {
  state: VisitStatus;
  label: string;
  description: string;
  changedAt: string | null;
}

export interface IVisit extends IBodyTemperature {
  id: number;
  peopleId: number;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  checkinAt: string | null;
  checkoutAt: string | null;
  checkinPhotoUrl?: string;
  checkoutPhotoUrl?: string;
  isForcedCheckout: boolean;
  isBlacklisted: boolean;
  ndaUrl: string;
  checkinCustomData: IFormData | null;
  checkoutCustomData: IFormData | null;
  visitCustomData: IFormData | null;
  visitInvitation: IInvitationDetailed | null;
  isInvited: boolean;
  visitRequestId?: number;
  nextAllowedState: VisitStatus;
  state: VisitStatus;

  /** whether checkout was enabled in setting during checkin of this visit */
  isCheckoutAllowed: boolean;

  /** TRUE if atleast one of the approver has not updated the status of checkout. */
  isCheckoutApprovalPending: boolean | null;
  isCheckInApproved: boolean | null;

  /** FALSE if atleast one of the approver rejected the status of checkout */
  isCheckoutApproved: boolean | null;
  approvalData: IApprovalData;
  approvers: IApprovers[];

  qrCodeUrl?: string;

  qrCodeBase64?: string;

  preCheckinAt?: string | null;

  visitorBadgeUrl?: string;

  statesJourney: IStateJourney[];

  orgLogoUrl?: string;
}
interface IBadgeVisitInvitation {
  name: string;
  start: string;
  end: string;
  locationHtml: string;
  locationText: string;
  visitorComment: string;
}

interface IBadgeCalendarUrl {
  apple: string;
  google: string;
  outlook: string;
}
export interface IVisitBadge extends Omit<IVisit, 'visitInvitation'> {
  visitInvitation: IBadgeVisitInvitation | null;
  calendarUrls: IBadgeCalendarUrl;
}

export interface IVisitRequiredFields
  extends Pick<
    VaccinationVerificationConfig,
    | 'vaccinationVerificationSkipAllowed'
    | 'partiallyVaccinatedVisitorCheckinAllowed'
  > {
  isCheckinFaceRequired: boolean;
  isCheckinFormRequired: boolean;
  isCheckoutFaceRequired: boolean;
  isCheckoutFormRequired: boolean;
  isCheckinOtpRequired: boolean;
  isCheckoutOtpRequired: boolean;
  isMobileVerified: boolean;
  isScreeningRequired: boolean;
  isTncRequired: boolean;
  isTemperatureRequired: boolean;
  isVaccinationVerificationRequired: boolean;
}

export interface IVisitRequest {
  id: number;
  people: IPerson;
  space: ISpace;
  spaceConfig: Pick<
    IVMSConfig,
    | 'checkinFormSchema'
    | 'checkinFormSchemaRevisionId'
    | 'checkoutFormSchema'
    | 'checkoutFormSchemaRevisionId'
    | 'tncContent'
    | 'checkinFormAutofillEnabled'
    | 'checkoutFormAutofillEnabled'
  > & {
    screeningFormSchema: IScreeningDataFormSchema;
    checkinWithoutScreening: boolean;
  };
  visit: IVisit;
  visitDetails?: {
    people: IPerson | null;
    activeVisit: IVisit | null;
    lastVisit: IVisit | null;
    isTncRequired: boolean;
    isCheckinFaceRequired: boolean;
    isCheckoutFaceRequired: boolean;
    isMobileVerified: boolean;
    isScreeningRequired: boolean;
  };
  visitRequiredFields: IVisitRequiredFields;
  checkinVerified: boolean | null;
  checkoutVerified: boolean | null;
}

export interface IVisitVerify {
  visit: IVisit;
  visitRequiredFields: IVisitRequiredFields;
  visitStateFlow: {
    nextAllowedState: VisitStatus | null;
    error: null | {
      message: string;
    };
  };
  visitRequest: IVisitRequest;
  space: ISpace;
  people: IPerson;
  lastVisit: IVisit | null;
  activeVisit: IVisit | null;
  isMobileVerified?: boolean;
  isTncSigned?: boolean;
}

export interface IVisitorDetails {
  approver: IApprovers;
  approverFields: string[];
  customFormSchema: {
    checkinFormSchema: IForm;
  };
  visit: IVisit;
}

export interface IVisitorItemByMobileNumber
  extends Pick<
    IPerson,
    | 'id'
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'isBlacklisted'
    | 'mobileNumber'
    | 'pictureUrl'
    | 'profileStatus'
    | 'c19VaccinationStatus'
  > {
  fullName: string;
  lastVisitId: number | null;
  lastVisitedAt: string | null;
}
