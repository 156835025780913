import { FC } from 'react';
import clsx from 'clsx';
import styles from './layout.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const Content: FC<Props> = ({ className, children }) => (
  <div className={clsx(styles.Content, className)}>{children}</div>
);
