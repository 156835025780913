import { Modal } from '@datapeace/1up-frontend-web-ui';
import { useEffect, useState } from 'react';
import { useIdle } from 'react-use';
import styles from './screen-saver.module.scss';
import { useConfig } from '@datapeace/vms-web-hooks';

interface Props {
  isScreensaverDisabled?: boolean;
}

export const Screensaver = ({ isScreensaverDisabled = false }: Props) => {
  const [imageIndex, setImageIndex] = useState(0);
  const config = useConfig();
  const {
    screensaverEnabled,
    screensaverIdleTimeoutSecs,
    screensaverImages,
    screensaverSlideDurationSecs,
  } = config;

  const isIdle = useIdle(screensaverIdleTimeoutSecs * 1000);

  // Called first time, or when isActive or isIdle changes
  useEffect(() => {
    let intervalRef: number;
    (() => {
      // return if component is active (screensaver will not be visible in this case - see modal visible condition)
      if (
        !(screensaverEnabled && screensaverImages?.length) ||
        !isIdle ||
        isScreensaverDisabled
      ) {
        return;
      }
      // else, set interval for image change (screensaver is visible in this case - see modal visible condition)
      intervalRef = window.setInterval(() => {
        setImageIndex((prev) =>
          prev + 1 >= screensaverImages.length ? 0 : prev + 1
        );
      }, screensaverSlideDurationSecs * 1000);
    })();
    // Perform cleanup when component unmounts (at the end of every render)
    return () => {
      intervalRef && clearInterval(intervalRef);
    };
  }, [
    isScreensaverDisabled,
    isIdle,
    screensaverEnabled,
    screensaverSlideDurationSecs,
    screensaverImages,
  ]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Modal
      style={{ top: 0, left: 0, height: '100vh' }}
      width="100vw"
      visible={
        screensaverEnabled &&
        !!screensaverImages?.length &&
        !isScreensaverDisabled &&
        isIdle
      }
      footer={null}
      bodyStyle={{ height: '100vh', padding: 0, display: 'flex', margin: 0 }}
      maskStyle={{ background: 'rgba(0, 0, 0, 0.9)' }}
    >
      <div className={styles.ScreenSaver}>
        <img src={screensaverImages?.[imageIndex]} alt="screensaverImage" />
      </div>
    </Modal>
  );
};
