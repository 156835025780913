import React, { FC } from 'react';
import { Alert } from '..';
import { useIsOnline } from '@datapeace/1up-frontend-web-utils';
import styles from './online-status.module.scss';

export interface OnlineStatusProps {
  children: React.ReactNode;
}
export const OnlineStatus: FC<OnlineStatusProps> = ({ children }) => {
  const isOnline = useIsOnline();

  return (
    <>
      {!isOnline && (
        <Alert
          className={styles.Alert}
          banner
          message="No internet connection! Please inform the concerned person about this issue."
          type="error"
          showIcon
        />
      )}

      {children}
    </>
  );
};

export default OnlineStatus;
