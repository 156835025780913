import {
  ComponentType,
  useState,
  useContext,
  createContext,
  useEffect,
} from 'react';
import {
  ISpace,
  IOrganizationDetailed,
  setCurrentSpaceInStorage,
  removeCurrentSpaceFromStorage,
  removeCurrentOrganizationFromStorage,
  setCurrentOrganizationInStorage,
} from '@datapeace/1up-frontend-shared-api';
import {
  getSettingsFromLocalStorage,
  ISettings,
  saveSettingsIntoLocalStorage,
} from '@datapeace/vms-web-utils';
import { VMS_MODES, IConfigContext } from '@datapeace/vms-web-utils';
import { useProfileContext } from '@datapeace/vms-web-models';
import {
  useGetCurrentOrganization,
  useGetCurrentSpace,
  useGetConfig,
  useGetOrganizations,
} from '@datapeace/vms-web-hooks';

const ConfigContext = createContext<IConfigContext | null>(null);

export const useConfig = () => {
  const config: IConfigContext | null = useContext(ConfigContext);
  if (!config) {
    throw new Error("ConfigContext should be consumed inside it's Provider");
  }

  // const mockedConfig: IConfigContext & { isContactLessMode?: boolean } = config;
  // mockedConfig.isContactLessMode = true;

  return config;
};

export const withConfig = (Comp: ComponentType) => (props: any) => {
  const context = useConfig();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Comp {...context} {...props} />;
};

export function useConfigContainer() {
  const { email } = useProfileContext();

  const [error, setError] = useState('');

  const [organizations] = useGetOrganizations(setError);
  const [currentOrganization, setCurrentOrganization] =
    useGetCurrentOrganization(organizations);
  const [currentSpace, setCurrentSpace] =
    useGetCurrentSpace(currentOrganization);
  const [config, setConfig] = useGetConfig(
    currentOrganization,
    currentSpace,
    setError
  );

  const { visitInvitationEnabled, visitorAppEnabled } = config || {};

  useEffect(() => {
    const settingData = getSettingsFromLocalStorage(email);

    if (
      settingData.vmsMode === VMS_MODES.QR_ONLY &&
      visitInvitationEnabled === false
    ) {
      settingData.vmsMode = VMS_MODES.AUTO_CAPTURE;
    }

    if (
      settingData.vmsMode === VMS_MODES.CONTACT_LESS &&
      visitorAppEnabled === false
    ) {
      settingData.vmsMode = VMS_MODES.NORMAL;
    }

    saveSettingsIntoLocalStorage(email, settingData as ISettings);
  }, [email, visitInvitationEnabled, visitorAppEnabled]);

  // handle space selection of user
  const handleSelectSpace = (space: ISpace | null) => {
    if (space) {
      setCurrentSpace(space);
      setCurrentSpaceInStorage(email, space.id);
    } else {
      setCurrentSpace(null);
      removeCurrentSpaceFromStorage(email);
    }

    // reset config as it depends on selected space
    setConfig(null);
  };
  const handleClearSpace = () => handleSelectSpace(null);

  // handle organization selection of user
  const handleSelectOrganization = (
    organization: IOrganizationDetailed | null
  ) => {
    if (organization) {
      if (
        !organization.features.organizationsVmsBasic ||
        !organization.canAccessVms
      ) {
        setError("Can't access vms");
        return;
      }

      setCurrentOrganization(organization);
      setCurrentOrganizationInStorage(email, organization.id);
    } else {
      setCurrentOrganization(null);
      removeCurrentOrganizationFromStorage(email);
    }

    // reset space as it depends on selected organization
    handleSelectSpace(null);
  };
  const handleClearOrganization = () => {
    handleSelectOrganization(null);
    setError('');
  };

  return {
    email,
    error,
    config,
    organizations,
    currentOrganization,
    currentSpace,
    handleClearSpace,
    handleClearOrganization,
    Provider: ConfigContext.Provider,
    handleSelectSpace,
    handleSelectOrganization,
  };
}
