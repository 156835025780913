import React, { FormEvent, useState } from 'react';
import { UndoOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import { Button, Typography, message } from '@datapeace/1up-frontend-web-ui';
import clsx from 'clsx';
import { drawBackgroundInTransparentCanvas } from '@datapeace/1up-frontend-shared-api';
import SignatureCanvas from 'react-signature-canvas';
import { useTermsContainer } from '@datapeace/vms-web-containers';
import {
  Layout,
  Header,
  Content,
  Form,
  NavButtons,
  FormField,
  Toggle,
  Text,
} from '@datapeace/vms-web-components';
import { DISABLED_TNC_EMAIL } from '@datapeace/vms-web-utils';
import formStyles from '../custom-form-screen/custom-form-screen.module.scss';
import styles from './terms-screen.module.scss';
import { useElementSize } from '@datapeace/1up-frontend-web-utils';

export function Terms() {
  const {
    tncContent,
    shouldSendEmail,
    setShouldSendEmail,
    email,
    setEmail,
    onSubmit,
    handleGoBack,
  } = useTermsContainer();

  const [hasSignature, setHasSignature] = useState(false);
  const [signaturePad, setSignaturePad] = useState<{
    isEmpty: () => boolean;
    clear: () => void;
    getTrimmedCanvas: () => HTMLCanvasElement;
    fromDataURL: (url: string) => void;
  }>();

  const handleClearSignature = () => {
    if (signaturePad) {
      signaturePad.clear();
    }
    setHasSignature(false);
  };

  const [signaturePadElement] = useElementSize(({ width, height }) => (
    <div className={styles.SignaturePad}>
      {!hasSignature && <p>Please sign here</p>}

      <SignatureCanvas
        onBegin={() => setHasSignature(true)}
        ref={setSignaturePad}
        velocityFilterWeight={0.5}
        canvasProps={{ width, height }}
      />

      {hasSignature && (
        <Button
          onClick={handleClearSignature}
          className={styles.SignatureClearButton}
          type="link"
          icon={<UndoOutlined />}
        >
          Clear
        </Button>
      )}
    </div>
  ));

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!signaturePad) {
      return;
    }

    if (signaturePad.isEmpty()) {
      message.error('Please sign the document first!');
      return;
    }

    // trim canvas to get only the part with signatures
    const trimmedCanvas = signaturePad.getTrimmedCanvas();

    // draw white background behind canvas, otherwise jpeg image will be all black
    drawBackgroundInTransparentCanvas(trimmedCanvas);

    const signatureImage = trimmedCanvas.toDataURL('image/jpeg', 0.3);
    onSubmit(signatureImage);
  };

  return (
    <Layout>
      <Header />

      <Content>
        <div className={clsx(formStyles.Form, styles.Content)}>
          <Form className={styles.Form} onSubmit={handleSubmit}>
            <Typography.Title level={3}>Terms & Conditions</Typography.Title>

            <Typography.Paragraph className={styles.Text}>
              {tncContent}
            </Typography.Paragraph>

            {!DISABLED_TNC_EMAIL && (
              <FormField required label="Email me a copy of document">
                <Row className={styles.EmailField} align="middle">
                  <Toggle
                    name="shouldSendEmail"
                    value={shouldSendEmail}
                    onChange={(name: string, value: boolean) =>
                      setShouldSendEmail(value)
                    }
                  />

                  {shouldSendEmail && (
                    <Text
                      placeholder="Enter your email address"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(name: string, value: string) =>
                        setEmail(value)
                      }
                    />
                  )}
                </Row>
              </FormField>
            )}
            {signaturePadElement}

            <NavButtons onBack={handleGoBack} />
          </Form>
        </div>
      </Content>
    </Layout>
  );
}
