import { AxiosRequestConfig } from 'axios';
import { instance } from '../lib';

export const fileToBase64 = async (file: File | Blob) =>
  new Promise<string | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const compressBase64Image = (
  base64: string,
  isDownscaleAllowed = false,
  imageQuality = 0.5
) => {
  return new Promise<string>((resolve) => {
    const minResolution = 800;
    // Create a temporary image so that we can compute the height of the downscaled image.
    const image = new Image();
    image.src = base64;
    image.onload = () => {
      // Use the img
      const oldWidth = image.width;
      const oldHeight = image.height;
      let newWidth = oldWidth;
      let newHeight = oldHeight;
      if (
        (image.height > minResolution || image.width > minResolution) &&
        isDownscaleAllowed
      ) {
        if (oldWidth > oldHeight) {
          // Landscape mode
          newWidth = minResolution;
          newHeight = Math.floor((oldHeight / oldWidth) * newWidth);
        } else if (oldWidth < oldHeight) {
          // Portrait mode
          newHeight = minResolution;
          newWidth = Math.floor((oldWidth / oldHeight) * newHeight);
        } else {
          // Square photo
          newWidth = minResolution;
          newHeight = minResolution;
        }
      }
      // Create a temporary canvas to draw the downscaled image on.
      const canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;
      // Draw the downscaled image on the canvas, reduce quality and return the new data URL.
      const ctx = canvas.getContext('2d');
      ctx ? ctx.drawImage(image, 0, 0, newWidth, newHeight) : resolve(base64);
      resolve(canvas.toDataURL('image/jpeg', imageQuality));
    };
    image.onerror = () => {
      // In case of error, original file is uploaded
      resolve(base64);
    };
  });
};

function compressImageFile(
  file: File,
  isDownscaleAllowed: boolean,
  imageQuality?: number
) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    // Convert file to base64
    const base64 = await fileToBase64(file);
    if (typeof base64 === 'string') {
      // Compress base64
      const base64Compressed = await compressBase64Image(
        base64,
        isDownscaleAllowed,
        imageQuality
      );
      // Convert back to File
      fetch(base64Compressed)
        .then((res) => res.blob())
        .then((blob) =>
          resolve(new File([blob], file.name, { type: file.type }))
        );
    } else {
      // In case of error, original file is uploaded
      resolve(file);
    }
  });
}

export async function fileUpload(
  args: any,
  method: 'post' | 'put',
  axiosConfig?: AxiosRequestConfig & { convertResponseToCamelCase?: boolean },
  isDownscaleAllowed = true,
  imageQuality?: number,
  compressAllTypes?: boolean
): Promise<void> {
  const compressedFile =
    args.file.type !== 'image/gif' &&
    (compressAllTypes || args.file.type === 'image/jpeg') &&
    args.file.type.split('/')[0] === 'image'
      ? await compressImageFile(args.file, isDownscaleAllowed, imageQuality)
      : args.file;
  const formData = new FormData();
  formData.append(args.filename, compressedFile);
  if (Array.isArray(args?.data?.additionalFields)) {
    args?.data?.additionalFields.forEach(
      (item: { fileName: string; data: any }) => {
        formData.append(item.fileName, item.data);
      }
    );
  }
  return instance.client[method](args.action, formData, {
    ...axiosConfig,
    headers: {
      ...axiosConfig?.headers,
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress(progress: any): void {
      args.onProgress(
        {
          percent: Number(
            Math.round((progress.loaded / progress.total) * 100).toFixed(2)
          ),
        },
        args.file
      );
    },
  })
    .then((response): void => {
      args.onSuccess(response, args.file);
    })
    .catch((error): void => {
      args.onError(error, error.response, args.file);
    });
}

export function getCommonFileUploadConfig(
  method: 'post' | 'put' = 'post',
  axiosConfig?: AxiosRequestConfig & { convertResponseToCamelCase?: boolean },
  compressAllTypes?: boolean,
  isDownscaleAllowed?: boolean,
  imageQuality?: number
) {
  return {
    name: 'file',
    customRequest: (args: any) =>
      fileUpload(
        args,
        method,
        axiosConfig,
        isDownscaleAllowed,
        imageQuality,
        compressAllTypes
      ),
  };
}
