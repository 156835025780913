import { FC } from 'react';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import { Button } from '@datapeace/1up-frontend-web-ui';
import { useRouter, RoutesValues } from '@datapeace/vms-web-models';
import styles from './nav-buttons.module.scss';

interface Props {
  backLink?: RoutesValues;
  onBack?: () => void;
  onNext?: () => void;
  loading?: boolean;
  showNext?: boolean;
  isDisabled?: boolean;
}

export const NavButtons: FC<Props> = ({
  backLink,
  onNext,
  onBack,
  loading,
  showNext = true,
  isDisabled = false,
}) => {
  const { setCurrentRoute } = useRouter();

  return (
    <Row justify="space-between" className={styles.Root}>
      {(backLink || onBack) && (
        <Button
          disabled={loading || isDisabled}
          onClick={() => (backLink ? setCurrentRoute(backLink) : onBack?.())}
          className={styles.Button}
          size="large"
          icon={<ArrowLeftOutlined />}
        >
          Back
        </Button>
      )}

      {showNext && (
        <Button
          loading={loading}
          disabled={isDisabled}
          onClick={onNext}
          className={styles.Button}
          size="large"
          htmlType="submit"
          type="primary"
        >
          Continue
          <ArrowRightOutlined />
        </Button>
      )}
    </Row>
  );
};
