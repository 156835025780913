import { instance } from './client';
import { AxiosResponse } from 'axios';
import mime from 'mime';

import {
  apiSpaceVisitsPath,
  apiSpaceVisitMetricsPath,
  apiSpaceConfigPath,
  apiVisitPath,
  apiSpaceVisitByFacePath,
  apiSpaceVisitCheckInPath,
  apiSpaceVisitCheckOutPath,
  apiVisitApprovePath,
  apiVisitCheckinApprovePath,
  apiVisitCheckoutApprovePath,
  apiFormSchemaRevision,
  apiVisitVerifyByIdPath,
  apiCheckinVisitorByVisitRequestPath,
  apiCheckoutVisitorByVisitRequestPath,
  apiCreateVisitRequestInVmsPath,
  apiVisitRequestPath,
  apiSpaceVisitsSearchByMobileWithoutVerifyPath,
  apiSpaceVisitSmartSearchPath,
  apiVmsFileUploadPath,
  apiSpaceVisitSendOTPPath,
  apiSpaceVisitResendOTPPath,
  apiSpaceVisitVerifyVisitorOTPPath,
  apiSpaceVmsConfigPath,
  apiVisitVisitorGatePassDownload,
  apiVisitRequestCheckinVerificationSendOTPPath,
  apiVisitRequestCheckinVerificationPath,
  apiVisitRequestCheckoutVerificationPath,
  apiVisitRequestCheckoutVerificationSendOTPPath,
  ISpaceVmsConfig,
  apiSpaceVisitorSearchByMobilePathSendOtp,
  apiSpaceVisitorSearchByMobilePathVerifyOtp,
  apiSpaceVisitorsSearchByMobilePath,
  IVisitorItemByMobileNumber,
  apiSpaceVisitorPath,
  apiSpaceVisitorsSearchByMobileWithoutVerifyInMobilePlanPath,
  IVisitVerify,
  createPath,
  convertToSnakeCase,
  VaccinationVerificationData,
} from '../';
import {
  IVMSConfig,
  IVisit,
  IFormData,
  IPerson,
  IClientMetaData,
  IVisitRequest,
  IVisitRequiredFields,
  ISmartSearchSchema,
  SmartSearchParams,
  IPaginationParams,
  IVisitorDetails,
} from '../';

export const getVisits = async (
  spaceId: number,
  params: Partial<{
    checkin_at__gte: string;
    checkin_at__lte: string;
    first_last_mobile: string;
    ordering: string;
    page: number;
    page_size: number;
    body_temp_status: string;
    visitor_status: string;
  }>
): Promise<IVisit[]> =>
  instance.client.get<IVisit[]>(createPath(apiSpaceVisitsPath, { spaceId }), {
    params,
  });

export const getVMSMetrics = async (
  spaceId: number,
  to: string,
  from: string
) =>
  instance.client.get<{
    blacklistCheckinCount: number;
    checkinCount: number;
    checkoutCount: number;
    insidePremiseCount: number;
  }>(createPath(apiSpaceVisitMetricsPath, { spaceId }), {
    params: { from, to },
  });

export const getVMSConfig = async (spaceId: number): Promise<IVMSConfig> =>
  instance.client.get(createPath(apiSpaceConfigPath, { spaceId }));

export const getSpaceVMSConfig = async (
  spaceId: number
): Promise<ISpaceVmsConfig> =>
  instance.client.get(createPath(apiSpaceVmsConfigPath, { spaceId }));

export const updateVMSConfig = async (
  spaceId: number,
  data: Partial<
    Omit<IVMSConfig, 'temperatureDevices'> & {
      temperatureDevices: string[] | null;
    }
  >
): Promise<IVMSConfig> =>
  instance.client.put(
    createPath(apiSpaceConfigPath, { spaceId }),
    convertToSnakeCase(data)
  );

export const updateSpaceVMSConfig = async (
  spaceId: number,
  data: Partial<ISpaceVmsConfig>
): Promise<Pick<ISpaceVmsConfig, 'gatepassEnabled'>> =>
  instance.client.put(
    createPath(apiSpaceVmsConfigPath, { spaceId }),
    convertToSnakeCase(data)
  );

export const getFormSchemaByVersion = async (
  spaceId: number,
  attributeName:
    | 'checkin_form_schema'
    | 'checkout_form_schema'
    | 'visit_form_schema',
  revisionId: number
) =>
  instance.client.get(
    createPath(apiFormSchemaRevision, { spaceId, attributeName, revisionId })
  );

export const getVisit = async (visitId: number): Promise<AxiosResponse> =>
  instance.client.get<IVisit>(createPath(apiVisitPath, { visitId }));

export const updateVisit = async (
  visitId: number,
  data: Partial<IVMSConfig>
): Promise<AxiosResponse> =>
  instance.client.put<IVisit>(
    createPath(apiVisitPath, { visitId }),
    convertToSnakeCase(data)
  );

export const getVisitorByFace = async (
  spaceId: number,
  faceImageData:
    | { faceImage: Blob; type: 'web' }
    | { faceImageUri: string; type: 'native' }
) => {
  try {
    const form = new FormData();

    // suppose type is application/png, then fileName will be file.png
    if (faceImageData.type === 'native') {
      const fileName = `file.${
        mime.getType(faceImageData.faceImageUri)?.split('/')?.[1] || 'jpeg'
      }`;
      form.append('file', {
        uri: faceImageData.faceImageUri,
        type: mime.getType(faceImageData.faceImageUri),
        name: fileName,
      } as any);
    } else {
      const fileName = `file.${
        faceImageData.faceImage.type?.split('/')?.[1] || 'jpeg'
      }`;

      form.append('file', faceImageData.faceImage, fileName);
    }
    const response = await instance.client.post<{
      fileUrl: string;
      people: IPerson | null;
      activeVisit: IVisit | null;
      lastVisit: IVisit | null;
    }>(createPath(apiSpaceVisitByFacePath, { spaceId }), form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getVisitorByMobile = async (
  spaceId: number,
  mobileNumber: string
) => {
  try {
    if (!mobileNumber) {
      throw new Error('Please enter a valid mobile number.');
    }

    const response = await instance.client.post<{
      people: IPerson | null;
      activeVisit: IVisit | null;
      lastVisit: IVisit | null;
      visitRequiredFields: Pick<
        IVisitRequiredFields,
        | 'isCheckinFaceRequired'
        | 'isCheckoutFaceRequired'
        | 'isCheckinOtpRequired'
        | 'isCheckoutOtpRequired'
        | 'isMobileVerified'
      >;
    }>(
      createPath(apiSpaceVisitsSearchByMobileWithoutVerifyPath, { spaceId }),
      convertToSnakeCase({
        mobileNumber,
      })
    );

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getVMSVisitorsByMobile = async (
  spaceId: number,
  mobile: string
) => {
  try {
    if (!mobile) {
      throw new Error('Please enter a valid mobile number.');
    }

    const response = await instance.client.post<{
      visitors: IVisitorItemByMobileNumber[];
    }>(
      createPath(apiSpaceVisitorsSearchByMobileWithoutVerifyInMobilePlanPath, {
        spaceId,
      }),
      convertToSnakeCase({
        mobile,
      })
    );

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Applicable to mobile-plan and not-you
export async function sendOtpSpaceVisitor({
  spaceId,
  resend,
  mobile,
  ticketId,
}: {
  spaceId: number;
  mobile: string;
} & (
  | { resend: false; ticketId: undefined }
  | { resend: true; ticketId: string }
)) {
  return instance.client.post<{
    message: string;
    verificationTicket: {
      id: string;
      expires_at: string;
    };
  }>(
    createPath(apiSpaceVisitorSearchByMobilePathSendOtp, { spaceId }),
    convertToSnakeCase({
      resend,
      mobile,
      ticketId,
      retryType: resend ? 'Text' : undefined,
    })
  );
}

export function verifyOtpSpaceVisitor({
  mobile,
  ticketId,
  otp,
  spaceId,
}: {
  mobile: string;
  ticketId: string;
  otp: string;
  spaceId: number;
}) {
  return instance.client.post<{ message: string }>(
    createPath(apiSpaceVisitorSearchByMobilePathVerifyOtp, { spaceId }),
    convertToSnakeCase({ mobile, ticketId, otp })
  );
}

export async function getSpaceVisitorsByVerifiedMobileNumber({
  spaceId,
  mobile,
  ticketId,
}: {
  mobile: string;
  ticketId: string;
  spaceId: number;
}) {
  return instance.client.post<{
    visitors: IVisitorItemByMobileNumber[];
  }>(
    createPath(apiSpaceVisitorsSearchByMobilePath, { spaceId }),
    convertToSnakeCase({ mobile, ticketId })
  );
}

export async function getSpaceVisitor({
  spaceId,
  visitorId,
}: {
  spaceId: number;
  visitorId: number;
}): Promise<{
  item: { activeVisit: IVisit | null; lastVisit: IVisit | null };
}> {
  return instance.client.get<{
    item: { activeVisit: IVisit | null; lastVisit: IVisit | null };
  }>(createPath(apiSpaceVisitorPath, { spaceId, visitorId }));
}

export const checkInVisitor = async (
  spaceId: number,
  data: {
    people: number | null;
    checkinCustomData: IFormData | null;
    screeningFormData: IFormData | null;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    invitation?: number;
    checkinPhotoUrl?: string;
    signatureImageData?: string;
    checkinMetadata: IClientMetaData | null;
    verifyOtpAttempt: number;
    sendTncCopyTo?: string;
    bodyTemp?: string | null;
    bodyTempUnit?: 'C' | 'F' | null;
  }
): Promise<{ visit: IVisit }> =>
  instance.client.post(
    createPath(apiSpaceVisitCheckInPath, { spaceId }),
    convertToSnakeCase(data)
  );

export const checkOutVisitor = async (
  spaceId: number,
  data: {
    checkoutCustomData: IFormData | null;
    checkoutPhotoUrl?: string;
    checkoutMetadata: IClientMetaData | null;
    visit: number;
  }
): Promise<AxiosResponse> =>
  instance.client.post<{ visit: IVisit }>(
    createPath(apiSpaceVisitCheckOutPath, { spaceId }),
    convertToSnakeCase(data)
  );

// Verify Visit Request

export async function sendVisitRequestOTP(
  visitRequestId: number,
  mobile: string,
  processType: 'checkin' | 'checkout' = 'checkin',
  resend = false,
  ticketId?: string // Need to send ticket_id if resend=true
): Promise<{
  message: string;
  verificationTicket: {
    id: string;
  };
}> {
  return instance.client.post<{
    message: string;
    verificationTicket: {
      id: string;
    };
  }>(
    createPath(
      processType === 'checkin'
        ? apiVisitRequestCheckinVerificationSendOTPPath
        : apiVisitRequestCheckoutVerificationSendOTPPath,
      { visitRequestId }
    ),
    {
      mobile,
      resend,
      ticket_id: ticketId,
    }
  );
}

export async function verifyVisitRequestOTP(
  visitRequestId: number,
  mobile: string,
  otp: string,
  ticketId: string,
  processType: 'checkin' | 'checkout' = 'checkin'
) {
  return instance.client.post<{ message: string }>(
    createPath(
      processType === 'checkin'
        ? apiVisitRequestCheckinVerificationPath
        : apiVisitRequestCheckoutVerificationPath,
      { visitRequestId }
    ),
    {
      mobile,
      otp,
      ticket_id: ticketId,
    }
  );
}

// Verify Visitor

export const sendVisitorOTP = async (
  spaceId: number,
  mobile: string,
  otpType?: 'checkin' | 'checkout'
): Promise<AxiosResponse> =>
  instance.client.post<{ message: string }>(
    createPath(apiSpaceVisitSendOTPPath, { spaceId }),
    {
      mobile,
      otp_type: otpType, // eslint-disable-line
    }
  );

export const resendVisitorOTP = async (
  spaceId: number,
  mobile: string,
  otpType?: 'checkin' | 'checkout'
): Promise<AxiosResponse> =>
  instance.client.post<{ message: string }>(
    createPath(apiSpaceVisitResendOTPPath, { spaceId }),
    {
      mobile,
      otp_type: otpType, // eslint-disable-line
    }
  );

export const verifyVisitorOTP = async (
  spaceId: number,
  mobile: string,
  otp: string
): Promise<AxiosResponse> =>
  instance.client.post<{ message: string }>(
    createPath(apiSpaceVisitVerifyVisitorOTPPath, { spaceId }),
    {
      mobile,
      otp,
    }
  );

export const approveVisit = async (
  visitId: string,
  token: string
): Promise<AxiosResponse> =>
  instance.client.post(createPath(apiVisitApprovePath, { visitId }), {
    token,
  });

export const approveCheckin = async (
  visitId: string,
  token: string,
  status: string,
  statusMessage: string
): Promise<AxiosResponse> =>
  instance.client.post(createPath(apiVisitCheckinApprovePath, { visitId }), {
    token,
    status,
    status_message: statusMessage, // eslint-disable-line
  });

export const approveCheckout = async (
  visitId: string,
  token: string,
  status: string,
  statusMessage: string
): Promise<AxiosResponse> =>
  instance.client.post(createPath(apiVisitCheckoutApprovePath, { visitId }), {
    token,
    status,
    status_message: statusMessage, // eslint-disable-line
  });

export const getCheckinVisitorDetails = async (
  visitId: string,
  token: string
) =>
  instance.client.get<IVisitorDetails>(
    `${createPath(apiVisitCheckinApprovePath, { visitId })}?token=${token}`
  );

export const getCheckoutVisitorDetails = async (
  visitId: string,
  token: string
) =>
  instance.client.get<IVisitorDetails>(
    `${createPath(apiVisitCheckoutApprovePath, { visitId })}?token=${token}`
  );

export const verifyVisitById = async (visitId: string) =>
  instance.client.get<IVisitVerify>(
    createPath(apiVisitVerifyByIdPath, { visitId })
  );

export const checkinVisitorByVisitRequest = async (
  visitRequestId: number,
  data: {
    checkinPhotoUrl?: string;
    checkinMetadata: IClientMetaData | null;
    bodyTemp?: string | null;
    bodyTempUnit?: 'C' | 'F' | null;
    checkinVerifyOtpAttempt?: number;
    sendTncCopyTo?: string;
    signatureImageData?: string;
    checkinCustomData?: IFormData | null;
    screeningFormData?: IFormData | null;
    invitation?: number;
    otpVerificationSkipped: boolean;
    ticketId?: string;
  } & Partial<VaccinationVerificationData>
) =>
  instance.client.post<{
    visit: IVisit;
    successMessage?: { title: string; displayText: string };
  }>(
    createPath(apiCheckinVisitorByVisitRequestPath, { visitRequestId }),
    convertToSnakeCase(data)
  );

export const checkoutVisitorByVisitRequest = async (
  visitRequestId: number,
  data: {
    checkoutPhotoUrl?: string;
    checkoutMetadata: IClientMetaData | null;
    checkoutCustomData?: IFormData | null;
    visit?: number;
    otpVerificationSkipped?: boolean;
    ticketId?: string;
  }
) =>
  instance.client.post<{
    visit: IVisit;
    successMessage?: { title: string; displayText: string };
  }>(
    createPath(apiCheckoutVisitorByVisitRequestPath, { visitRequestId }),
    convertToSnakeCase(data)
  );

export const createVisitorVisitRequest = async (
  spaceId: number,
  personData:
    | {
        people: Pick<IPerson, 'firstName' | 'lastName' | 'mobileNumber'>;
        faceImageUrl?: string;
      }
    | {
        people: Pick<IPerson, 'id' | 'firstName' | 'lastName' | 'mobileNumber'>;
      }
): Promise<IVisitRequest> => {
  const { item } = await instance.client.post<{ item: IVisitRequest }>(
    createPath(apiCreateVisitRequestInVmsPath, { spaceId }),
    convertToSnakeCase(personData)
  );
  return item;
};

export const getVisitorVisitRequest = async (
  visitRequestId: number
): Promise<IVisitRequest> => {
  const { item } = await instance.client.get<{ item: IVisitRequest }>(
    createPath(apiVisitRequestPath, { visitRequestId })
  );
  return item;
};

export const getVisitSmartSearchSchema = async (spaceId: number) => {
  const res = await instance.client.get<ISmartSearchSchema>(
    createPath(apiSpaceVisitSmartSearchPath, { spaceId })
  );
  return res;
};

export const getVisitSmartSearch = async (
  spaceId: number,
  params: SmartSearchParams,
  paginationParams: IPaginationParams & { since: string; upto: string }
) => {
  const res = await instance.client.post(
    createPath(apiSpaceVisitSmartSearchPath, { spaceId }),
    params,
    {
      params: convertToSnakeCase(paginationParams),
    }
  );
  return res;
};

export const uploadFileInVmsApp = async (
  visitRequestId: number,
  fileData: { file: Blob; type: 'web' } | { fileUri: string; type: 'native' }
) => {
  const form = new FormData();

  // suppose type is application/png, then fileName will be file.png
  if (fileData.type === 'native') {
    const fileName = `file.${
      mime.getType(fileData.fileUri)?.split('/')?.[1] || 'jpeg'
    }`;
    form.append('file', {
      uri: fileData.fileUri,
      type: mime.getType(fileData.fileUri),
      name: fileName,
    } as any);
  } else {
    const fileName = `file.${fileData.file.type?.split('/')?.[1] || 'jpeg'}`;

    form.append('file', fileData.file, fileName);
  }

  return await instance.client.post<{ fileUrl: string }>(
    createPath(apiVmsFileUploadPath, { visitRequestId }),
    form,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );
};

export async function downloadVisitorGatePass(visitId: string) {
  const gatepassBlob = (await instance.client.get(
    `${createPath(apiVisitVisitorGatePassDownload, {
      visitId,
    })}`,
    {
      params: convertToSnakeCase({ outputFormat: 'pdf', paperSize: 'a4' }),
      responseType: 'blob',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      convertResponseToCamelCase: false,
    }
  )) as Blob;
  const pdfUrl = URL.createObjectURL(gatepassBlob);
  return pdfUrl;
}
