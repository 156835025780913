import { useState, useEffect } from 'react';
import { IErrorResponse, toast } from '@datapeace/1up-frontend-web-ui';
import {
  getVMSVisitorsByMobile,
  validatePhoneWithPrefix,
  getCountriesData,
} from '@datapeace/1up-frontend-shared-api';
import {
  useProcessDataContext,
  useRouter,
  ProcessType,
  useProfileContext,
} from '@datapeace/vms-web-models';
import {
  checkIfVerificationRequired,
  ROUTES,
  VMS_MODES,
  getSettingsFromLocalStorage,
  Api,
  FREQUENCY_OPTIONS,
} from '@datapeace/vms-web-utils';
import { useIsMounted } from '@datapeace/1up-frontend-web-utils';
import { useConfig } from '@datapeace/vms-web-hooks';

export function useCheckinByMobileContainer() {
  const { setCurrentRoute } = useRouter();
  const { setProcessData, processType, isNotYouFlow } = useProcessDataContext();
  const {
    currentSpace,
    countryCallingCode,
    isMobilePlan,
    checkinFormAutofillEnabled,
    checkoutFormAutofillEnabled,
    checkinOtpFrequency,
    checkoutOtpFrequency,
    supportedCallingCodes,
  } = useConfig();
  const { email } = useProfileContext();

  const isMountedRef = useIsMounted();

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({ mobileNumber: '' });

  useEffect(() => {
    setProcessData({ checkinByMobile: true });
  }, [setProcessData]);

  const handleMobilePlanSubmit = async () => {
    const { mobileNumber } = formData;

    if (!currentSpace) {
      throw new Error('Space is not selected!');
    }

    if (!isMountedRef.current) return;
    const isCheckoutOtpRequired =
      checkoutOtpFrequency !== FREQUENCY_OPTIONS.never;
    const isCheckinOtpRequired =
      checkinOtpFrequency !== FREQUENCY_OPTIONS.never;

    let currentProcessType = processType;

    const { vmsMode } = getSettingsFromLocalStorage(email);

    setProcessData({
      isCheckoutOtpRequired,
      isCheckinOtpRequired,
      registerData: { firstName: '', lastName: '', mobileNumber },
      isFormAutofillEnabled:
        currentProcessType === ProcessType.Checkin
          ? checkinFormAutofillEnabled
          : checkoutFormAutofillEnabled,
    });

    if (
      checkIfVerificationRequired(
        isMobilePlan,
        false,
        false,
        currentProcessType,
        {
          isCheckoutOtpRequired,
          isCheckinOtpRequired,
        },
        vmsMode === VMS_MODES.QUICK && isMobilePlan,
        mobileNumber,
        supportedCallingCodes
      )
    ) {
      const res = await Api.sendSpaceVisitorOTP(
        isMobilePlan,
        currentSpace?.id,
        mobileNumber,
        countryCallingCode
      );
      // We don't know if the current process is checking or checkout, so we add otp verification as required eitherway
      setProcessData({
        otpTicket: res.verificationTicket.id,
      });

      setCurrentRoute(ROUTES.MOBILE_VERIFY);
    } else {
      // Get list of visitors associated with the mobile number
      const { visitors } = await getVMSVisitorsByMobile(
        currentSpace.id,
        mobileNumber
      );
      // redirect if visitors length is 0 in mobile plan and is not an not you flow.
      if (visitors.length === 0 && isMobilePlan && !isNotYouFlow) {
        //if mode is quick and visitors are zero then assume it's checkin - so this will allow the registering of users.
        if (vmsMode === 'QUICK') {
          setProcessData({
            processType: ProcessType.Checkin,
          });
          setCurrentRoute(ROUTES.REGISTER);
          return;
        }
        if (currentProcessType === ProcessType.Checkout)
          throw new Error('You are not checked in!');
        setCurrentRoute(ROUTES.REGISTER);
        return;
      }
      // Select the visitor if only visitor option available
      if (visitors.length === 1) {
        if (isMobilePlan) {
          const {
            item: { activeVisit, lastVisit },
          } = await Api.getSpaceVisitor({
            spaceId: currentSpace.id,
            visitorId: visitors[0]?.id,
          });
          if (vmsMode === VMS_MODES.QUICK) {
            currentProcessType = activeVisit
              ? ProcessType.Checkout
              : ProcessType.Checkin;
          }
          setProcessData({
            processType: currentProcessType,
            activeVisit,
            lastVisit,
          });
        }
        // reset previous values and add notYouRegisterData
        setProcessData({
          personData: visitors[0],
          registerData: null,
          customFormData: null,
          notYouRegisterData: { ...visitors[0] },
        });
        setCurrentRoute(ROUTES.REGISTER);
        return;
      }
      // Take user to visitor selection
      setProcessData({
        availableVisitorListByMobileNumber: { mobile: mobileNumber, visitors },
      });
      setCurrentRoute(ROUTES.VISITOR_SELECTION);
    }
  };

  const handleSubmit = async () => {
    const { mobileNumber } = formData;

    try {
      const countriesData = await getCountriesData();
      if (!isMountedRef.current) return;
      if (
        !mobileNumber ||
        !validatePhoneWithPrefix(mobileNumber, countriesData)
      ) {
        throw new Error('Please enter a valid mobile number!');
      }

      setIsLoading(true);

      if (isMobilePlan) {
        await handleMobilePlanSubmit();
        if (!isMountedRef.current) return;
        setIsLoading(false);
        return;
      }

      if (!currentSpace) {
        throw new Error('Current space Id not found!');
      }

      setProcessData({
        notYouRegisterData: { firstName: '', lastName: '', mobileNumber },
      });
      const res = await Api.sendSpaceVisitorOTP(
        isMobilePlan,
        currentSpace?.id,
        mobileNumber,
        countryCallingCode
      );
      // We don't know if the current process is checking or checkout, so we add otp verification as required eitherway
      setProcessData({
        otpTicket: res.verificationTicket.id,
        isCheckinOtpRequired: true,
        isCheckoutOtpRequired: true,
      });
      if (!isMountedRef.current) return;

      setIsLoading(false);

      setCurrentRoute(ROUTES.MOBILE_VERIFY);
    } catch (err) {
      if (!isMountedRef.current) return;
      setIsLoading(false);
      toast.error(err as IErrorResponse);
    }
  };

  const formSchema = [
    {
      type: 'tel',
      defaultPrefix: countryCallingCode,
      name: 'mobileNumber',
      label: 'Enter your mobile number',
      required: true,
    },
  ];

  return {
    formSchema,
    formData,
    setFormData,
    isLoading,
    onSubmit: handleSubmit,
    onSettingsButtonClick: isMobilePlan
      ? () => setCurrentRoute(ROUTES.SETTINGS)
      : null,
  };
}
