import { Button, Result } from 'antd';
import { useState } from 'react';

export function CookieChecker({
  logoUrl,
  children,
}: {
  children: React.ReactNode;
  logoUrl?: string;
}) {
  const [isCookieEnabled] = useState(navigator.cookieEnabled);
  function handleCheckCookies() {
    if (window) {
      window.location.reload();
    }
  }
  if (!isCookieEnabled) {
    return (
      <div
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {!!logoUrl && (
          <img
            src={logoUrl}
            alt="logo"
            height="56px"
            style={{ objectFit: 'contain' }}
          />
        )}
        <Result
          status="error"
          title="Cookies are disabled"
          subTitle="Your browser has cookies disabled. Make sure your cookies are enabled and try again."
          extra={[
            <Button key="Try" onClick={handleCheckCookies}>
              Try Again
            </Button>,
          ]}
        ></Result>
      </div>
    );
  }
  return children;
}
