import { useEffect, useState } from 'react';

export const useIsWindowFocused = (initialValue: boolean) => {
  const [isWindowFocused, setIsWindowFocused] = useState<boolean>(initialValue);

  useEffect(() => {
    const handleActivityFalse = () => {
      setIsWindowFocused(false);
    };

    const handleActivityTrue = () => {
      setIsWindowFocused(true);
    };

    window.addEventListener('focus', handleActivityTrue);
    window.addEventListener('blur', handleActivityFalse);

    return () => {
      window.removeEventListener('focus', handleActivityTrue);
      window.removeEventListener('blur', handleActivityFalse);
    };
  }, [setIsWindowFocused]);

  return isWindowFocused;
};
