import React, { FC } from 'react';
import { Form, Input, Row } from 'antd';
import {
  Button,
  Card,
  Flex,
  toast,
  IErrorResponse,
  CustomSelectionPage,
  SelectionPage,
  Loader,
} from '@datapeace/1up-frontend-web-ui';
import { CloseCircleOutlined, LogoutOutlined } from '@ant-design/icons';
import Meta from 'antd/lib/card/Meta';
import * as Sentry from '@sentry/react';
import {
  getOrganizationSpaces,
  getRestrictedModeFromStorage,
  ISpace,
} from '@datapeace/1up-frontend-shared-api';
import { debounce } from '@datapeace/1up-frontend-web-utils';
import { IConfigContext, redirectToLogout } from '@datapeace/vms-web-utils';
import { useConfigContainer } from '@datapeace/vms-web-hooks';
import styles from './config-provider.module.scss';

const PAGE_SIZE = 10;
const defaultSearchFilterValue = {
  searchText: '',
};
export const ConfigProvider: FC<{ children: JSX.Element }> = ({ children }) => {
  const {
    email,
    error,
    config,
    organizations,
    currentOrganization,
    currentSpace,
    handleClearSpace,
    handleClearOrganization,
    Provider,
    handleSelectSpace,
    handleSelectOrganization,
  } = useConfigContainer();
  const [form] = Form.useForm();
  const isRestrictedMode = getRestrictedModeFromStorage();
  const [isFetchingOrganizationSpace, setIsFetchingOrganizationSpace] =
    React.useState(true);
  const [organizationSpaces, setOrganizationSpaces] = React.useState<ISpace[]>(
    []
  );
  const [apiParams, setApiParams] = React.useState({
    page: 1,
    searchFilterSubmittedValue: defaultSearchFilterValue.searchText,
  });
  const [organizationSpacesNext, setOrganizationSpacesNext] = React.useState<
    string | null
  >(null);

  const fetchOrganizationSpace = React.useCallback(
    async ({
      page = 1,
      searchText = defaultSearchFilterValue.searchText,
      clearPreviousData = false,
    }: {
      page: number;
      searchText: string;
      clearPreviousData: boolean;
    }) => {
      if (organizations && currentOrganization && !currentSpace) {
        setIsFetchingOrganizationSpace(true);
        clearPreviousData && setOrganizationSpaces([]);
        try {
          const { items, next } = await getOrganizationSpaces(
            currentOrganization?.id,
            {
              search: searchText,
              page,
              pageSize: PAGE_SIZE,
              active: 1,
            }
          );
          setOrganizationSpaces((prevState) => [...prevState, ...items]);
          setOrganizationSpacesNext(next);
          setApiParams({
            page,
            searchFilterSubmittedValue: searchText,
          });
        } catch (err) {
          toast.error(err as IErrorResponse);
        }

        setIsFetchingOrganizationSpace(false);
      }
    },
    [currentOrganization, currentSpace, organizations]
  );

  React.useEffect(() => {
    fetchOrganizationSpace({
      page: 1,
      searchText: '',
      clearPreviousData: true,
    });
  }, [fetchOrganizationSpace]);

  const handleSearchFilterSubmit = (value: string) => {
    fetchOrganizationSpace({
      page: 1,
      searchText: value,
      clearPreviousData: true,
    });
  };

  const handleLoadMoreOrganizationSpace = ({
    page,
    searchText,
  }: {
    page: number;
    searchText: string;
  }) => {
    if (!organizationSpacesNext) {
      return;
    }
    fetchOrganizationSpace({
      page,
      searchText,
      clearPreviousData: false,
    });
  };

  React.useEffect(() => {
    Sentry.configureScope((scope) => {
      scope.setUser({
        ...scope.getUser(),
        orgId: currentOrganization?.id,
        orgName: currentOrganization?.name,
        spaceId: currentSpace?.id,
        spaceName: currentSpace?.name,
      });
    });
  }, [email, currentOrganization, currentSpace]);

  if (!email) {
    console.error('email not found in ConfigProvider');
    return null;
  }

  if (error) {
    return (
      <Row
        style={{
          height: '100%',
        }}
        align="middle"
        justify="center"
      >
        <Card
          actions={[
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <Button onClick={handleClearOrganization}>
                Switch Organization
              </Button>
              <Button onClick={redirectToLogout}>Logout</Button>
            </div>,
          ]}
          style={{ width: '350px' }}
        >
          <Meta
            avatar={
              <CloseCircleOutlined style={{ color: 'red', fontSize: '30px' }} />
            }
            title={error}
            description="Please ask for assistance from the concerned person."
          />
        </Card>
      </Row>
    );
  }

  if (config) {
    const contextValue: IConfigContext = {
      ...config,
      organizations,
      currentOrganization,
      currentSpace,
      clearCurrentSpace: handleClearSpace,
      clearCurrentOrganization: handleClearOrganization,
    };
    return <Provider value={contextValue}>{children}</Provider>;
  }

  if (organizations && currentOrganization && !currentSpace) {
    if (isRestrictedMode) {
      return (
        <Row
          style={{
            height: '100%',
          }}
          align="middle"
          justify="center"
        >
          <Card
            actions={[
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button onClick={redirectToLogout}>Logout</Button>
              </div>,
            ]}
            style={{ width: '350px' }}
          >
            <Meta
              avatar={
                <CloseCircleOutlined
                  style={{ color: 'red', fontSize: '30px' }}
                />
              }
              title={error}
              description="You are not authorised to access this space. Please ask for assistance from the concerned person."
            />
          </Card>
        </Row>
      );
    }
    const handleSearchFilterSubmitWithDebounce = debounce(
      (searchText: string) => {
        handleSearchFilterSubmit(searchText);
      },
      400
    );

    return (
      <Flex center>
        <CustomSelectionPage
          key="space"
          title={
            <Form className={styles.Container} form={form} layout="vertical">
              <Form.Item
                name="choose-space"
                label="Choose Space"
                style={{
                  marginBottom: 0,
                  width: '100%',
                }}
                rules={[
                  ({ getFieldValue }) => ({
                    validator() {
                      if (
                        getFieldValue('choose-space') !== '' &&
                        getFieldValue('choose-space').length < 3
                      ) {
                        return Promise.reject(
                          new Error(
                            'Please enter at least 3 characters to search.'
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.Search
                  autoFocus
                  allowClear
                  placeholder="Type here to search space"
                  onChange={(event) => {
                    if (
                      event.target.value.length >= 3 ||
                      event.target.value === ''
                    ) {
                      handleSearchFilterSubmitWithDebounce(event.target.value);
                    }
                  }}
                  onSearch={(value, event) => {
                    event && event.preventDefault();
                    if (value !== '' && value.length >= 3) {
                      handleSearchFilterSubmitWithDebounce(value);
                    }
                  }}
                />
              </Form.Item>
            </Form>
          }
          onSelect={handleSelectSpace}
          isFetching={isFetchingOrganizationSpace}
          handleLoadMore={() => {
            handleLoadMoreOrganizationSpace({
              page: apiParams.page + 1,
              searchText: apiParams.searchFilterSubmittedValue,
            });
          }}
          hasMoreNextPageItems={organizationSpacesNext}
          options={organizationSpaces}
          onBack={
            organizations.length > 1 ? handleClearOrganization : undefined
          }
          emptyMessage="No spaces found!"
        />
      </Flex>
    );
  }

  if (organizations && !currentOrganization) {
    if (isRestrictedMode) {
      return (
        <Row
          style={{
            height: '100%',
          }}
          align="middle"
          justify="center"
        >
          <Card
            actions={[
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button onClick={redirectToLogout}>Logout</Button>
              </div>,
            ]}
            style={{ width: '350px' }}
          >
            <Meta
              avatar={
                <CloseCircleOutlined
                  style={{ color: 'red', fontSize: '30px' }}
                />
              }
              title={error}
              description="You are not authorised to access this organization. Please ask for assistance from the concerned person."
            />
          </Card>
        </Row>
      );
    }
    return (
      <Flex center>
        <SelectionPage
          key="org"
          title="Choose Organization"
          onSelect={handleSelectOrganization}
          options={organizations}
          extra={
            <Button
              onClick={redirectToLogout}
              size="small"
              danger
              icon={<LogoutOutlined />}
            >
              Logout
            </Button>
          }
          emptyMessage="No organizations with VMS plan found!"
        />
      </Flex>
    );
  }

  return <Loader />;
};
