import { useRef, useLayoutEffect } from 'react';
import { useRefValue } from './useRefValue';

export function useAnimationFrame(callback: (() => void) | null) {
  const savedCallback = useRefValue(callback);

  const frameRef = useRef<number | null>(null);

  const shouldRun = callback !== null;

  useLayoutEffect(() => {
    function loop() {
      if (!shouldRun) {
        return;
      }

      if (savedCallback.current) {
        savedCallback.current();
      }

      frameRef.current = window.requestAnimationFrame(loop);
    }

    frameRef.current = window.requestAnimationFrame(loop);

    return () => {
      if (frameRef.current !== null) {
        cancelAnimationFrame(frameRef.current);
        frameRef.current = null;
      }
    };
  }, [savedCallback, shouldRun]);
}
