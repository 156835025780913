/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import clsx from 'clsx';
import { Alert, Button, Dropdown, Form, Input, List, Typography } from 'antd';
import {
  IErrorResponse,
  Loader,
  toast,
  Card,
} from '@datapeace/1up-frontend-web-ui';
import { debounce } from '@datapeace/1up-frontend-web-utils';
import {
  ISpace,
  getOrganizationSpaces,
} from '@datapeace/1up-frontend-shared-api';
import { DownOutlined } from '@ant-design/icons';
import { useConfig, useConfigContainer } from '@datapeace/vms-web-hooks';
import styles from './home-space-selection-menu.module.scss';

type OptionType = { id: string | number; name: string };

export interface HomeSpaceSelectionScreenProps<
  T extends OptionType = OptionType
> {
  title: React.ReactNode;
  options: T[];
  onSelect: (org: T) => void;
  extra?: React.ReactNode;
  emptyMessage?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  isFetching: boolean;
  handleLoadMore: () => void;
  hasMoreNextPageItems: string | null;
  loadMoreText?: string;
  currentSpace: ISpace | null;
}
const PAGE_SIZE = 10;
const defaultSearchFilterValue = {
  searchText: '',
};
const HomeSpaceSelection = <T extends OptionType = OptionType>({
  title,
  options,
  onSelect,
  emptyMessage = 'No items found!',
  extra = null,
  className,
  style,
  isFetching,
  handleLoadMore,
  hasMoreNextPageItems,
  loadMoreText,
  currentSpace,
}: HomeSpaceSelectionScreenProps<T>) => {
  const renderList = () => {
    if (options.length > 0) {
      return (
        <List>
          {options.map((item) => (
            <List.Item
              className={clsx(
                currentSpace?.id === item?.id
                  ? styles.ListItemSelected
                  : styles.ListItem
              )}
              key={`${item?.id} + ${item?.name}`}
              onClick={() => {
                onSelect(item);
                window.location.reload();
              }}
            >
              {item.name}
            </List.Item>
          ))}
        </List>
      );
    }
    return (
      !isFetching && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
          }}
        >
          <Alert
            message={emptyMessage}
            type="warning"
            showIcon
            style={{
              flexGrow: 1,
            }}
          />
        </div>
      )
    );
  };

  const renderLoader = () => {
    if (isFetching) {
      return <Loader style={{ minHeight: '50px' }} text="" />;
    }
    return null;
  };

  const renderLoadMoreButton = () => {
    if (hasMoreNextPageItems && !isFetching) {
      return (
        <button
          style={{
            width: '100%',
            height: 40,
            cursor: 'pointer',
            border: 'hidden',
          }}
          onClick={() => handleLoadMore()}
          type="button"
          disabled={isFetching}
        >
          <div className={styles.LoadMore}>
            {loadMoreText || 'Load More ...'}
          </div>
        </button>
      );
    }
    return null;
  };

  return (
    <Card
      className={clsx(styles.HomeSpaceSelectionScreen, className)}
      style={style}
      title={title}
      extra={extra}
    >
      {renderList()}
      {renderLoader()}
      {renderLoadMoreButton()}
    </Card>
  );
};

const HomeSpaceSelectionMenu = () => {
  const {
    organizations,
    currentOrganization,
    currentSpace,
    handleSelectSpace,
  } = useConfigContainer();
  const [form] = Form.useForm();

  const [isFetchingOrganizationSpace, setIsFetchingOrganizationSpace] =
    React.useState(true);
  const [organizationSpaces, setOrganizationSpaces] = React.useState<ISpace[]>(
    []
  );

  const [apiParams, setApiParams] = React.useState({
    page: 1,
    searchFilterSubmittedValue: defaultSearchFilterValue.searchText,
  });

  const [organizationSpacesNext, setOrganizationSpacesNext] = React.useState<
    string | null
  >(null);
  const fetchOrganizationSpace = React.useCallback(
    async ({
      page = 1,
      searchText = defaultSearchFilterValue.searchText,
      clearPreviousData = false,
    }: {
      page: number;
      searchText: string;
      clearPreviousData: boolean;
    }) => {
      if (organizations && currentOrganization) {
        setIsFetchingOrganizationSpace(true);
        clearPreviousData && setOrganizationSpaces([]);
        try {
          const { items, next } = await getOrganizationSpaces(
            currentOrganization?.id,
            {
              search: searchText,
              page,
              pageSize: PAGE_SIZE,
              active: 1,
            }
          );
          setOrganizationSpaces((prevState) => [...prevState, ...items]);
          setOrganizationSpacesNext(next);
          setApiParams({
            page,
            searchFilterSubmittedValue: searchText,
          });
        } catch (err) {
          toast.error(err as IErrorResponse);
        }

        setIsFetchingOrganizationSpace(false);
      }
    },
    [currentOrganization, organizations]
  );

  React.useEffect(() => {
    fetchOrganizationSpace({
      page: 1,
      searchText: '',
      clearPreviousData: true,
    });
  }, [fetchOrganizationSpace]);

  const handleSearchFilterSubmit = (value: string) => {
    fetchOrganizationSpace({
      page: 1,
      searchText: value,
      clearPreviousData: true,
    });
  };

  const handleLoadMoreOrganizationSpace = ({
    page,
    searchText,
  }: {
    page: number;
    searchText: string;
  }) => {
    if (!organizationSpacesNext) {
      return;
    }

    fetchOrganizationSpace({
      page,
      searchText,
      clearPreviousData: false,
    });
  };

  const handleSearchFilterSubmitWithDebounce = debounce(
    (searchText: string) => {
      handleSearchFilterSubmit(searchText);
    },
    400
  );

  return (
    <HomeSpaceSelection
      key="space"
      title={
        <Form className={styles.Container} form={form} layout="vertical">
          <Form.Item
            name="choose-space"
            label="Switch Space"
            style={{
              marginBottom: 0,
              width: '100%',
            }}
            rules={[
              ({ getFieldValue }) => ({
                validator() {
                  if (
                    getFieldValue('choose-space') !== '' &&
                    getFieldValue('choose-space').length < 3
                  ) {
                    return Promise.reject(
                      new Error('Please enter at least 3 characters to search.')
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Search
              autoFocus
              allowClear
              placeholder="Type here to search space"
              onChange={(event) => {
                if (
                  event.target.value.length >= 3 ||
                  event.target.value === ''
                ) {
                  handleSearchFilterSubmitWithDebounce(event.target.value);
                }
              }}
              onSearch={(value, event) => {
                event && event.preventDefault();
                if (value !== '' && value.length >= 3) {
                  handleSearchFilterSubmitWithDebounce(value);
                }
              }}
            />
          </Form.Item>
        </Form>
      }
      onSelect={handleSelectSpace}
      isFetching={isFetchingOrganizationSpace}
      handleLoadMore={() => {
        handleLoadMoreOrganizationSpace({
          page: apiParams.page + 1,
          searchText: apiParams.searchFilterSubmittedValue,
        });
      }}
      currentSpace={currentSpace}
      hasMoreNextPageItems={organizationSpacesNext}
      options={organizationSpaces}
      emptyMessage="No spaces found!"
    />
  );
};

const SpaceIcon = () => {
  return (
    <svg
      aria-hidden="true"
      data-icon="building"
      width="20"
      height="20"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M128 148v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm140 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-128 96h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm128 0h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-76 84v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm76 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm180 124v36H0v-36c0-6.6 5.4-12 12-12h19.5V24c0-13.3 10.7-24 24-24h337c13.3 0 24 10.7 24 24v440H436c6.6 0 12 5.4 12 12zM79.5 463H192v-67c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v67h112.5V49L80 48l-.5 415z"
      />
    </svg>
  );
};

export const HomeSpaceSelectionDropDown = () => {
  const { currentSpace } = useConfig();
  return (
    <div className={clsx(styles.HomeSpaceSelection)}>
      {/* @ts-ignore */}
      <Dropdown
        overlay={() => <HomeSpaceSelectionMenu />}
        trigger={['click']}
        className={styles.Dropdown}
        placement={window?.innerWidth < 601 ? 'bottomCenter' : 'bottomLeft'}
      >
        <Button>
          <div className={styles.CurrentSpace}>
            <SpaceIcon />
            <Typography.Text
              style={{ maxWidth: '210px' }}
              ellipsis={{ tooltip: currentSpace?.name }}
            >
              {currentSpace?.name}
            </Typography.Text>
            <DownOutlined />
          </div>
        </Button>
      </Dropdown>{' '}
    </div>
  );
};
