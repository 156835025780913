import {
  instance,
  verifyVaccinationCertificateQR,
} from '@datapeace/1up-frontend-shared-api';
import {
  checkVaccinationProcess,
  parseVaccinationData,
} from '@datapeace/1up-frontend-web-utils';
import { useEffect, useState } from 'react';
import { IErrorResponse, toast } from '@datapeace/1up-frontend-web-ui';
import {
  ProcessType,
  useProcessDataContext,
  useProfileContext,
  useRouter,
} from '@datapeace/vms-web-models';
import { getSettingsFromLocalStorage, ROUTES } from '@datapeace/vms-web-utils';

export function useVaccinationFormContainer() {
  const {
    setProcessData,
    processType,
    isVaccinationVerificationRequired,
    vaccinationVerificationSkipAllowed,
    partiallyVaccinatedVisitorCheckinAllowed,
    getBackNavigationRoute,
  } = useProcessDataContext();
  const { setCurrentRoute } = useRouter();
  const { email } = useProfileContext();
  const { cameraFacing } = getSettingsFromLocalStorage(email);
  const [videoFacingMode, setVideoFacingMode] = useState<
    'environment' | 'user'
  >(cameraFacing || 'environment');
  const [resetCameraView, setResetCameraView] = useState<boolean>();
  useEffect(() => {
    if (
      processType === ProcessType.Checkout ||
      !isVaccinationVerificationRequired
    ) {
      setCurrentRoute(ROUTES.CHECK_IN_FORM);
    }
  });
  async function handleSubmitVaccinationForm(capturedImageData: string) {
    if (!capturedImageData) {
      throw new Error('Failed to capture! Please try again');
    }
    const vaccinationCertificateData = await verifyVaccinationCertificateQR(
      capturedImageData,
      instance.client
    );
    try {
      checkVaccinationProcess(
        vaccinationCertificateData,
        partiallyVaccinatedVisitorCheckinAllowed
      );
    } catch (e) {
      toast.error(e as IErrorResponse);
      return;
    }
    setProcessData({
      vaccinationVerificationData: parseVaccinationData(
        vaccinationCertificateData
      ),
    });
    toast.success({ message: 'Vaccination Certificate Verified!' });
    setCurrentRoute(ROUTES.CHECK_IN_FORM);
  }
  function handleSetVideoFacingMode() {
    setResetCameraView(true);
    setTimeout(() => {
      setVideoFacingMode((prev) =>
        prev === 'environment' ? 'user' : 'environment'
      );
      setResetCameraView(false);
    }, 100);
  }
  function handleSkipVaccinationForm() {
    setProcessData({
      vaccinationVerificationData: {
        vaccinationCertData: null,
        vaccinationVerificationStatus: 'NONE',
      },
    });
    toast.info({ message: 'Vaccination Certificate Verificaton Skipped' });
    setCurrentRoute(ROUTES.CHECK_IN_FORM);
  }
  function handleGoBack() {
    setCurrentRoute(getBackNavigationRoute(ROUTES.SCREENING_FORM));
  }
  return {
    handleSubmitVaccinationForm,
    videoFacingMode,
    handleSetVideoFacingMode,
    resetCameraView,
    handleSkipVaccinationForm,
    vaccinationVerificationSkipAllowed,
    handleGoBack,
  };
}
