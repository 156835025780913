import {
  VaccinationVerificationData,
  VerifyVaccinationCertificate,
} from '@datapeace/1up-frontend-shared-api';

export function checkVaccinationProcess(
  vaccinationCertificateData: VerifyVaccinationCertificate | null,
  partiallyVaccinatedVisitorCheckinAllowed: boolean
) {
  // if skipped
  if (!vaccinationCertificateData) {
    throw new Error('Vaccination verification is required!');
  }
  const { vaccineDosesGiven, vaccineTotalDosesNeeded } =
    vaccinationCertificateData.shortInfo;
  const status: VaccinationVerificationData['vaccinationVerificationStatus'] =
    vaccineDosesGiven < vaccineTotalDosesNeeded ? 'PARTIALLY' : 'FULLY';
  // if partially not allowed
  if (!partiallyVaccinatedVisitorCheckinAllowed && status === 'PARTIALLY') {
    throw new Error(
      'You must be fully vaccinated with all the required vaccine doses to complete this process.'
    );
  }
}

export function parseVaccinationData(
  vaccinationCertificateData: VerifyVaccinationCertificate | null
): VaccinationVerificationData {
  if (!vaccinationCertificateData) {
    return {
      vaccinationCertData: {},
      vaccinationVerificationStatus: 'NONE',
    };
  }
  const { vaccineDosesGiven, vaccineTotalDosesNeeded } =
    vaccinationCertificateData.shortInfo;
  return {
    vaccinationCertData: vaccinationCertificateData.certificateData,
    vaccinationVerificationStatus:
      vaccineDosesGiven < vaccineTotalDosesNeeded ? 'PARTIALLY' : 'FULLY',
  };
}
