import { useEffect } from 'react';
import { IErrorResponse, toast } from '@datapeace/1up-frontend-web-ui';
import {
  useRouter,
  useProcessDataContext,
  IDynamicForm,
  ProcessType,
} from '@datapeace/vms-web-models';
import { ROUTES, validateFields } from '@datapeace/vms-web-utils';
import { useConfig } from '@datapeace/vms-web-hooks';

export function useScreeningFormContainer() {
  const { setCurrentRoute } = useRouter();
  const { screeningFormSchema, checkinWithoutScreening } = useConfig();
  const {
    screeningFormData,
    setProcessData,
    isScreeningRequired,
    processType,
    getBackNavigationRoute,
  } = useProcessDataContext();

  const fields = [
    ...(screeningFormSchema?.fields || []).map((item) => ({
      ...item,
      required: !checkinWithoutScreening,
    })),
    {
      name: 'declration',
      label: 'Declaration',
      type: 'checkbox',
      required: !checkinWithoutScreening,
      options: [
        {
          key: 'declaration',
          label:
            'I, hereby declare that the information furnished above is true, complete and correct to the best of my knowledge and belief. I understand that in the event of my information being found false or incorrect at any stage, I shall be responsible for the consequences.',
        },
      ],
    },
  ];

  const handleSubmit = async () => {
    try {
      validateFields(fields, screeningFormData);
      setCurrentRoute(ROUTES.VACCINATION_FORM);
    } catch (error) {
      toast.error(error as IErrorResponse);
    }
  };

  useEffect(() => {
    if (
      processType === ProcessType.Checkout ||
      !isScreeningRequired ||
      !screeningFormSchema?.fields?.length
    ) {
      setCurrentRoute(ROUTES.VACCINATION_FORM);
    }
  });

  function handleGoBack() {
    setCurrentRoute(getBackNavigationRoute(ROUTES.REGISTER));
  }

  return {
    onChange: (data: IDynamicForm) =>
      setProcessData({ screeningFormData: data }),
    onSubmit: handleSubmit,
    fields,
    values: screeningFormData || {},
    title: screeningFormSchema?.title || '',
    description: screeningFormSchema?.description || '',
    handleGoBack,
  };
}
