import { FC } from 'react';
import clsx from 'clsx';
import styles from './loader.module.scss';
import { LoadingOutlined } from '../icons';
import { Row, Spin } from '..';
import { RowProps } from 'antd';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface IProps {
  text?: string;
  inline?: boolean;
}

export const Loader: FC<IProps & RowProps> = ({
  text = 'Loading...',
  inline = false,
  ...remainingProps
}) => (
  <Row
    align="middle"
    justify="center"
    className={clsx(styles.Loader, inline && styles.LoaderInline)}
    {...remainingProps}
  >
    <Spin indicator={antIcon} size="large" tip={text} />
  </Row>
);
