import { useState, useEffect } from 'react';
import {
  IOrganizationDetailed,
  ISubscription,
} from '@datapeace/1up-frontend-shared-api';
import { IErrorResponse } from '@datapeace/1up-frontend-web-ui';
import { Api } from '@datapeace/vms-web-utils';

export function useGetOrganizations(
  onError: (error: string) => void
): [
  IOrganizationDetailed[] | null,
  (organizations: IOrganizationDetailed[] | null) => void
] {
  const [organizations, setOrganizations] = useState<
    IOrganizationDetailed[] | null
  >(null);

  useEffect(() => {
    const getOrganizations = async () => {
      try {
        const { items: orgs } = await Api.getOrganizations();
        const hasActiveSubscriptionOrgs = orgs.filter((org) => {
          return org.activeSubscriptions.some((subscription: ISubscription) =>
            subscription.plan.product.shortName.includes('VMS')
          );
        });
        setOrganizations(hasActiveSubscriptionOrgs);
      } catch (err) {
        onError((err as IErrorResponse).message);
      }
    };

    getOrganizations();
  }, [onError]);

  return [organizations, setOrganizations];
}
