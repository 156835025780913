import { Alert } from '@datapeace/1up-frontend-web-ui';
import { isArray } from '@datapeace/1up-frontend-web-utils';
import { CustomStatusCard } from '@datapeace/vms-web-components';
import { ROUTES } from '@datapeace/vms-web-utils';
import { useVisitErrorContainer } from '@datapeace/vms-web-containers';
import { useRouter } from '@datapeace/vms-web-models';

export function VisitErrorScreen() {
  const { setCurrentRoute } = useRouter();
  const {
    faceData,
    firstName,
    lastName,
    visitErrorMessage,
    resendDelay,
    shouldRedirect,
  } = useVisitErrorContainer();

  return (
    <CustomStatusCard
      status="error"
      content={
        isArray(visitErrorMessage?.fields?.non_field_errors) ? (
          <ul>
            {visitErrorMessage?.fields?.non_field_errors.map((e) => (
              <li>{typeof e === 'string' ? e : JSON.stringify(e)}</li>
            ))}
          </ul>
        ) : (
          visitErrorMessage?.message
        )
      }
      handleGoBack={() => setCurrentRoute(ROUTES.HOME)}
      title={`${firstName} ${lastName}`}
      message="Submission Failed"
      meta={
        <small>
          Please check and modify the information before resubmitting
        </small>
      }
      avatar={faceData ? faceData?.dataUrl : ''}
    >
      {shouldRedirect && (
        <Alert
          style={{ marginTop: '1em' }}
          type="info"
          message={`This screen will close in ${resendDelay} seconds`}
          banner
        />
      )}
    </CustomStatusCard>
  );
}
