import { IFormField } from '@datapeace/1up-frontend-shared-api';

export const CUSTOM_FIELD_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  TOGGLE: 'toggle',
  DROPDOWN: 'dropdown',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  DATE: 'date',
  TIME: 'time',
  DATETIME: 'datetime',
  CAMERA: 'camera',
  PEOPLE: 'people',
  PHONE_NUMBER: 'tel',
  GROUP: 'group',
  FILE: 'file',
};

function getValueObject(
  { name, label, type, options = [] }: any,
  fieldValue: any
) {
  if (!fieldValue) {
    return {
      name,
      label,
      type,
      value:
        type === CUSTOM_FIELD_TYPES.PEOPLE || type === CUSTOM_FIELD_TYPES.GROUP
          ? []
          : [{ key: null, label: '' }],
    };
  }

  let value: {
    key: string | null;
    label:
      | string
      | {
          key: string | null;
          label: string;
        };
  }[] = [{ key: null, label: fieldValue }];

  let selectedOptions;

  switch (type) {
    case CUSTOM_FIELD_TYPES.RADIO:
    case CUSTOM_FIELD_TYPES.DROPDOWN:
      selectedOptions = options.find(
        (option: any) => option.key === fieldValue
      );
      if (selectedOptions) {
        value = [selectedOptions];
      }
      break;

    case CUSTOM_FIELD_TYPES.CHECKBOX:
      if (Array.isArray(fieldValue)) {
        value = options.filter((option: any) =>
          fieldValue.includes(option.key)
        );
      }
      break;

    case CUSTOM_FIELD_TYPES.PEOPLE:
      if (
        typeof fieldValue !== 'string' &&
        fieldValue.key &&
        fieldValue.label
      ) {
        value = fieldValue.key
          ? [{ key: fieldValue.key, label: fieldValue.label }]
          : [];
      } else {
        value = [];
      }
      break;

    case CUSTOM_FIELD_TYPES.GROUP:
      value = fieldValue;
      break;

    case CUSTOM_FIELD_TYPES.FILE:
      value = [{ key: fieldValue.key, label: fieldValue.label }];
      break;

    default:
      break;
  }

  // convert label to string
  if (type !== CUSTOM_FIELD_TYPES.GROUP) {
    value = value.map((v) => ({ key: v.key, label: String(v.label) }));
  }

  return { name, label, type, value };
}

function normalizeValueObject(
  fields: any,
  formSchemaFields?: IFormField[] | null
) {
  const { data = [] } = fields || {};

  return data.reduce(
    (
      values: any,
      {
        name,
        type,
        value,
        options,
      }: {
        name: string;
        type: string;
        value: any;
        options?: { key: string; label: string }[];
      }
    ) => {
      let fieldValue;

      switch (type) {
        case CUSTOM_FIELD_TYPES.RADIO:
        case CUSTOM_FIELD_TYPES.DROPDOWN:
          if (formSchemaFields) {
            const schemaField = formSchemaFields.find(
              (sField) => sField.name === name
            );
            fieldValue = schemaField?.options?.some(
              (option) => option.key === value[0].key
            )
              ? value[0].key
              : null;
          } else {
            fieldValue = value[0].key;
          }
          break;

        case CUSTOM_FIELD_TYPES.CHECKBOX:
          fieldValue = value.map((v: { key: string }) => v.key);
          break;

        case CUSTOM_FIELD_TYPES.TOGGLE:
          fieldValue = value[0].label === 'true' || value[0].label === true;
          break;

        case CUSTOM_FIELD_TYPES.PEOPLE:
          [fieldValue] = value;
          break;

        case CUSTOM_FIELD_TYPES.GROUP:
          fieldValue = value;
          break;

        case CUSTOM_FIELD_TYPES.FILE:
          fieldValue = value?.[0];
          break;

        default:
          fieldValue = value[0].label;
      }

      return { ...values, [name]: fieldValue };
    },
    {}
  );
}

export * from './text-dynamic-field';
export * from './drop-down-dynamic-field';
export * from './toggle-dynamic-field';
export * from './radio-dynamic-field';
export * from './check-box-dynamic-field';
export * from './date-time-dynamic-field';
export * from './camera-dynamic-field';
export * from './verification-code-dynamic-field';
export * from './phone-number-dynamic-field';
export * from './group-dynamic-field';
export * from './people-dynamic-field';
export * from './file-dynamic-field';

export { getValueObject, normalizeValueObject };
