/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useState } from 'react';
import { CloseOutlined, CameraOutlined } from '@ant-design/icons';
import { Button, Modal } from '@datapeace/1up-frontend-web-ui';
import { WebcamUploader } from '@datapeace/vms-web-components';
import { IBlobWithDataURL } from '@datapeace/1up-frontend-web-utils';

export interface ICameraProps {
  name: string;
  onChange: (name: string, url: string) => void;
  value?: string;
  disabled?: boolean;
  onUploadFile: (file: IBlobWithDataURL) => Promise<{
    fileUrl: string;
  }>;
}

export const Camera: FC<ICameraProps> = ({
  name,
  onChange,
  value,
  disabled,
  onUploadFile,
}) => {
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const handleCapture = (url: string, imageData: string) => {
    setIsCameraOpen(false);
    setPreviewImage(imageData);
    onChange(name, url);
  };

  const handleClearImage = () => {
    setPreviewImage('');
    if (value) {
      onChange(name, '');
    }
  };

  const imageToShow = previewImage || value;

  return (
    <div>
      {/* @ts-ignore */}
      <Modal
        destroyOnClose
        bodyStyle={{ padding: 0 }}
        visible={isCameraOpen}
        onCancel={() => setIsCameraOpen(false)}
        footer={null}
      >
        <WebcamUploader
          onCapture={handleCapture}
          uploadHandler={onUploadFile}
        />
      </Modal>
      {imageToShow && (
        <img
          style={{
            width: '8em',
            height: '8em',
            background: '#eee',
            border: '1px solid #ddd',
            display: 'inline-block',
            verticalAlign: 'middle',
            objectFit: 'contain',
          }}
          src={imageToShow}
          alt=""
        />
      )}
      <Button
        style={{ margin: imageToShow && '0 1em' }}
        onClick={imageToShow ? handleClearImage : () => setIsCameraOpen(true)}
        size="large"
        icon={imageToShow ? <CloseOutlined /> : <CameraOutlined />}
        disabled={disabled}
      >
        {imageToShow ? 'Clear' : 'Take photo'}
      </Button>
    </div>
  );
};
