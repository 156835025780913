import React, {
  createContext,
  useContext,
  useReducer,
  useRef,
  FC,
  Reducer,
  useCallback,
} from 'react';
import {
  IInvitationDetailed,
  IVisit,
  IVisitorItemByMobileNumber,
  VaccinationVerificationData,
} from '@datapeace/1up-frontend-shared-api';
import { IErrorResponse } from '@datapeace/1up-frontend-web-ui';
import { ROUTES, getVisibleFields } from '@datapeace/vms-web-utils';
import { getFieldsDataFromConfig } from '../containers/useCustomFormContainer';
import { normalizeValueObject } from '@datapeace/vms-web-components';
import { useConfig } from '@datapeace/vms-web-hooks';

enum ActionType {
  SetProcessData = 'SetProcessData',

  HandleEnteredWelcomeScreen = 'HandleEnteredWelcomeScreen',
  HandleEnteredCameraScreen = 'HandleEnteredCameraScreen',
  HandleSelectedDifferentPerson = 'HandleSelectedDifferentPerson',
}

export enum ProcessType {
  Checkin = 'Checkin',
  Checkout = 'Checkout',
}

export interface IFaceData {
  dataUrl: string;
  imageUrl: string;
}

export interface IRegisterData {
  firstName: string;
  lastName: string;
  mobileNumber: string;

  invitationCode?: string;
}

export interface IPersonData extends Omit<IRegisterData, 'invitationCode'> {
  id: number;
  hasIndexedFaces?: boolean;
}

export interface IDynamicForm {
  [key: string]: any;
}

interface ErrorMessage {
  title: string;
  display_text: string;
  errorResponse?: IErrorResponse;
}

interface IState {
  processType: ProcessType | null;
  faceData: IFaceData | null;
  personData: IPersonData | null; // person data received from API
  activeVisit: IVisit | null;
  lastVisit: IVisit | null;
  registerData: IRegisterData | null; // person data filled by user
  notYouRegisterData: Partial<IPersonData> | null;
  invitationId: number | null;
  visitInvitation: IInvitationDetailed | null;
  customFormData: IDynamicForm | null;
  isFormAutofillEnabled: boolean;
  screeningFormData: IDynamicForm | null;
  signatureImage: string;
  tncEmail: string;
  checkinByMobile: boolean; // true when clicked on 'not you'
  shouldFillForm: boolean;
  verifyOtpAttempt: number;
  isUsingQr: boolean;
  isFaceRequired: boolean;
  isScreeningRequired: boolean;
  bodyTemp: string | null;
  bodyTempUnit: 'C' | 'F' | null;
  visitRequestId: number | null;
  isTemperatureRequired: boolean;
  isTncRequired: boolean;
  visitErrorMessage: IErrorResponse | null;
  isCheckinOtpRequired: boolean;
  isCheckoutOtpRequired: boolean;
  checkinVerified: boolean | null;
  checkoutVerified: boolean | null;
  isVaccinationVerificationRequired: boolean;
  vaccinationVerificationData: VaccinationVerificationData | null;
  vaccinationVerificationSkipAllowed: boolean;
  partiallyVaccinatedVisitorCheckinAllowed: boolean;
  otpTicket: string;
  hasSkippedMobileVerification: boolean;
  availableVisitorListByMobileNumber: {
    mobile: string;
    visitors: IVisitorItemByMobileNumber[];
  } | null;
  isNotYouFlow: boolean;
}

const initialState: IState = {
  processType: null,
  faceData: null,
  personData: null, // person data received from API
  activeVisit: null,
  lastVisit: null,
  registerData: null, // person data filled by user
  notYouRegisterData: null,
  invitationId: null,
  visitInvitation: null,
  customFormData: null,
  isFormAutofillEnabled: true,
  screeningFormData: null,
  checkinByMobile: false, // true when clicked on 'not you'
  signatureImage: '',
  tncEmail: '',
  shouldFillForm: true,
  verifyOtpAttempt: 0,
  isUsingQr: false,
  isFaceRequired: false,
  isScreeningRequired: false,
  bodyTemp: null,
  bodyTempUnit: null,
  visitRequestId: null,
  isTemperatureRequired: true,
  isTncRequired: true,
  visitErrorMessage: null,
  isCheckinOtpRequired: false,
  checkinVerified: false,
  checkoutVerified: false,
  isCheckoutOtpRequired: false,
  isVaccinationVerificationRequired: false,
  vaccinationVerificationData: null,
  vaccinationVerificationSkipAllowed: true,
  partiallyVaccinatedVisitorCheckinAllowed: true,
  otpTicket: '',
  hasSkippedMobileVerification: false,
  availableVisitorListByMobileNumber: null,
  isNotYouFlow: false,
};

type IAction =
  | { type: ActionType.SetProcessData; payload: Partial<IState> }
  | { type: ActionType.HandleEnteredWelcomeScreen }
  | { type: ActionType.HandleSelectedDifferentPerson };

interface IProcessActions {
  setProcessData: (state: Partial<IState>) => void;

  handleEnteredWelcomeScreen: () => void;
  handleSelectedDifferentPerson: () => void;
}

interface IProcessDataContext extends IState, IProcessActions {
  getBackNavigationRoute: (
    intendedRoute: ValueOf<typeof ROUTES>
  ) => ValueOf<typeof ROUTES>;
}

export const ProcessDataContext = createContext<IProcessDataContext | null>(
  null
);

export const useProcessDataContext = () => {
  const processData = useContext(ProcessDataContext);

  if (!processData) {
    throw new Error(
      "ProcessDataContext should be consumed inside it's Provider"
    );
  }

  return processData;
};

const reducer: React.Reducer<IState, IAction> = (state, action) => {
  switch (action.type) {
    case ActionType.SetProcessData:
      return { ...state, ...action.payload };

    case ActionType.HandleEnteredWelcomeScreen:
      // reset state when entered Welcome screen
      return initialState;

    case ActionType.HandleSelectedDifferentPerson:
      return {
        ...state,
        personData: null, // person data received from API
        activeVisit: null,
        lastVisit: null,
        registerData: null, // person data filled by user
        notYouRegisterData: null,
        customFormData: null,
        isFormAutofillEnabled: true,
        screeningFormData: null,
        checkinByMobile: false, // true when clicked on 'not you'
        signatureImage: '',
        tncEmail: '',
        shouldFillForm: true,
        verifyOtpAttempt: 0,
        isFaceRequired: false,
        isScreeningRequired: false,
        bodyTemp: null,
        bodyTempUnit: null,
        visitRequestId: null,
        isTemperatureRequired: true,
        isTncRequired: true,
        visitErrorMessage: null,
        isCheckinOtpRequired: false,
        checkinVerified: false,
        checkoutVerified: false,
        isCheckoutOtpRequired: false,
        isVaccinationVerificationRequired: false,
        vaccinationVerificationData: null,
        vaccinationVerificationSkipAllowed: true,
        partiallyVaccinatedVisitorCheckinAllowed: true,
        otpTicket: '',
        hasSkippedMobileVerification: false,
        availableVisitorListByMobileNumber: null,
        isNotYouFlow: false,
      };

    default:
      throw new Error('Invalid action!');
  }
};

export const ProcessDataProvider: FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer<Reducer<IState, IAction>>(
    reducer,
    initialState
  );

  const config = useConfig();

  const getBackNavigationRoute: (
    intendedRoute: ValueOf<typeof ROUTES>
  ) => ValueOf<typeof ROUTES> = useCallback(
    (intendedRoute: ValueOf<typeof ROUTES>) => {
      // HOME -> CAPTURE -> REGISTRATION [<-> CHECKIN_BY_MOBILE <-> MOBILE VERIFY <<-> SELECT_VISITOR -> REGISTRATION] -> SCREENING_FORM -> VACCINATION_FORM -> CUSTOM_FORM -> TERMS_AND_CONDITIONS -> TEMPERATURE -> CONFIRM

      const { checkinFormSchema, checkoutFormSchema, screeningFormSchema } =
        config;

      const {
        processType,
        lastVisit,
        isFormAutofillEnabled,
        visitInvitation,
        activeVisit,
        customFormData,
        isTemperatureRequired,
        isTncRequired,
        shouldFillForm,
        isVaccinationVerificationRequired,
        isScreeningRequired,
      } = state;

      switch (intendedRoute) {
        case ROUTES.TEMPERATURE:
          if (isTemperatureRequired) {
            return ROUTES.TEMPERATURE;
          }
          return getBackNavigationRoute(ROUTES.TERMS);

        case ROUTES.TERMS:
          if (processType !== ProcessType.Checkout && isTncRequired) {
            return ROUTES.TERMS;
          }
          return getBackNavigationRoute(ROUTES.CHECK_IN_FORM);

        case ROUTES.CHECK_IN_FORM: {
          const fields = getFieldsDataFromConfig(
            processType === ProcessType.Checkin,
            checkinFormSchema,
            checkoutFormSchema
          );

          const lastVisitValues = {
            // values from last visit
            ...normalizeValueObject(
              lastVisit?.checkinCustomData,
              checkinFormSchema?.fields
            ),
            ...normalizeValueObject(
              lastVisit?.checkoutCustomData,
              checkoutFormSchema?.fields
            ),
          };

          const values = {
            // autofill values from last visit (only use if autofill enabled)
            ...(isFormAutofillEnabled && lastVisitValues),

            // autofill values from invitation prefill
            ...normalizeValueObject(
              visitInvitation?.prefilledCheckinCustomData,
              checkinFormSchema?.fields
            ),
            ...normalizeValueObject(
              visitInvitation?.prefilledCheckoutCustomData,
              checkoutFormSchema?.fields
            ),

            // autofill values from checkin
            ...normalizeValueObject(
              activeVisit && activeVisit.checkinCustomData,
              checkinFormSchema?.fields
            ),

            // current CustomForm state, highest priority
            ...customFormData,
          };

          const visibleFields = getVisibleFields(fields, values, processType);

          if (visibleFields && visibleFields.length && shouldFillForm) {
            return ROUTES.CHECK_IN_FORM;
          }
          return getBackNavigationRoute(ROUTES.VACCINATION_FORM);
        }

        case ROUTES.VACCINATION_FORM:
          if (
            processType === ProcessType.Checkin &&
            isVaccinationVerificationRequired
          ) {
            return ROUTES.VACCINATION_FORM;
          }
          return getBackNavigationRoute(ROUTES.SCREENING_FORM);

        case ROUTES.SCREENING_FORM:
          if (
            processType === ProcessType.Checkin &&
            isScreeningRequired &&
            screeningFormSchema?.fields?.length
          ) {
            return ROUTES.SCREENING_FORM;
          }
          return getBackNavigationRoute(ROUTES.REGISTER);

        case ROUTES.REGISTER:
          return ROUTES.REGISTER;

        default:
          return ROUTES.HOME;
      }
    },
    [state, config]
  );

  const actions = useRef<IProcessActions>({
    setProcessData: (payload) =>
      dispatch({ type: ActionType.SetProcessData, payload }),
    handleEnteredWelcomeScreen: () =>
      dispatch({ type: ActionType.HandleEnteredWelcomeScreen }),
    handleSelectedDifferentPerson: () =>
      dispatch({ type: ActionType.HandleSelectedDifferentPerson }),
  });

  return (
    <ProcessDataContext.Provider
      value={{ ...state, ...actions.current, getBackNavigationRoute }}
    >
      {children}
    </ProcessDataContext.Provider>
  );
};
