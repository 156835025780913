import { useEffect } from 'react';
import { useRefValue } from '.';

export function useTimeout(callback: () => void, delay: number | null = 1000) {
  // Remember the latest callback.
  const callbackRef = useRefValue(callback);

  // Set up the timeout.
  useEffect(() => {
    if (delay === null) return;

    const id = setTimeout(callbackRef.current, delay);

    // eslint-disable-next-line consistent-return
    return (): void => clearTimeout(id);
  }, [delay, callbackRef]);
}
