import React from 'react';
import clsx from 'clsx';
import { Button, Card, List, Alert, ArrowLeftOutlined } from '..';
import styles from './selection-page.module.scss';

type OptionType = { id: string | number; name: React.ReactText };

export interface SelectionPageProps<T extends OptionType = OptionType> {
  title: string;
  options: T[];
  onSelect: (org: T) => void;
  onBack?: () => void;
  extra?: React.ReactNode;
  emptyMessage?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const SelectionPage = <T extends OptionType = OptionType>({
  title,
  options,
  onSelect,
  onBack,
  emptyMessage = 'No items found!',
  extra = <></>,
  className,
  style,
}: SelectionPageProps<T>) => {
  return (
    <Card
      className={clsx(styles.SelectionPage, className)}
      style={style}
      title={title}
      extra={extra}
      actions={
        onBack && [
          <Button icon={<ArrowLeftOutlined />} onClick={onBack}>
            &nbsp;Back
          </Button>,
        ]
      }
    >
      {options.length ? (
        <List className={styles.List}>
          {options.map((item) => (
            <List.Item
              className={styles.ListItem}
              key={item.id}
              onClick={() => onSelect(item)}
            >
              {item.name}
            </List.Item>
          ))}
        </List>
      ) : (
        <Alert message={emptyMessage} type="warning" showIcon />
      )}
    </Card>
  );
};
