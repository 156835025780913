import { useProcessDataContext } from '@datapeace/vms-web-models';
import { useRedirectOnDone } from '@datapeace/vms-web-hooks';

export function useVisitErrorContainer() {
  const { registerData, faceData, visitErrorMessage } = useProcessDataContext();

  const [resendDelay, shouldRedirect] = useRedirectOnDone('error');

  const { firstName = '', lastName = '' } = registerData || {};

  return {
    firstName,
    lastName,
    faceData,
    visitErrorMessage,
    resendDelay,
    shouldRedirect,
  };
}
