/* eslint-disable @typescript-eslint/ban-ts-comment */
import { IProfile } from '../typings';
import { convertToCamelCase, createPath } from '../utils';
import { instance } from './client';
import { apiProfilePath } from './constants';

export const getProfile = async (): Promise<IProfile> => {
  const res = await instance.client.get<IProfile>(createPath(apiProfilePath), {
    // @ts-ignore
    convertResponseToCamelCase: false,
  });
  return convertToCamelCase(res, ['web_client'], true);
};
