import { FC } from 'react';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  MobileOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { Button, Alert } from '@datapeace/1up-frontend-web-ui';
import clsx from 'clsx';
import { CustomStatusCard } from '@datapeace/vms-web-components';
import { useProcessDataContext, useRouter } from '@datapeace/vms-web-models';
import { ROUTES } from '@datapeace/vms-web-utils';
import styles from '../custom-form-screen/custom-form-screen.module.scss';
import { getMaskedMobileNumber } from '@datapeace/1up-frontend-shared-api';
import { useConfirmContainer } from '@datapeace/vms-web-containers';

export const Confirm: FC = () => {
  const {
    onSubmit,
    status,
    resendDelay,
    faceDataUrl,
    name,
    mobileNumber,
    customMessage,
    handleGoBack,
    shouldRedirect,
  } = useConfirmContainer();

  const { bodyTemp, bodyTempUnit } = useProcessDataContext();
  const { setCurrentRoute } = useRouter();

  const errorActions = [
    <Button size="large" icon={<UndoOutlined />} onClick={onSubmit}>
      Retry
    </Button>,
  ];

  const showCustomIcons = () => {
    return status === 'success' ? (
      <CheckCircleOutlined
        style={{
          color: '#1d8102',
        }}
      />
    ) : (
      <CloseCircleOutlined
        style={{
          color: '#d13212',
        }}
      />
    );
  };

  return (
    <CustomStatusCard
      status={status}
      title={name}
      actions={status === 'error' ? errorActions : undefined}
      message={customMessage?.title || ''}
      avatar={faceDataUrl}
      meta={
        <>
          {mobileNumber && (
            <small>
              <MobileOutlined />
              &nbsp;
              {getMaskedMobileNumber(mobileNumber)}
            </small>
          )}
          {bodyTemp && (
            <small>
              Temperature: &nbsp;
              {bodyTemp}
              &#176;
              {bodyTempUnit}
            </small>
          )}
        </>
      }
      content={
        customMessage?.displayText ||
        (customMessage?.extraJSX && (
          <div>
            {customMessage?.displayText && (
              <p style={{ whiteSpace: 'pre-wrap' }}>
                {customMessage.displayText}
              </p>
            )}
            {customMessage?.extraJSX && customMessage.extraJSX}
          </div>
        ))
      }
      handleGoBack={status === 'error' ? handleGoBack : undefined}
    >
      {status === 'success' || status === 'error' ? (
        <Alert
          className={clsx(
            styles.RedirectAlert,
            status === 'success' && styles.success
          )}
          type={status === 'success' ? 'success' : 'error'}
          showIcon={false}
          message={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                {shouldRedirect ? (
                  <ClockCircleOutlined
                    style={{
                      color: status === 'success' ? '#1d8102' : '#d13212',
                    }}
                  />
                ) : (
                  showCustomIcons()
                )}
                {shouldRedirect
                  ? `This screen will close in ${resendDelay} seconds`
                  : `You can now close this screen.`}
              </div>
              <Button
                onClick={() => setCurrentRoute(ROUTES.HOME)}
                type="dashed"
              >
                Close now
              </Button>
            </div>
          }
          banner
        />
      ) : null}
    </CustomStatusCard>
  );
};
