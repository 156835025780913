import { useEffect, useRef } from 'react';

/** `isMounted.current` is `true` if component is currently *mounted*, otherwise it's `false` */
export function useIsMounted(): { current: boolean } {
  const isMountedRef = useRef(true);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return isMountedRef;
}
