import { FC } from 'react';
import clsx from 'clsx';
import * as styles from './form.module.scss';

export const Form: FC<JSX.IntrinsicElements['form']> = ({
  children,
  className,
  ...props
}) => {
  return (
    <form className={clsx(styles.Form, className)} {...props}>
      {children}
    </form>
  );
};
