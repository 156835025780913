import {
  getVMSConfig,
  getOrganizationConfig,
  getSpaceVMSConfig,
  ISpace,
  IVMSConfig,
  IOrganizationConfig,
  IOrganizationDetailed,
  ISpaceVmsConfig,
} from '@datapeace/1up-frontend-shared-api';
import { IErrorResponse } from '@datapeace/1up-frontend-web-ui';
import { useState, useEffect } from 'react';

type IConfig = IOrganizationConfig &
  IVMSConfig & { isMobilePlan: boolean } & ISpaceVmsConfig;

export function useGetConfig(
  currentOrganization: IOrganizationDetailed | null,
  currentSpace: ISpace | null,
  onError: (error: string) => void
): [IConfig | null, (config: IConfig | null) => void] {
  const [config, setConfig] = useState<IConfig | null>(null);

  useEffect(() => {
    // if space is selected/updated fetch config
    const setupConfig = async () => {
      if (!currentOrganization || !currentSpace) {
        setConfig(null);
        return;
      }

      if (
        !currentOrganization.features.organizationsVmsBasic ||
        !currentOrganization.canAccessVms
      ) {
        onError("Can't access vms");
        return;
      }

      try {
        const vmsConfig = await getVMSConfig(currentSpace.id);
        const organizationConfig = await getOrganizationConfig(
          currentOrganization.id
        );
        const spaceVmsConfig = await getSpaceVMSConfig(currentSpace.id);
        setConfig({
          ...organizationConfig,
          ...vmsConfig,
          ...spaceVmsConfig,
          isMobilePlan:
            organizationConfig.activeFeatures.includes('VMS_MOBILE_NUMBER'),
        });
      } catch (err) {
        onError((err as IErrorResponse).message);
      }
    };

    setupConfig();
  }, [currentOrganization, currentSpace, onError]);

  return [config, setConfig];
}
