import { message, notification } from 'antd';
import { isArray } from 'lodash-es';
import styles from './toast.module.scss';

export interface IErrorResponse {
  message: string;
  fields?: { non_field_errors: string[] };
}

function displayToast(
  error: IErrorResponse,
  type: 'success' | 'error' | 'info' | 'warn',
  duration?: number
) {
  if (isArray(error.fields?.non_field_errors)) {
    const errors = error.fields?.non_field_errors as string[];
    switch (type) {
      case 'error':
        notification.error({
          duration,
          className: styles.Toast,
          message: 'Error',
          description: (
            <ul>
              {errors.map((e) => (
                <li>{typeof e === 'string' ? e : JSON.stringify(e)}</li>
              ))}
            </ul>
          ),
        });
        break;
      case 'success':
        notification.success({
          duration,
          className: styles.Toast,
          message: 'Success',
          description: (
            <ul>
              {errors.map((e) => (
                <li>{typeof e === 'string' ? e : JSON.stringify(e)}</li>
              ))}
            </ul>
          ),
        });
        break;
      case 'info':
        notification.info({
          duration,
          className: styles.Toast,
          message: 'Info',
          description: (
            <ul>
              {errors.map((e) => (
                <li>{typeof e === 'string' ? e : JSON.stringify(e)}</li>
              ))}
            </ul>
          ),
        });
        break;
      case 'warn':
        notification.warn({
          duration,
          className: styles.Toast,
          message: 'Warning',
          description: (
            <ul>
              {errors.map((e) => (
                <li>{typeof e === 'string' ? e : JSON.stringify(e)}</li>
              ))}
            </ul>
          ),
        });
        break;
      default:
        notification.info({
          duration,
          className: styles.Toast,
          message: 'Info',
          description: (
            <ul>
              {errors.map((e) => (
                <li>{typeof e === 'string' ? e : JSON.stringify(e)}</li>
              ))}
            </ul>
          ),
        });
        break;
    }
    return;
  }
  switch (type) {
    case 'error':
      message.error(error.message, duration);
      break;
    case 'info':
      message.info(error.message, duration);
      break;
    case 'warn':
      message.warn(error.message, duration);
      break;
    case 'success':
      message.success(error.message, duration);
      break;
    default:
      message.info(error.message, duration);
      break;
  }
}

function successToast(error: IErrorResponse, duration?: number) {
  displayToast(error, 'success', duration);
}
function errorToast(error: IErrorResponse, duration?: number) {
  displayToast(error, 'error', duration);
}
function infoToast(error: IErrorResponse, duration?: number) {
  displayToast(error, 'info', duration);
}
function warnToast(error: IErrorResponse, duration?: number) {
  displayToast(error, 'warn', duration);
}

export const toast = {
  success: successToast,
  error: errorToast,
  info: infoToast,
  warn: warnToast,
};
