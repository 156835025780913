import React from 'react';
import clsx from 'clsx';
import { Alert, Button, Card, List, Loader } from '../index';
import { ArrowLeftOutlined } from '../icons';
import styles from './custom-selection-page.module.scss';

type OptionType = { id: string | number; name: React.ReactText };

export interface CustomSelectionPageProps<T extends OptionType = OptionType> {
  title: React.ReactNode;
  options: T[];
  onSelect: (org: T) => void;
  onBack?: () => void;
  extra?: React.ReactNode;
  emptyMessage?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  isFetching: boolean;
  handleLoadMore: () => void;
  hasMoreNextPageItems: string | null;
  loadMoreText?: string;
  backText?: string;
}

export const CustomSelectionPage = <T extends OptionType = OptionType>({
  title,
  options,
  onSelect,
  onBack,
  emptyMessage = 'No items found!',
  extra = null,
  className,
  style,
  isFetching,
  handleLoadMore,
  hasMoreNextPageItems,
  loadMoreText,
  backText,
}: CustomSelectionPageProps<T>) => {
  const renderList = () => {
    if (options.length > 0) {
      return (
        <List className={styles.List}>
          {options.map((item, index) => (
            <List.Item
              className={clsx(styles.ListItem)}
              key={`${item?.id} + ${item?.name} + ${index}`}
              onClick={() => onSelect(item)}
            >
              {item.name}
            </List.Item>
          ))}
        </List>
      );
    }
    return (
      !isFetching && <Alert message={emptyMessage} type="warning" showIcon />
    );
  };

  const renderLoader = () => {
    if (isFetching) {
      return <Loader style={{ minHeight: '50px' }} text="" />;
    }
    return null;
  };

  const renderLoadMoreButton = () => {
    if (hasMoreNextPageItems && !isFetching) {
      return (
        <button
          style={{
            width: '100%',
            height: 40,
            cursor: 'pointer',
            border: 'hidden',
          }}
          onClick={() => handleLoadMore()}
          type="button"
          disabled={isFetching}
        >
          <div className={styles.LoadMore}>
            {loadMoreText || 'Load More ...'}
          </div>
        </button>
      );
    }
    return null;
  };

  return (
    <Card
      className={clsx(styles.SelectionPage, className)}
      style={style}
      title={title}
      extra={extra}
      actions={
        onBack && [
          <Button icon={<ArrowLeftOutlined />} onClick={onBack}>
            &nbsp;
            {backText || 'Back'}
          </Button>,
        ]
      }
    >
      {renderList()}
      {renderLoader()}
      {renderLoadMoreButton()}
    </Card>
  );
};
