export type IBlobWithDataURL = Blob & { dataURL: string };

export async function dataUrlToBlob(base64: string) {
  return (await fetch(base64)).blob();
}

async function getCanvasBlob(
  canvas: HTMLCanvasElement,
  type: string,
  quality: number
) {
  const dataUrl = canvas.toDataURL(type, quality);
  return Object.assign(await dataUrlToBlob(dataUrl), {
    dataURL: dataUrl,
  }) as IBlobWithDataURL;
}

export function getImageFromVideo(
  video: HTMLVideoElement,
  quality = 0.8,
  imageType = 'image/jpeg'
) {
  const canvas = document.createElement('canvas');

  const { videoWidth, videoHeight } = video;
  canvas.width = videoWidth;
  canvas.height = videoHeight;

  const ctx = canvas.getContext('2d');
  if (!ctx) return null;

  ctx.drawImage(video, 0, 0);
  return getCanvasBlob(canvas, imageType, quality);
}
