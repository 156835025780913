import { FC, useState, useEffect } from 'react';
import {
  getPeopleForFormSearch,
  getPerson,
} from '@datapeace/1up-frontend-shared-api';
import { Spin } from 'antd';
import { IErrorResponse, toast } from '@datapeace/1up-frontend-web-ui';
import { useConfig } from '@datapeace/vms-web-hooks';
import { Dropdown, DropdownValue } from './drop-down-dynamic-field';
import { debounce, useRefValue } from '@datapeace/1up-frontend-web-utils';

export interface IPeopleProps {
  name: string;
  value?: DropdownValue;
  onChange: (name: string, value: IPeopleProps['value']) => void;
  placeholder?: string;
  data?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    peopleGroups?: any;
  };
  disabled?: boolean;
}

const pageSize = 10;
const searchDebounceDelay = 400;

interface Option {
  key: string;
  label: string;
  iconSrc?: string;
  isSetManually?: boolean;
}

export const People: FC<IPeopleProps> = ({
  onChange,
  name,
  value,
  placeholder,
  disabled,
  data,
}) => {
  const [options, setOptions] = useState<Option[]>(
    value && (value as { key: string; label: string; value: string })?.key
      ? [
          {
            ...(value as { key: string; label: string; value: string }),
            isSetManually: true,
          },
        ]
      : []
  );
  const [isFetching, setIsFetching] = useState(false);

  const config = useConfig();

  const orgId =
    config && config.currentOrganization && config.currentOrganization.id;

  const personId =
    (value && (value as { key: string; label: string; value: string })?.key) ||
    null;
  const shouldCheckIfPersonExists =
    !!personId && options.length && options[0].isSetManually === true;

  const onChangeRef = useRefValue(onChange);
  useEffect(() => {
    // check if person exists
    if (!personId || !shouldCheckIfPersonExists) return;

    const fetchPersonData = async () => {
      setIsFetching(true);

      try {
        // if person by this id not found assume it's DELETED, so clear the value
        const person = await getPerson(+personId);
        if (!person) {
          onChangeRef.current(name, '');
        }

        const { id, firstName, lastName, pictureUrl } = person;
        setOptions([
          {
            key: String(id),
            label: `${firstName} ${lastName}`,
            iconSrc: pictureUrl,
          },
        ]);
      } catch {
        onChangeRef.current(name, '');
      }

      setIsFetching(false);
    };

    fetchPersonData();
  }, [personId, shouldCheckIfPersonExists, onChangeRef, name]);

  const fetchPeopleFirstPage = debounce(async (searchText?: string) => {
    if (!orgId || !data || !data.peopleGroups || !data.peopleGroups.length) {
      setOptions([]);
      return;
    }

    setIsFetching(true);

    try {
      const { items } = await getPeopleForFormSearch(orgId, {
        page: 1,
        pageSize,
        search: searchText,
        ...(data.peopleGroups === 'all'
          ? { personGroupNone: false }
          : { personGroupIsOr: data.peopleGroups }),
      });

      setOptions(
        items.map(({ id, firstName, lastName, pictureUrl }) => ({
          key: String(id),
          label: `${firstName} ${lastName}`,
          iconSrc: pictureUrl,
        }))
      );
    } catch (err) {
      toast.error(err as IErrorResponse);
    }

    setIsFetching(false);
  }, searchDebounceDelay);

  return (
    <Dropdown
      loading={isFetching}
      name={name}
      value={value}
      options={options}
      placeholder={placeholder || 'Search for person'}
      disabled={disabled}
      notFoundContent={isFetching ? <Spin size="small" /> : 'No people found!'}
      filterOption={false}
      onSearch={fetchPeopleFirstPage}
      onFocus={() => fetchPeopleFirstPage()}
      showSearch
      labelInValue
      onChange={onChange}
    />
  );
};
