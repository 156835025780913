import React, { FC } from 'react';
import { Typography } from '@datapeace/1up-frontend-web-ui';
import clsx from 'clsx';
import * as styles from './form-field.module.scss';

interface Props {
  className?: string;
  label?: React.ReactNode;
  required?: boolean;
  children: JSX.Element;
}

export const FormField: FC<Props> = ({
  className,
  label,
  required,
  children,
}) => (
  <div className={clsx(styles.Field, className)}>
    {label ? (
      <div className={styles.Label}>
        {label}
        {required && <Typography.Text type="danger">&nbsp;*</Typography.Text>}
      </div>
    ) : null}
    {children}
  </div>
);
