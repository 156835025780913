import React, { FC } from 'react';
import { UndoOutlined } from '@ant-design/icons';
import { Camera, Loader } from '@datapeace/1up-frontend-web-ui';
import { IBlobWithDataURL, useIdle } from '@datapeace/1up-frontend-web-utils';
import styles from './web-cam.module.scss';

export const defaultIdleTime = 15 * 60 * 1000; // 15 minutes

interface IProps {
  facingMode?: 'user' | 'environment';
  previewImage?: string;
  onCapture: (blob: IBlobWithDataURL | null) => void;
  idleTime?: number;
}

export const Webcam: FC<IProps> = ({
  facingMode,
  previewImage = '',
  onCapture,
  idleTime = defaultIdleTime,
}) => {
  const isIdle = useIdle(idleTime);

  return (
    <div className={styles.CameraWrapper}>
      <div className={styles.CardContent}>
        {previewImage && <img src={previewImage} alt="" />}

        {!previewImage &&
          (isIdle ? (
            <div className={styles.Idle}>
              <div>
                <UndoOutlined />
                <p>Tap here to open camera</p>
              </div>
            </div>
          ) : (
            <Camera
              videoConstraints={{ facingMode: facingMode || 'user' }}
              captureAreaBoxSize={0}
              info=""
              hideCaptureButton={!!previewImage}
              onCapture={onCapture}
            />
          ))}
      </div>
      <div className={styles.Capture}>
        {previewImage && <Loader inline text="" />}
      </div>
    </div>
  );
};
