import { useState, useEffect } from 'react';
import {
  useProcessDataContext,
  ProcessType,
  useRouter,
} from '@datapeace/vms-web-models';
import { ROUTES } from '@datapeace/vms-web-utils';
import { useConfig } from '@datapeace/vms-web-hooks';

export function useTermsContainer() {
  const { setCurrentRoute } = useRouter();
  const { tncContent } = useConfig();
  const { setProcessData, processType, isTncRequired, getBackNavigationRoute } =
    useProcessDataContext();

  const [shouldSendEmail, setShouldSendEmail] = useState(false);
  const [email, setEmail] = useState('');

  // remove any previous signature image on mount
  useEffect(() => {
    setProcessData({ signatureImage: '' });
  }, [setProcessData]);

  const handleSubmit = (signatureImage: string) => {
    setProcessData({ signatureImage });
    setProcessData({ tncEmail: shouldSendEmail ? email.trim() : '' });

    setCurrentRoute(ROUTES.TEMPERATURE);
  };

  useEffect(() => {
    if (processType === ProcessType.Checkout || !isTncRequired) {
      setCurrentRoute(ROUTES.TEMPERATURE);
    }
  });

  function handleGoBack() {
    setCurrentRoute(getBackNavigationRoute(ROUTES.CHECK_IN_FORM));
  }

  return {
    onSubmit: handleSubmit,
    shouldSendEmail,
    setShouldSendEmail,
    tncContent,
    setEmail,
    email,
    handleGoBack,
  };
}
