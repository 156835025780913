import { useEffect } from 'react';
import { CookieChecker, OnlineStatus } from '@datapeace/1up-frontend-web-ui';
import {
  ConfigProvider,
  RouterProvider,
  ProcessDataProvider,
} from '@datapeace/vms-web-models';
import { Authenticator } from '@datapeace/vms-web-components';
import { AppInitializerContainer } from '@datapeace/vms-web-containers';
import { Routes } from './routes';

function App() {
  useEffect(() => {
    const img = new Image();
    img.src = '/temperature.gif';
  }, []);

  return (
    <CookieChecker logoUrl="/images/vms_logo.png">
      <AppInitializerContainer>
        <OnlineStatus>
          <Authenticator>
            <ConfigProvider>
              <RouterProvider>
                <ProcessDataProvider>
                  <Routes />
                </ProcessDataProvider>
              </RouterProvider>
            </ConfigProvider>
          </Authenticator>
        </OnlineStatus>
      </AppInitializerContainer>
    </CookieChecker>
  );
}

export default App;
