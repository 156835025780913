import { useState } from 'react';
import {
  Button,
  Alert,
  message,
  IErrorResponse,
} from '@datapeace/1up-frontend-web-ui';
import { SwapOutlined } from '@ant-design/icons';
import { useProfileContext } from '@datapeace/vms-web-models';
import { Webcam } from './web-cam';
import { getSettingsFromLocalStorage } from '@datapeace/vms-web-utils';
import {
  IBlobWithDataURL,
  useIsWindowFocused,
} from '@datapeace/1up-frontend-web-utils';

export function WebcamUploader(props: {
  onCapture: (url: string, imageData: string) => void;
  uploadHandler: (file: IBlobWithDataURL) => Promise<{ fileUrl: string }>;
}) {
  const [previewImage, setPreviewImage] = useState<string>('');
  const { email } = useProfileContext();

  const { onCapture, ...remainingProps } = props;
  const { compressImage, cameraFacing: defaultSettings } =
    getSettingsFromLocalStorage(email);
  const quality = !compressImage ? 0.92 : 0.7;
  const [cameraFacing, setCameraFacing] = useState(defaultSettings);

  const isWindowFocused = useIsWindowFocused(true);

  const handleCapture = async (capturedImageData: IBlobWithDataURL | null) => {
    try {
      if (!capturedImageData) {
        throw new Error('Failed to capture! Please try again');
      }

      setPreviewImage(capturedImageData.dataURL);
      const { fileUrl } = await props.uploadHandler(capturedImageData);
      onCapture(fileUrl, capturedImageData.dataURL);
    } catch (e) {
      setPreviewImage('');
      message.error((e as IErrorResponse).message);
      onCapture('', '');
    }
  };
  return isWindowFocused ? (
    <>
      <Button
        shape="circle"
        style={{
          height: '2.5em',
          width: '2.5em',
          position: 'absolute',
          right: '20%',
          bottom: '1em',
          zIndex: 10,
        }}
        onClick={() => {
          setCameraFacing(cameraFacing === 'user' ? 'environment' : 'user');
        }}
      >
        <SwapOutlined />
      </Button>
      <Webcam
        facingMode={cameraFacing}
        // quality={quality}
        previewImage={previewImage}
        onCapture={handleCapture}
        {...remainingProps}
      />
    </>
  ) : (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
      }}
    >
      <Alert
        message="Tap on screen to see camera view!"
        description="This window is out of focus. Tap here to see the camera view."
        type="warning"
        showIcon
      />
    </div>
  );
}
