import { FC, useState, useEffect } from 'react';
import {
  ArrowLeftOutlined,
  SettingOutlined,
  QrcodeOutlined,
  UserOutlined,
  RedoOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Spin } from 'antd';
import {
  Button,
  Alert,
  Camera,
  FaceCamera,
} from '@datapeace/1up-frontend-web-ui';
import clsx from 'clsx';
import { useCaptureContainer } from '@datapeace/vms-web-containers';
import {
  Layout,
  Screensaver,
  Content,
  CustomStatusCard,
} from '@datapeace/vms-web-components';
import { useRouter } from '@datapeace/vms-web-models';
import { DETECTION_DOWNSCALING, ROUTES } from '@datapeace/vms-web-utils';
import styles from './capture-screen.module.scss';
import {
  useCountdown,
  useIsWindowFocused,
  useWindowSize,
  useQrReader,
} from '@datapeace/1up-frontend-web-utils';

export const Capture: FC = () => {
  const {
    capturedImage,
    isFirstScreen,
    isAutoCaptureEnabled,
    detectionDistance,
    detectionScoreThreshold,
    handleBackButtonClick,
    onFaceCapture,
    onQrFound,
    isLoading,
    cameraFacing,
    isFaceDetectionEnabled,
    isQrReaderEnabled,
    customErrorScreen,
    retry,
  } = useCaptureContainer();

  const [hasFace, setHasFace] = useState(false);

  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(
    null
  );

  const isWindowFocused = useIsWindowFocused(true);

  const { setCurrentRoute } = useRouter();
  const { width, height } = useWindowSize();
  const canvasWidth = Math.round(width / 2);
  const canvasHeight = Math.round(height / 2);

  const [qrReaderCanvasElement, setQrReaderCanvasElement] =
    useState<HTMLCanvasElement | null>(null);
  useQrReader(
    isQrReaderEnabled ? videoElement : null,
    qrReaderCanvasElement,
    true,
    onQrFound
  );

  const [transitionDelay, resetTransitionDelay] = useCountdown(1, 200);
  useEffect(() => {
    if (!isLoading) resetTransitionDelay();
  }, [isLoading, resetTransitionDelay]);

  if (customErrorScreen?.displayText && customErrorScreen?.title) {
    return (
      <CustomStatusCard
        status="error"
        message={customErrorScreen?.title}
        actions={[
          <Button icon={<RedoOutlined />} onClick={() => retry()}>
            Retry
          </Button>,
        ]}
        handleGoBack={() => setCurrentRoute(ROUTES.HOME)}
        content={customErrorScreen?.displayText}
      />
    );
  }

  return (
    <Layout>
      <Screensaver isScreensaverDisabled={hasFace} />
      {!isLoading && (
        <div
          className={clsx(
            styles.HintText,
            !!transitionDelay && styles.HintTextInitial
          )}
        >
          <div>
            {isQrReaderEnabled && (
              <>
                <span>
                  <QrcodeOutlined className={styles.HintTextIcon} />
                  Show QR code for checkin or checkout
                </span>

                {isFaceDetectionEnabled && (
                  <span className={styles.Divider}>OR</span>
                )}
              </>
            )}
            {isFaceDetectionEnabled && (
              <span>
                <UserOutlined className={styles.HintTextIcon} />
                Take photo
              </span>
            )}
          </div>
        </div>
      )}

      {isLoading && (
        <div className={styles.HintText}>
          <div>
            <Spin indicator={<LoadingOutlined />} size="large" />
          </div>
        </div>
      )}

      <Content className={styles.Content}>
        {capturedImage && <img src={capturedImage.dataURL} alt="" />}

        {!capturedImage &&
          (() => {
            return isWindowFocused ? (
              <>
                {isFaceDetectionEnabled ? (
                  <FaceCamera
                    videoConstraints={{ facingMode: cameraFacing || 'user' }}
                    autoCapture={isAutoCaptureEnabled}
                    shouldDrawDetection={isFaceDetectionEnabled}
                    validRangeMinFaceSize={detectionDistance}
                    detectionScoreThreshold={detectionScoreThreshold}
                    downscaleBy={DETECTION_DOWNSCALING}
                    loading={isLoading}
                    onCapture={onFaceCapture}
                    captureAreaBoxSize={0}
                    videoElementRef={(el) => setVideoElement(el)}
                    onDetection={(status) => setHasFace(status === 'YES')}
                  />
                ) : (
                  <Camera
                    videoConstraints={{ facingMode: cameraFacing || 'user' }}
                    loading={isLoading}
                    onCapture={onFaceCapture}
                    captureAreaBoxSize={0}
                    videoElementRef={(el) => setVideoElement(el)}
                    info=""
                    hideCaptureButton={isQrReaderEnabled}
                  />
                )}
                {isQrReaderEnabled && (
                  <>
                    <canvas
                      width={canvasWidth}
                      height={canvasHeight}
                      ref={setQrReaderCanvasElement}
                    />
                    <div className={styles.QrFrame} />
                  </>
                )}
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '1rem',
                }}
              >
                <Alert
                  message="Tap on screen to see camera view!"
                  description="This window is out of focus. Tap here to see the camera view."
                  type="warning"
                  showIcon
                />
              </div>
            );
          })()}
      </Content>

      {isFirstScreen ? (
        <>
          {/* isSettingsOpen && <Settings onClose={() => setIsSettingsOpen(false)} /> */}
          <Button
            className={styles.BackButton}
            icon={<SettingOutlined />}
            shape="circle"
            onClick={() => setCurrentRoute(ROUTES.SETTINGS)}
          />
        </>
      ) : (
        <Button
          onClick={handleBackButtonClick}
          className={styles.BackButton}
          size="large"
          icon={<ArrowLeftOutlined />}
          shape="circle"
        />
      )}
    </Layout>
  );
};
