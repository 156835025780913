import { IPaginationParams, IPaginationResponse, IPerson } from '../typings';
import {
  IPersonForFormSearch,
  IPersonGroupFiltersForForm,
} from '../typings/people';
import { convertToSnakeCase, createPath } from '../utils';
import { instance } from './client';
import { apiOrganizationPersonPath, apiPersonsPath } from './constants';

export const getPeopleForFormSearch = async (
  organizationId: number,
  params?: IPaginationParams & Partial<IPersonGroupFiltersForForm>
) =>
  await instance.client.get<IPaginationResponse<IPersonForFormSearch>>(
    createPath(apiOrganizationPersonPath, { organizationId }),
    {
      params: convertToSnakeCase(params),
    }
  );

export const getPerson = async (personId: number) => {
  const res = await instance.client.get<{ item: IPerson }>(
    createPath(apiPersonsPath, { personId })
  );
  return res.item;
};
