import { useEffect } from 'react';
import { IForm } from '@datapeace/1up-frontend-shared-api';
import { IErrorResponse, toast } from '@datapeace/1up-frontend-web-ui';
import {
  getVisibleFields,
  validateFields,
  ROUTES,
} from '@datapeace/vms-web-utils';
import {
  useRouter,
  useProcessDataContext,
  ProcessType,
  IDynamicForm,
} from '@datapeace/vms-web-models';
import { normalizeValueObject } from '@datapeace/vms-web-components';
import { useConfig } from '@datapeace/vms-web-hooks';

export function getFieldsDataFromConfig(
  isCheckinProcess: boolean,
  checkInForm: IForm,
  checkOutForm: IForm
) {
  // eslint-disable-next-line no-console
  const checkinFields = ((checkInForm || {}).fields || []).map((field) => ({
    ...field,
    processType: ProcessType.Checkin,
  }));
  const checkoutFields = ((checkOutForm || {}).fields || []).map((field) => ({
    ...field,
    processType: ProcessType.Checkout,
  }));
  return (
    (isCheckinProcess
      ? checkinFields
      : [...checkinFields, ...checkoutFields]) || []
  );
}

export function useCustomFormContainer() {
  const { setCurrentRoute } = useRouter();
  const { checkinFormSchema, checkoutFormSchema } = useConfig();
  const {
    customFormData,
    processType,
    activeVisit,
    lastVisit,
    setProcessData,
    shouldFillForm,
    isFormAutofillEnabled,
    visitInvitation,
    getBackNavigationRoute,
  } = useProcessDataContext();

  const fields = getFieldsDataFromConfig(
    processType === ProcessType.Checkin,
    checkinFormSchema,
    checkoutFormSchema
  );

  const lastVisitValues = {
    // values from last visit
    ...normalizeValueObject(
      lastVisit?.checkinCustomData,
      checkinFormSchema?.fields
    ),
    ...normalizeValueObject(
      lastVisit?.checkoutCustomData,
      checkoutFormSchema?.fields
    ),
  };

  const values = {
    // autofill values from last visit (only use if autofill enabled)
    ...(isFormAutofillEnabled && lastVisitValues),

    // autofill values from invitation prefill
    ...normalizeValueObject(
      visitInvitation?.prefilledCheckinCustomData,
      checkinFormSchema?.fields
    ),
    ...normalizeValueObject(
      visitInvitation?.prefilledCheckoutCustomData,
      checkoutFormSchema?.fields
    ),

    // autofill values from checkin
    ...normalizeValueObject(
      activeVisit && activeVisit.checkinCustomData,
      checkinFormSchema?.fields
    ),

    // current CustomForm state, highest priority
    ...customFormData,
  };

  const visibleFields = getVisibleFields(fields, values, processType);

  const handleSubmit = async () => {
    try {
      validateFields(visibleFields, values);
      setCurrentRoute(ROUTES.TERMS);
    } catch (error) {
      toast.error(error as IErrorResponse);
    }
  };

  useEffect(() => {
    if (!visibleFields || !visibleFields.length || !shouldFillForm) {
      setCurrentRoute(ROUTES.TERMS);
    }
  });

  function handleGoBack() {
    setCurrentRoute(getBackNavigationRoute(ROUTES.VACCINATION_FORM));
  }

  return {
    setFormData: (data: IDynamicForm) =>
      setProcessData({ customFormData: data }),
    onSubmit: handleSubmit,
    values,
    visibleFields,
    handleGoBack,
  };
}
