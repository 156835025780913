import Fingerprint2 from 'fingerprintjs2';

interface Coordinates {
  readonly accuracy: number;
  readonly altitude: number | null;
  readonly altitudeAccuracy: number | null;
  readonly heading: number | null;
  readonly latitude: number;
  readonly longitude: number;
  readonly speed: number | null;
}
async function generateDeviceFingerprint() {
  const components = await Fingerprint2.getPromise({});
  const values = components.map(({ value }) => value);
  const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
  return fingerprint;
}

function generateDeviceFingerprintWhenIdle() {
  return new Promise((resolve: (value: string) => void, reject) => {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(() => {
        generateDeviceFingerprint().then(resolve).catch(reject);
      });
    } else {
      setTimeout(() => {
        generateDeviceFingerprint().then(resolve).catch(reject);
      }, 1000);
    }
  });
}

const DEVICE_FINGERPRINT_STORAGE_KEY = 'device-fingerprint';

export async function getDeviceFingerpint() {
  const fingerPrintInStorage = localStorage.getItem(
    DEVICE_FINGERPRINT_STORAGE_KEY
  );

  if (fingerPrintInStorage) return fingerPrintInStorage;

  const deviceFingerprint = await generateDeviceFingerprintWhenIdle();
  localStorage.setItem(DEVICE_FINGERPRINT_STORAGE_KEY, deviceFingerprint);
  return deviceFingerprint;
}

export async function getDeviceGeolocation(
  options: PositionOptions = { enableHighAccuracy: true, timeout: 5000 }
): Promise<Coordinates> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        resolve(coords);
      },
      (error) => {
        reject(error);
      },
      options
    );
  });
}

export const getPermissionState = async (
  name: PermissionName
): Promise<PermissionState> => {
  try {
    return (await navigator.permissions.query({ name })).state;
  } catch (err) {
    // in case navigator.permissions is not supported, assume permission is granted
    return 'granted';
  }
};
