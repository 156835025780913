import { useState, useEffect } from 'react';
import {
  IOrganizationDetailed,
  getCurrentOrganizationFromStorage,
} from '@datapeace/1up-frontend-shared-api';
import { useProfileContext } from '@datapeace/vms-web-models';

export function useGetCurrentOrganization(
  organizations: IOrganizationDetailed[] | null
): [
  IOrganizationDetailed | null,
  (organization: IOrganizationDetailed | null) => void
] {
  const [currentOrganization, setCurrentOrganization] =
    useState<IOrganizationDetailed | null>(null);
  const { email } = useProfileContext();

  useEffect(() => {
    // if organizations are fetched set organization if saved in storage
    const setupCurrentOrganization = () => {
      if (!organizations || currentOrganization) {
        return;
      }

      if (!organizations.length) {
        return;
      }

      if (organizations.length === 1) {
        setCurrentOrganization(organizations[0]);
        return;
      }

      const savedOrgId = getCurrentOrganizationFromStorage(email);

      if (savedOrgId !== null) {
        const savedOrg = organizations.find(
          ({ id }) => String(id) === savedOrgId
        );
        if (savedOrg) {
          setCurrentOrganization(savedOrg);
        }
      }
    };

    setupCurrentOrganization();
  }, [organizations, currentOrganization, email]);

  return [currentOrganization, setCurrentOrganization];
}
