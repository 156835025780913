import {
  removeRestrictedModeFromStorage,
  getSearchParamFromUrl,
  redirectWithReturnPath,
  logout,
  instance,
} from '@datapeace/1up-frontend-shared-api';
import {
  getLogoutRedirectUrlFromStorage,
  removeLogoutRedirectUrlFromStorage,
  removeRestrictedVMSModeFromStorage,
  LOGOUT_PATH,
  LOGIN_PATH,
} from '@datapeace/vms-web-utils';

export const getTokensFromUrl = () => ({
  accessToken: getSearchParamFromUrl('token'),
  refreshToken: getSearchParamFromUrl('refresh-token'),
});

export const redirectToLogin = async () => {
  await instance.auth.removeAccessToken();
  await instance.auth.removeRefreshToken();
  removeRestrictedModeFromStorage();
  removeRestrictedVMSModeFromStorage();
  const logoutRedirectUrl = getLogoutRedirectUrlFromStorage();
  if (logoutRedirectUrl) {
    removeLogoutRedirectUrlFromStorage();
    window.location.replace(logoutRedirectUrl);
  } else {
    redirectWithReturnPath(LOGIN_PATH);
  }
};

export const redirectToLogout = async () => {
  await instance.auth.removeAccessToken();
  await instance.auth.removeRefreshToken();
  removeRestrictedModeFromStorage();
  removeRestrictedVMSModeFromStorage();
  const logoutRedirectUrl = getLogoutRedirectUrlFromStorage();
  if (logoutRedirectUrl) {
    removeLogoutRedirectUrlFromStorage();
    window.location.replace(logoutRedirectUrl);
  } else {
    redirectWithReturnPath(LOGOUT_PATH);
  }
};

export async function onLogout() {
  try {
    await logout();
  } catch (e) {
    console.error(e);
  }
  await instance.auth.removeAccessToken();
  await instance.auth.removeRefreshToken();
}
