/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from 'axios';
import { VerifyVaccinationCertificate } from '../typings';
import { createPath } from '../utils';
import { apiVaccinationVerification } from './constants';

export const verifyVaccinationCertificate = async (
  file: File,
  axiosInstance: AxiosInstance
) => {
  const form = new FormData();
  form.append('file', file);
  try {
    const res = await axiosInstance.post<null, VerifyVaccinationCertificate>(
      createPath(apiVaccinationVerification).slice(0, -1),
      form,
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        noAuth: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return res;
  } catch (e) {
    if (
      e &&
      (e as any).response &&
      (e as any).response.status &&
      (e as any).response.data &&
      (e as any).response.data.error
    ) {
      throw new Error((e as any).response.data.error);
    }
    throw e;
  }
};

export const verifyVaccinationCertificateQR = async (
  qrData: string,
  axiosInstance: AxiosInstance
) => {
  const form = new FormData();
  form.append('qr_data', qrData);
  try {
    const res = await axiosInstance.post<null, VerifyVaccinationCertificate>(
      createPath(apiVaccinationVerification).slice(0, -1),
      form,
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        noAuth: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return res;
  } catch (e) {
    if (
      e &&
      (e as any).response &&
      (e as any).response.status &&
      (e as any).response.data &&
      (e as any).response.data.error
    ) {
      throw new Error((e as any).response.data.error);
    }
    throw e;
  }
};
