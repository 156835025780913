import { useState, useEffect } from 'react';
import {
  IOrganizationDetailed,
  ISpace,
  getCurrentSpaceFromStorage,
} from '@datapeace/1up-frontend-shared-api';
import { useProfileContext } from '@datapeace/vms-web-models';

export function useGetCurrentSpace(
  currentOrganization: IOrganizationDetailed | null
): [ISpace | null, (space: ISpace | null) => void] {
  const [currentSpace, setCurrentSpace] = useState<ISpace | null>(null);
  const { email } = useProfileContext();

  useEffect(() => {
    // if organization is selected/updated set space if saved in storage
    const setupCurrentSpace = () => {
      if (!currentOrganization || currentSpace) {
        return;
      }

      const spaces = currentOrganization.spaces || [];

      if (!spaces.length) {
        return;
      }

      if (spaces.length === 1) {
        setCurrentSpace(spaces[0]);
        return;
      }

      const savedSpaceId = getCurrentSpaceFromStorage(email);
      if (savedSpaceId !== null) {
        const savedSpace = currentOrganization.spaces.find(
          ({ id }) => String(id) === savedSpaceId
        );
        if (savedSpace) {
          setCurrentSpace(savedSpace);
        }
      }
    };

    setupCurrentSpace();
  }, [currentOrganization, currentSpace, email]);

  return [currentSpace, setCurrentSpace];
}
