/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SigninResponse } from '../typings';
import { convertToSnakeCase, createPath } from '../utils';
import { instance } from './client';
import JwtDecode from 'jwt-decode';
import { apiLogoutPath, apiRefreshAccessTokenPath } from './constants';

export const refreshAccessToken = async () => {
  const storedAccessToken = await instance.auth.getAccessToken();
  const storedRefreshToken = await instance.auth.getRefreshToken();

  if (!storedAccessToken || !storedRefreshToken) {
    throw new Error('You are not authenticated! Please login.');
  }

  const res = await instance.client.post<SigninResponse>(
    createPath(apiRefreshAccessTokenPath),
    convertToSnakeCase({
      accessToken: storedAccessToken,
      refreshToken: storedRefreshToken,
    })
  );

  await instance.auth.setAccessToken(res.accessToken);
  await instance.auth.setRefreshToken(res.refreshToken);

  return res;
};

export const refreshAccessTokenIfNeeded = async (
  force = false,
  graceMinutes = 1
) => {
  console.info('Checking if access token refresh needed!');

  const storedAccessToken = await instance.auth.getAccessToken();

  if (!storedAccessToken) {
    console.error('No stored access token found, not refreshing');
    throw new Error('You are not authenticated! Please login.');
  }

  const { exp } = JwtDecode<{ exp: number }>(storedAccessToken);
  let expiresInMS = +new Date(exp * 1000) - Date.now();

  const graceMs = graceMinutes * 60 * 1000;

  // if token expires within a minute, refresh it
  if (expiresInMS <= graceMs || force) {
    try {
      console.log('Refreshing access token!');
      const { accessTokenExpiresIn } = await refreshAccessToken();
      expiresInMS = accessTokenExpiresIn * 1000;
    } catch (err: any) {
      if (err && err.response && err.response.status) {
        await instance.config.onLogout();
      }
      throw err;
    }
  }

  return { expiresInMS, graceMs };
};

export async function logout() {
  const storedAccessToken = await instance.auth.getAccessToken();
  const storedRefreshToken = await instance.auth.getRefreshToken();
  if (!storedAccessToken && !storedRefreshToken) {
    return;
  }
  await instance.client.post(
    createPath(apiLogoutPath),
    {
      access_token: storedAccessToken,
      refresh_token: storedRefreshToken || undefined,
    },
    {
      // @ts-ignore
      noAuth: true,
    }
  );
}
