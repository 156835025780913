import {
  createContext,
  useContext,
  FC,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { ROUTES } from '@datapeace/vms-web-utils';

export type RoutesValues = ValueOf<typeof ROUTES>;

export const RouterContext = createContext<{
  currentRoute: RoutesValues;
  setCurrentRoute: (newRoute: RoutesValues) => null;
} | null>(null);

export const useRouter = () => {
  const routerData = useContext(RouterContext);

  if (!routerData) {
    throw new Error("RouterContext should be consumed inside it's Provider");
  }

  return routerData;
};

export const RouterProvider: FC<{ children: JSX.Element }> = ({ children }) => {
  const [currentRoute, setRoute] = useState<RoutesValues>(() => {
    if (window.location.pathname === ROUTES.SETTINGS) {
      return ROUTES.SETTINGS;
    }
    return ROUTES.HOME;
  });

  useEffect(() => {
    if (currentRoute === ROUTES.SETTINGS) {
      if (window.location.pathname !== ROUTES.SETTINGS) {
        window.history.pushState({}, '', ROUTES.SETTINGS);
      }
    } else if (window.location.pathname !== ROUTES.HOME) {
      window.history.pushState({}, '', ROUTES.HOME);
    }
  }, [currentRoute]);

  const setCurrentRoute = useCallback((newRoute: RoutesValues) => {
    setRoute(newRoute);
    return null;
  }, []);

  return (
    <RouterContext.Provider value={{ currentRoute, setCurrentRoute }}>
      {children}
    </RouterContext.Provider>
  );
};
