export const MAX_TIMEOUT_MS = 2147483647;
export const API_BASE_URL: string =
  import.meta.env.VITE_APP_API_BASE_URL ||
  import.meta.env.VITE_API_BASE_URL ||
  '';

export const apiAlertsPath = ['alerts'] as const;
export const apiPeoplePath = ['peoples'] as const;

export const apiPlansPath = ['plans'] as const;
export const apiDevicesPath = ['devices'] as const;
export const apiAlertNotificationsPath = ['alert-notification'] as const;
export const apiMembersPath = ['members'] as const;
export const apiReportsPath = ['reports'] as const;
export const apiPersonGroupsPath = ['person-groups'] as const;
export const apiInvitationsPath = ['visit-invitations'] as const;
export const apiPrintersPath = ['printers'] as const;
export const apiTemperatureDevicesPath = ['temperature-devices'] as const;
export const apiSpacesPath = ['spaces'] as const;
export const apiVisitsPath = ['visits'] as const;
export const apiSendOTPPath = ['send-otp'] as const;
export const apiResendOTPPath = ['resend-otp'] as const;
export const apiOrganizationsPath = ['organizations'] as const;
export const apiSpacePath = [apiSpacesPath, '{{spaceId}}'] as const;

// Auth
export const apiAuthPath = ['auth'] as const;
export const apiRefreshAccessTokenPath = [
  apiAuthPath,
  'refresh-token',
] as const;
export const apiVerifyEmailPath = ['verify-email'] as const;
export const apiLoginPath = [apiAuthPath, 'login'] as const;
export const apiLogoutPath = [apiAuthPath, 'logout'] as const;
export const apiSendLoginOTPPath = [apiLoginPath, apiSendOTPPath] as const;
export const apiResendLoginOTPPath = [apiLoginPath, apiResendOTPPath] as const;
export const apiSignupPath = [apiAuthPath, 'signup'] as const;
export const apiForgotPassword = [apiAuthPath, 'reset-password'] as const;
export const apiEmailVerify = [
  apiAuthPath,
  apiVerifyEmailPath,
  '{{token}}',
] as const;
export const apiResendVerificationMail = [
  apiAuthPath,
  apiVerifyEmailPath,
  'resend',
] as const;
export const apiDeleteUserPath = ['delete-user'] as const;
export const apiSubscriptionsPath = ['subscriptions'] as const;
export const apiSsoLoginPath = [apiLoginPath, '{{orgSlug}}'] as const;
export const apiCustomLoginPageList = [
  apiAuthPath,
  'custom-login-pages',
  '{{orgSlug}}',
];

// Profile
export const apiProfilePath = ['me', 'profile'] as const;
export const apiUpdatedProfilePath = ['me'] as const;
export const apiScreenSaverPath = [apiSpacesPath, '{{spaceId}}'] as const;
export const apiChangePassword = ['me', 'change-password'] as const;
export const apiProfilePictureUploadPath = [
  apiUpdatedProfilePath,
  'avatar',
] as const;
export const apiScreenSaverImagesUploadPath = [
  apiScreenSaverPath,
  'screensaver-image',
  'upload',
] as const;
export const apiProfilePictureUploadAbsolutePath = [
  API_BASE_URL,
  apiProfilePictureUploadPath,
] as const;
export const apiScreenSaverImagesUploadAbsolutePath = [
  API_BASE_URL,
  apiScreenSaverImagesUploadPath,
] as const;
export const apiProfileMobileNumberPath = [
  apiUpdatedProfilePath,
  'mobile',
] as const;
export const apiProfileMobileNumberSendOTPPath = [
  apiProfileMobileNumberPath,
  'send-otp',
] as const;

// Alerts & Notifications
export const apiAlertPath = [apiAlertsPath, '{{alertId}}'] as const;
export const apiAlertNotificationPath = [
  apiAlertNotificationsPath,
  '{{notificationId}}',
] as const;
export const apiVerifyOTPPath = ['verify-otp'] as const;
export const apiNotificationsPath = ['me', 'notifications'] as const;
export const apiOneSignalSubscriptionsNotificationsPath = [
  apiNotificationsPath,
  'onesignal-subscriptions',
] as const;

// Misc
export const apiCountriesDataPath = ['countries'] as const;

// Vaccination
export const apiVaccinationVerification = [
  'cowin-verify-api',
  'verify-certificate',
];

// AMS Device Registration
export const apiNotificationsBulkPath = [
  apiAlertNotificationsPath,
  'bulk',
] as const;
export const apiNotificationsReadAllPath = [
  apiAlertNotificationsPath,
  'mark-all-read',
] as const;

// Organizations
export const apiOrganizationPath = [
  apiOrganizationsPath,
  '{{organizationId}}',
] as const;
export const apiMyOrganizationsPath = ['me', apiOrganizationsPath] as const;
export const apiOrganizationConfigPath = [
  apiOrganizationPath,
  'config',
] as const;
export const apiOrganizationLogoUploadPath = [
  apiOrganizationPath,
  'asset-upload',
] as const;
export const apiOrganizationsInviteAcceptPath = [
  apiOrganizationsPath,
  'accept-invite',
  '{{token}}',
] as const;
export const apiOrganizationSpacesPath = [
  apiOrganizationPath,
  apiSpacesPath,
] as const;
export const apiOrganizationMembersPath = [
  apiOrganizationPath,
  apiMembersPath,
] as const;
export const apiOrganizationMemberPath = [
  apiOrganizationMembersPath,
  '{{memberId}}',
] as const;
export const apiOrganizationBulkMembersPath = [
  apiOrganizationPath,
  'bulk-members',
] as const;
export const apiOrganizationBulkActivateMembersPath = [
  apiOrganizationBulkMembersPath,
  'activate',
] as const;
export const apiOrganizationBulkDeactivateMembersPath = [
  apiOrganizationBulkMembersPath,
  'deactivate',
] as const;
export const apiOrganizationSubscriptionPath = [
  apiOrganizationPath,
  apiSubscriptionsPath,
] as const;
export const apiOrganizationPeoplePath = [
  apiOrganizationPath,
  apiPeoplePath,
] as const;
export const apiOrganizationPersonPath = [
  apiOrganizationPath,
  'persons',
] as const;
export const apiOrganizationPeopleGroupsPath = [
  apiOrganizationPath,
  apiPersonGroupsPath,
] as const;
export const apiOrganizationSendOTPPath = [
  apiOrganizationPath,
  apiSendOTPPath,
] as const;
export const apiOrganizationVerifyOTPPath = [
  apiOrganizationPath,
  apiVerifyOTPPath,
] as const;
export const apiOrganizationPrintersPath = [
  apiOrganizationPath,
  apiPrintersPath,
] as const;
export const apiOrganizationPrinterPath = [
  apiOrganizationPath,
  apiPrintersPath,
] as const;
export const apiOrganizationScreeningTestPath = [
  apiOrganizationPath,
  'screening-tests',
] as const;
export const apiScreeningDetailPath = [
  'screening-tests',
  '{{screeingTestId}}',
] as const;
export const apiOrganizationBillingDetailsPath = [
  apiOrganizationPath,
  'billing-info',
] as const;

// Spaces
export const apiSpaceConfigPath = [apiSpacePath, 'config'] as const;
export const apiSpaceVmsConfigPath = [apiSpacePath, 'vms-config'] as const;
export const apiFormSchemaRevisions = [
  apiSpaceConfigPath,
  'attr',
  '{{attributeName}}',
  'revisions',
] as const;
export const apiFormSchemaRevision = [
  apiFormSchemaRevisions,
  '{{revisionId}}',
] as const;
export const apiSpaceMembersPath = [apiSpacePath, apiMembersPath] as const;
export const apiSpaceMemberPath = [
  apiSpacePath,
  apiMembersPath,
  '{{memberId}}',
] as const;

// People Groups
export const apiPeopleGroupPath = [apiPersonGroupsPath, '{{groupId}}'] as const;
export const apiPeopleGroupsBulkPath = [
  apiOrganizationPath,
  'bulk-persons',
  'groups',
] as const;

// People & Faces
export const apiPersonPath = [apiPeoplePath, '{{peopleId}}'] as const;
export const apiPersonsPath = ['persons', '{{personId}}'] as const;
export const apiFacesPath = ['faces'] as const;
export const apiPersonFacesPath = [apiPersonsPath, apiFacesPath] as const;
export const apiBulkPersonFacesCreatePath = [
  apiPersonsPath,
  'bulk-faces',
] as const;
export const apiPersonFaceIndexBulkPath = [
  apiPersonsPath,
  'bulk-faces',
  '{{indexType}}',
] as const;
export const apiPeopleFacesUnindexAllPath = [
  apiOrganizationPath,
  'bulk-persons',
  'unindex-faces',
] as const;
export const apiPersonFacePath = [
  apiPersonPath,
  apiFacesPath,
  '{{faceId}}',
] as const;
export const apiOrganizationPeopleSearchPath = [
  apiOrganizationPeoplePath,
  'search',
] as const;
export const apiOrganizationPersonFacesPath = [
  API_BASE_URL,
  apiOrganizationPath,
  'persons',
  'face-upload',
] as const;
export const apiOrganizationPeopleBulkUploadPath = [
  apiOrganizationPeoplePath,
  'bulk-upload',
  'create',
] as const;
export const apiOrganizationPeopleBulkValidateAbsolutePath = [
  API_BASE_URL,
  apiOrganizationPeoplePath,
  'bulk-upload',
  'validate',
] as const;
export const apiOrganizationPeopleBulkEmailPath = [
  apiOrganizationPath,
  'bulk-persons',
  'details-form',
] as const;
export const apiOrganizationPeopleBulkRemovePath = [
  apiOrganizationPath,
  'bulk-persons',
] as const;
export const apiOrganizationPeopleBulkBlackListPath = [
  apiOrganizationPath,
  'bulk-persons',
  'blacklist',
] as const;
export const apiOrganizationPeopleBulkSyncValidatPath = [
  apiOrganizationPath,
  'bulk-persons',
  'sync',
  'validate',
] as const;
export const apiOrganizationPeopleBulkUploadSyncPath = [
  apiOrganizationPath,
  'bulk-persons',
  'sync',
] as const;
export const apiOrganizationPeopleBulkScreeningFormPath = [
  apiOrganizationPath,
  'bulk-persons',
  'screening-form',
] as const;
export const apiOrganizationPeopleSmartSearchPath = [
  apiOrganizationPath,
  'persons',
  'smart-search',
] as const;
export const apiOrganizationPeopleFileUploadPath = [
  apiOrganizationPath,
  'persons',
  'file-upload',
] as const;

// person details form
export const apiPersonDetailsByTokenPath = [
  'public',
  'person-details',
  '{{token}}',
] as const;
export const apiPersonFaceUploadByTokenPath = [
  apiPersonDetailsByTokenPath,
  'face-upload',
] as const;
export const apiPersonFileUploadByTokenPath = [
  apiPersonDetailsByTokenPath,
  'file-upload',
] as const;

// Screening form path
export const apiScreeningQuestionsByTokenPath = [
  'public',
  'screening-test-details',
  '{{token}}',
] as const;
// Screening form settings path
export const apiOrganizationScreeningPath = [
  apiOrganizationPath,
  'screening-config',
] as const;

// Visits
export const apiSpaceVisitsPath = [apiSpacePath, apiVisitsPath] as const;
export const apiSpaceVisitMetricsPath = [apiSpaceVisitsPath, 'stats'] as const;
export const apiVisitPath = [apiVisitsPath, '{{visitId}}'] as const;
export const apiSpaceVisitByFacePath = [
  apiSpaceVisitsPath,
  'search-by-face',
] as const;
export const apiSpaceVisitCheckInPath = [
  apiSpaceVisitsPath,
  'check-in',
] as const;
export const apiSpaceVisitCheckOutPath = [
  apiSpaceVisitsPath,
  'check-out',
] as const;
export const apiVisitApprovePath = [apiVisitPath, 'checkout/approve'];
export const apiVisitCheckinApprovePath = [apiVisitPath, 'checkin/approve'];
export const apiSpaceVisitsSearchByMobilePath = [
  apiSpaceVisitsPath,
  'search-by-phone-number',
] as const;
export const apiSpaceVisitorsPath = [apiSpacePath, 'visitors'] as const;
export const apiSpaceVisitorPath = [
  apiSpaceVisitorsPath,
  '{{visitorId}}',
] as const;
export const apiSpaceVisitorsSearchByMobilePath = [
  apiSpaceVisitorsPath,
  'search-by-mobile',
] as const;
export const apiSpaceVisitorSearchByMobilePathSendOtp = [
  apiSpaceVisitorsSearchByMobilePath,
  'send-otp',
] as const;
export const apiSpaceVisitorSearchByMobilePathVerifyOtp = [
  apiSpaceVisitorsSearchByMobilePath,
  'verify-otp',
] as const;
export const apiSpaceVisitSmartSearchPath = [
  apiSpacePath,
  'visits',
  'smart-search',
] as const;
export const apiSpaceVisitsSearchByMobileWithoutVerifyPath = [
  apiSpaceVisitsPath,
  'search-by-mobile-number',
] as const;

export const apiSpaceVisitorsSearchByMobileWithoutVerifyInMobilePlanPath = [
  apiSpacePath,
  'visitors',
  'search-by-mobile',
] as const;

export const apiVisitCheckoutApprovePath = [apiVisitPath, 'checkout/approve'];
export const apiVisitVerifyByIdPath = [apiVisitPath, 'verify'] as const;
export const apiVisitRequestPath = [
  'visit-requests',
  '{{visitRequestId}}',
] as const;
export const apiCheckinVisitorByVisitRequestPath = [
  apiVisitRequestPath,
  'checkin',
] as const;
export const apiCheckoutVisitorByVisitRequestPath = [
  apiVisitRequestPath,
  'checkout',
] as const;
export const apiCreateVisitRequestInVmsPath = [
  apiSpacePath,
  'visit-requests',
] as const;
export const apiVisitVisitorGatePassDownload = [
  apiVisitPath,
  'visitor-gatepass',
  'export',
];
// Verify Visit Request
export const apiVisitRequestCheckinVerificationPath = [
  apiVisitRequestPath,
  'checkin-verification',
] as const;
export const apiVisitRequestCheckinVerificationSendOTPPath = [
  apiVisitRequestCheckinVerificationPath,
  'send-otp',
] as const;
export const apiVisitRequestCheckoutVerificationPath = [
  apiVisitRequestPath,
  'checkout-verification',
] as const;
export const apiVisitRequestCheckoutVerificationSendOTPPath = [
  apiVisitRequestCheckoutVerificationPath,
  'send-otp',
] as const;
// Verify Visitor
export const apiSpaceVisitSendOTPPath = [
  apiSpaceVisitsPath,
  apiSendOTPPath,
] as const;
export const apiSpaceVisitResendOTPPath = [
  apiSpaceVisitsPath,
  apiResendOTPPath,
] as const;
export const apiSpaceVisitVerifyVisitorOTPPath = [
  apiSpaceVisitsPath,
  apiVerifyOTPPath,
] as const;

// Detections
export const apiDetectionsPath = [apiSpacePath, 'detections'] as const;
export const apiDetectionsSearchPath = [apiDetectionsPath, 'search'] as const;
export const apiDetectionsMetricsPath = [apiDetectionsPath, 'metrics'] as const;

// Devices
export const apiSpaceDevicesPath = [apiSpacePath, apiDevicesPath] as const;
export const apiDevicePath = [apiDevicesPath, '{{deviceId}}'] as const;
export const apiSpaceDevicePath = [apiSpacePath, apiDevicePath] as const;
export const apiGetStreamPath = [apiDevicePath, 'hls-stream'] as const;
export const apiSpaceAMSClientDevicesBulkUpdateStatusPath = [
  apiSpacePath,
  'bulk-ams-client-devices',
  'status',
] as const;
// Reports
export const apiOrganizationReports = [
  apiOrganizationPath,
  apiReportsPath,
] as const;
export const apiOrganizationReportsHistory = [
  apiOrganizationPath,
  'reports-history',
] as const;
export const apiOrganizationReportHistoryDetail = [
  apiOrganizationPath,
  'reports-history',
  '{{reportHistoryId}}',
] as const;
export const apiOrganizationReport = [
  apiOrganizationPath,
  apiReportsPath,
  '{{reportId}}',
] as const;
export const apiOrganizationReportDownload = [
  apiOrganizationPath,
  apiReportsPath,
  '{{reportId}}',
  'download',
] as const;

// Invitations
export const apiInvitationPath = [
  apiInvitationsPath,
  '{{invitationId}}',
] as const;
export const apiSpaceInvitationsPath = [
  apiSpacePath,
  apiInvitationsPath,
] as const;
export const apiSpaceInvitationsHostsPath = [
  apiSpaceInvitationsPath,
  'hosts',
] as const;
export const apiSpaceInviationSettingsPath = [
  apiSpaceInvitationsPath,
  'config',
] as const;
export const apiInvitationVerifyPath = [
  apiSpaceInvitationsPath,
  'verify',
] as const;
export const apiVisitInvitationBadgePath = [
  apiInvitationsPath,
  'visitor-info',
  '{{invitationCode}}',
] as const;
export const apiVisitInvitationApproverPath = [
  apiInvitationPath,
  'approve',
] as const;

// Printers
export const apiPrinterPath = [apiPrintersPath, '{{printerId}}'] as const;
export const apiPrinterModelsPath = ['printer-models'] as const;
export const apiPrinterPrintPath = [
  'prs',
  'api',
  apiOrganizationPath,
  apiPrinterPath,
  'print-label',
] as const;
export const getVisitorBadgeImagePath = [
  apiVisitPath,
  'visitor-badge',
  'export',
] as const;

// Temperature Device
export const apiOrganizationTemperatureDevicesPath = [
  apiOrganizationPath,
  apiTemperatureDevicesPath,
] as const;
export const apiTemperatureDevicePath = [
  apiTemperatureDevicesPath,
  '{{deviceId}}',
] as const;
export const apiTemperatureDeviceNewRecordPath = [
  apiTemperatureDevicePath,
  'new-record',
] as const;
export const apiTemperatureDeviceRecordPath = [
  apiTemperatureDevicePath,
  'records',
] as const;
export const apiTemperatureRecordInVMSPath = [
  apiVisitRequestPath,
  'body-temperature-record',
] as const;

// Evacuation
export const apiEvacuationsPath = 'emergency-broadcasts' as const;
export const apiSpaceEvacuationsPath = [
  apiSpacePath,
  apiEvacuationsPath,
] as const;
export const apiSpaceEvacuationsConfigPath = [
  apiSpaceEvacuationsPath,
  'config',
] as const;
export const apiEvacuationPath = [
  apiEvacuationsPath,
  '{{evacuationId}}',
] as const;
export const apiEvacuationMarkSafe = [
  'emergency-broadcast-visits',
  '{{evacuationVisitId}}',
  'mark-safe',
] as const;

// Visitor Client
export const apiVisitorAppPath = ['visitor-app'] as const;
export const apiVisitorAppAccessPath = [
  apiVisitorAppPath,
  'access-token',
] as const;
export const apiVisitorAppConfigPath = [apiVisitorAppPath, 'config'] as const;
export const apiVisitorAppSendOtpPath = [
  apiVisitorAppPath,
  'send-otp',
] as const;
export const apiVisitorAppResendOtpPath = [
  apiVisitorAppPath,
  'resend-otp',
] as const;
export const apiVisitorAppVerifyOtpPath = [
  apiVisitorAppPath,
  'verify-otp',
] as const;
export const apiVisitorAppPersonDetailsPath = [
  apiVisitorAppPath,
  'person-details',
] as const;
export const apiVisitorAppVisitRequestPath = [
  apiVisitorAppPath,
  'visit-requests',
] as const;
export const apiVisitorAppSearchByFacePath = [
  apiVisitorAppPath,
  'people-search-by-face',
] as const;
export const apiVisitorAppRegisterFacePath = [
  apiVisitorAppPath,
  'people-faces',
] as const;
export const apiVisitorAppGetVisitRequestPath = [
  apiVisitorAppVisitRequestPath,
  '{{visitRequestId}}',
] as const;

export const apiVisitorAppPreCheckinPath = [
  apiVisitorAppGetVisitRequestPath,
  'pre-checkin',
] as const;
export const apiVisitorAppPreCheckoutPath = [
  apiVisitorAppGetVisitRequestPath,
  'pre-checkout',
] as const;
export const apiVisitorAppFaceUploadPath = [
  apiVisitorAppGetVisitRequestPath,
  'face-upload',
] as const;
export const apiVisitorAppImageUploadPath = [
  apiVisitorAppGetVisitRequestPath,
  'file-upload',
] as const;

export const apiVisitorAppPeopleSearchPath = [
  apiVisitorAppGetVisitRequestPath,
  'persons',
] as const;
export const apiVisitorAppPeopleExistsPath = [
  apiVisitorAppGetVisitRequestPath,
  'persons',
  '{{peopleId}}',
] as const;
export const apiVisitorAppTemperatureRecordPath = [
  apiVisitorAppGetVisitRequestPath,
  'temperature-record',
] as const;
export const apiVmsAppGetVisitRequestPath = [
  'visit-requests',
  '{{visitRequestId}}',
] as const;
export const apiVmsFileUploadPath = [
  apiVmsAppGetVisitRequestPath,
  'file-upload',
] as const;
export const apiVisitorBadgePath = [
  'visitor-badge-details',
  '{{visitId}}',
  '{{token}}',
] as const;

// Dashboard
export const apiDashboardStatsPath = [apiOrganizationPath, 'stats'] as const;

// AMS Device Registration
export const apiAmsClientDevice = [
  'ams-client-devices',
  '{{deviceId}}',
] as const;
export const amsDevicePingPath = [apiAmsClientDevice, 'ping'] as const;
export const apiAmsClientDeviceLocations = [
  apiAmsClientDevice,
  'locations',
] as const;
export const apiAmsClientDevicePunches = [
  apiAmsClientDevice,
  'punches',
] as const;

// AMS
export const apiShiftsPath = ['shifts'] as const;
export const apiAmsPunchAddPath = [apiSpacePath, 'punches'] as const;
export const apiAmsPunchDetailPath = ['punches', '{{punchId}}'] as const;
export const apiAmsRegularizeAttendanceData = [
  apiSpacePath,
  'attendances-bulk',
  'regularize',
] as const;
export const apiAmsAttendancesPath = [apiSpacePath, 'attendances'] as const;
export const apiAmsAttendanceDetailPath = [
  'attendances',
  '{{attendanceId}}',
] as const;
export const apiAmsAttendancePunchPath = [
  apiAmsAttendanceDetailPath,
  apiAmsPunchDetailPath,
];
export const apiAmsAttendancePunchesPath = [
  apiAmsAttendanceDetailPath,
  'punches',
];
export const apiAmsSpaceConfigPath = [apiSpacePath, 'ams-config'] as const;
export const apiAmsSearchByFacePath = [
  apiSpacePath,
  'employee-search-by-face',
] as const;
export const apiOrganizationShiftsPath = [
  apiOrganizationPath,
  apiShiftsPath,
] as const;
export const apiShiftPath = [apiShiftsPath, '{{shiftId}}'] as const;
export const apiSpaceAttendanceMetricsPath = [
  apiAmsAttendancesPath,
  'stats',
] as const;

// AMS Weekly Off
export const apiWeeklyOffsPath = ['weekly-offs'] as const;
export const apiWeeklyOffPath = [apiWeeklyOffsPath, '{{weeklyOffId}}'] as const;
export const apiOrganizationWeeklyOffPath = [
  apiOrganizationPath,
  apiWeeklyOffsPath,
] as const;
export const apiWeeklyOffDetailPath = [apiWeeklyOffPath] as const;

// AMS Device Registration
export const amsDevicesPath = ['ams-client-devices'] as const;
export const amsDevicesRegisterRequestsPath = [
  amsDevicesPath,
  'register-requests',
] as const;
export const amsDevicesRegisterRequestDetailPath = [
  amsDevicesRegisterRequestsPath,
  '{{registerRequestId}}',
] as const;
export const amsDevicesRegisterRequestsVerifyPath = [
  amsDevicesRegisterRequestDetailPath,
  'verify',
] as const;
export const apiAmsSpaceClientDevices = [
  apiSpacePath,
  'ams-client-devices',
] as const;
export const amsDeviceConfigPath = [apiAmsClientDevice, 'config'] as const;
export const apiAmsClientDeviceStatePath = [
  apiAmsClientDevice,
  'device-state',
] as const;

export const amsDeviceBulkLocationsStorePath = [
  apiAmsClientDevice,
  'bulk-locations',
] as const;
