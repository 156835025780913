import React, { FC } from 'react';
import { Input } from 'antd';
import styles from './index-dynamic-field.module.scss';

export interface IMultiLineTextProps {
  name: string;
  onChange: (name: string, value: string) => void;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
}

export const MultiLineText: FC<IMultiLineTextProps> = ({
  name,
  placeholder,
  onChange,
  value,
  disabled,
}) => {
  const handleValueChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(name, e.target.value);
  };

  return (
    <Input.TextArea
      className={styles.Text}
      autoSize={{ maxRows: 5 }}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={handleValueChange}
      disabled={disabled}
    />
  );
};
