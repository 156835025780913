/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Upload, Button, message } from 'antd';
import { UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { uniqueId } from 'lodash-es';
import { IBlobWithDataURL } from '@datapeace/1up-frontend-web-utils';
import { IErrorResponse } from '@datapeace/1up-frontend-web-ui';

export interface IFileProps {
  value?: { label: string | React.ReactNode; key: string } | null;
  onChange: (name: string, value: IFileProps['value']) => void;
  data: {
    allowedFileTypes: {
      [category: string]: { label: string; value: string }[];
    };
  };
  onUploadFile: (file: Blob) => Promise<{
    fileUrl: string;
  }> | null;
  name: string;
  disabled?: boolean;
}

interface CurrentFileProps {
  uid?: string;
  name?: string | React.ReactNode;
  url?: string;
  status?: 'error' | 'done' | 'uploading';
  percent?: number;
  error?: string | null;
  response?: string;
}

export function File({
  value,
  onChange,
  name,
  data,
  onUploadFile,
  disabled = false,
}: IFileProps) {
  // State with initial value
  const [currentFile, setCurrentFile] = useState<CurrentFileProps | null>(
    () => {
      if (value && value.key) {
        return {
          uid: uniqueId(),
          name: value.label,
          url: value.key,
          status: 'done',
          percent: 100,
          error: null,
        };
      }
      return null;
    }
  );

  useEffect(() => {
    // When currentFile is updated
    if (currentFile && currentFile.url && currentFile.url !== value?.key) {
      onChange(name, {
        key: currentFile.url,
        label: currentFile.name,
      });
    }
    // When currentFile is deleted
    else if (!currentFile && value) {
      onChange(name, null);
    }
  }, [value, currentFile, onChange, name]);

  const onUpload = useCallback(
    async (file: { file: IBlobWithDataURL }) => {
      if (!onUploadFile) {
        message.error('Upload not supported for this form');
        setCurrentFile({
          ...file.file,
          name: file.file.name,
          status: 'error',
          response: 'Upload not supported for this form',
        });
      } else {
        try {
          const { fileUrl } = (await onUploadFile(file.file)) as {
            fileUrl: string;
          };

          setCurrentFile({
            ...file.file,
            name: file.file.name,
            url: fileUrl,
            status: 'done',
            percent: 100,
          });
        } catch (err) {
          message.error((err as IErrorResponse).message);
          console.error((err as IErrorResponse).message);
          setCurrentFile({
            ...file.file,
            name: file.file.name,
            status: 'error',
            response: (err as IErrorResponse).message,
          });
        }
      }
    },
    [onUploadFile]
  );

  const uploadProps = useMemo(
    () => ({
      disabled,
      accept: Object.values(data?.allowedFileTypes || {})
        .flat()
        .map((fileType) => fileType?.value)
        .filter((fileType) => fileType),
      fileList: currentFile ? [currentFile] : [],
      listType: 'picture',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      beforeUpload: (file: any) => {
        if (
          !Object.values(data?.allowedFileTypes)
            .flat()
            .map((fileType) => fileType.value)
            .includes(file.type)
        ) {
          message.error(`'${file.name}' file type is not supported`);
          return false;
        }
        setCurrentFile({
          ...file,
          status: 'uploading',
          percent: 0,
          error: null,
        });
        return true;
      },
      customRequest: onUpload,
      onRemove: () => setCurrentFile(null),
    }),
    [currentFile, data, onUpload, disabled]
  );

  return (
    <>
      <div style={{ marginBottom: '0.5rem' }}>
        <QuestionCircleOutlined />
        &nbsp; Allowed file types:
        {Object.keys(data?.allowedFileTypes).map((allowedFileTypesCategory) => {
          const allowedFileTypesForCategory =
            data?.allowedFileTypes?.[allowedFileTypesCategory];
          if (!allowedFileTypesForCategory?.length) {
            return null;
          }
          return (
            <span style={{ display: 'block' }} key={allowedFileTypesCategory}>
              {allowedFileTypesCategory}
              :&nbsp;
              {allowedFileTypesForCategory
                .map((fileType) => fileType.label)
                .join(', ')}
            </span>
          );
        })}
      </div>
      {/* @ts-ignore */}
      <Upload {...uploadProps}>
        <Button icon={<UploadOutlined />} disabled={!!value}>
          Click to Upload
        </Button>
      </Upload>
    </>
  );
}
