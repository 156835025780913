/* eslint-disable @typescript-eslint/ban-ts-comment */

import { snakeCase } from 'lodash-es';
import {
  apiSpaceInvitationsPath,
  apiInvitationPath,
  apiInvitationVerifyPath,
  apiVisitInvitationBadgePath,
  apiVisitInvitationApproverPath,
  apiSpaceInvitationsHostsPath,
} from './constants';
import {
  IPaginationParams,
  IInvitation,
  IPaginationResponse,
  IInvitationDetailed,
  IInvitationListItem,
  IInvitationVerified,
  IInvitationBadge,
  IHost,
} from '../typings';
import { instance } from './client';
import { convertToSnakeCase, createPath } from '../utils';

export const addInvitation = async (spaceId: number, data: IInvitation) => {
  const res = await instance.client.post<{ item: IInvitationDetailed }>(
    createPath(apiSpaceInvitationsPath, { spaceId }),
    convertToSnakeCase(data)
  );
  return res.item;
};

export const getInvitations = async (
  spaceId: number,
  params?: IPaginationParams & {
    status: IInvitationListItem['status'];
    approvalStatus: IInvitationListItem['approvalStatus'];
    sortField: string;
    sortOrder: 'ascend' | 'descend';
    startGte: string;
    endLte: string;
    startLte: string;
    endGte: string;
    invitationTimeRange: string;
  }
) =>
  await instance.client.get<IPaginationResponse<IInvitationListItem>>(
    createPath(apiSpaceInvitationsPath, { spaceId }),
    {
      params: params && {
        search: params.search,
        page: params.page,
        status: params.status,
        approval_status: params.approvalStatus,
        ordering: params.sortField
          ? `${params.sortOrder === 'descend' ? '-' : ''}${snakeCase(
              params.sortField
            )}`
          : undefined,
        page_size: params.pageSize,
        start__gte: params.startGte,
        end__lte: params.endLte,
        start__lte: params.startLte,
        end__gte: params.endGte,
        invitation_time_range: params.invitationTimeRange,
      },
    }
  );

export const getInvitation = async (invitationId: number) => {
  const res = await instance.client.get<{ item: IInvitationDetailed }>(
    createPath(apiInvitationPath, { invitationId })
  );
  return res.item;
};

export const updateInvitation = async (
  invitationId: number,
  data: Partial<IInvitation>
) => {
  const res = await instance.client.put<{ item: IInvitationDetailed }>(
    createPath(apiInvitationPath, { invitationId }),
    convertToSnakeCase(data)
  );
  return res.item;
};

export const cancelInvitation = async (invitationId: number) => {
  await instance.client.put<{ item: IInvitationDetailed }>(
    createPath(apiInvitationPath, { invitationId }),
    convertToSnakeCase({ isCancelled: true })
  );
  return true;
};

export const verifyInvitation = async (
  spaceId: number,
  data: { invitationCode: string }
) =>
  await instance.client.get<IInvitationVerified>(
    `${createPath(apiInvitationVerifyPath, {
      spaceId,
    })}?invitation_code=${data?.invitationCode}`
  );

export const getVisitInvitationBadge = async (invitationCode: string) =>
  await instance.client.get<null, IInvitationBadge>(
    createPath(apiVisitInvitationBadgePath, { invitationCode }),
    {
      // @ts-ignore
      noAuth: true,
    }
  );

export const getVisitInvitationApproversData = async (
  invitationId: string,
  token: string
) =>
  await instance.client.get<null, IInvitationDetailed & { logoUrl?: string }>(
    `${createPath(apiVisitInvitationApproverPath, {
      invitationId,
    })}?token=${token}`,
    {
      // @ts-ignore
      noAuth: true,
    }
  );

export const approveVisitInvitation = async (
  invitationId: string,
  token: string,
  status: 'APPROVED' | 'REJECTED',
  statusMessage: string
) =>
  await instance.client.post(
    `${createPath(apiVisitInvitationApproverPath, {
      invitationId,
    })}?token=${token}`,
    convertToSnakeCase({ status, statusMessage }),
    {
      // @ts-ignore
      noAuth: true,
    }
  );

export const getHosts = async (spaceId: number, params?: IPaginationParams) =>
  await instance.client.get<IPaginationResponse<IHost>>(
    createPath(apiSpaceInvitationsHostsPath, { spaceId }),
    {
      params: convertToSnakeCase(params),
    }
  );
