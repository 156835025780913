import { FC, FormEvent } from 'react';
import { Button } from '@datapeace/1up-frontend-web-ui';
import { useMobileVerificationContainer } from '@datapeace/vms-web-containers';
import {
  Layout,
  Header,
  Content,
  NavButtons,
  Form,
  DynamicForm,
} from '@datapeace/vms-web-components';
import styles from '../custom-form-screen/custom-form-screen.module.scss';

export const MobileVerification: FC = () => {
  const {
    fields,
    formData,
    setFormData,
    onSubmit,
    resendDelay,
    handleResendOtp,
    isLoading,
    isSkipOtpButtonVisible,
    handleBackNavigation,
    isResendLimitExceeded,
  } = useMobileVerificationContainer();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <Layout>
      <Header />

      <Content>
        <div className={styles.Form}>
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <DynamicForm
              items={fields}
              values={formData}
              onChange={setFormData}
            />

            <div>
              <Button
                disabled={!!resendDelay || isResendLimitExceeded}
                onClick={handleResendOtp}
                size="small"
                type="ghost"
              >
                Resend OTP?
                {!!resendDelay && (
                  <span style={{ marginLeft: 4 }}>{`(${resendDelay})`}</span>
                )}
              </Button>
              {isSkipOtpButtonVisible ? (
                <Button
                  style={{ marginLeft: '10px' }}
                  onClick={() => onSubmit(null, true)}
                  size="small"
                  type="ghost"
                >
                  Skip OTP
                </Button>
              ) : (
                ''
              )}
            </div>
            <NavButtons loading={isLoading} onBack={handleBackNavigation} />
          </Form>
        </div>
      </Content>
    </Layout>
  );
};
