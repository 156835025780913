import React, { FC, useEffect, useState } from 'react';
import { Button } from 'antd';
import Webcam from 'react-webcam';
import clsx from 'clsx';
import {
  Typography,
  CameraOutlined,
  UndoOutlined,
  Flex,
  Card,
  IErrorResponse,
} from '../index';
import {
  getImageFromVideo,
  IBlobWithDataURL,
  objectFit,
  useMeasure,
  useTimeout,
  useIsMounted,
} from '@datapeace/1up-frontend-web-utils';
import styles from './camera.module.scss';

const errorMessageDurationSeconds = 5;

export interface CameraProps {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
  captureAreaBoxSize?: number;
  info?: React.ReactNode;
  hideCaptureButton?: boolean;
  videoConstraints?: MediaTrackConstraints;
  videoElementRef?: (videoElementRef: HTMLVideoElement | null) => void;
  onCapture?: (blob: IBlobWithDataURL | null) => void | Promise<void>;
  // onError?: (errorMessage: string) => void;
  triggerCaptureRef?: React.MutableRefObject<() => Promise<void> | void>;
  children?: React.ReactNode;
}

export const Camera: FC<CameraProps> = ({
  className,
  disabled,
  loading,
  style,
  /** capture area size relative to videoWidth */
  captureAreaBoxSize = 0,
  info,
  hideCaptureButton = false,
  videoConstraints,
  videoElementRef,
  onCapture,
  // onError,
  children,
  triggerCaptureRef,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [videoElement, setVideoElement] = useState<
    (Webcam & HTMLVideoElement) | null
  >(null);

  useTimeout(
    () => setErrorMessage(''),
    errorMessage ? errorMessageDurationSeconds * 1000 : null
  ); // clear error message after some time

  const [containerRef, { width: containerWidth, height: containerHeight }] =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore  (ignore till type issue fixed in library)
    useMeasure<HTMLElement | null>();

  // send videoElement only if in playing state
  useEffect(() => {
    if (!videoElementRef) return;

    if (!videoElement?.video) {
      videoElementRef(null);
      return;
    }

    if (!videoElement.video.paused) {
      videoElementRef(videoElement.video);
      return;
    }

    const handlePlayEvent = () => videoElementRef(videoElement.video);

    videoElement.video.addEventListener('playing', handlePlayEvent);

    // eslint-disable-next-line consistent-return
    return () =>
      videoElement.video?.removeEventListener('playing', handlePlayEvent);
  }, [videoElement, videoElementRef]);

  const isMountedRef = useIsMounted();

  const handleCapture = async () => {
    if (loading || disabled) return;
    if (previewImage) {
      // reset if already captured
      setPreviewImage('');
      if (onCapture) onCapture(null);
      return;
    }

    try {
      setIsLoading(true);

      if (!videoElement?.video) {
        throw new Error('Failed to capture! Please check camera permissions.');
      }

      const blob = await getImageFromVideo(videoElement.video);
      if (!isMountedRef.current) return;

      if (!blob?.size) {
        throw new Error('Failed to capture! Please check camera permissions.');
      }

      setPreviewImage(blob.dataURL);
      if (onCapture) await onCapture(blob);
    } catch (err) {
      if (!isMountedRef.current) return;
      setPreviewImage('');
      setErrorMessage((err as IErrorResponse).message);
    } finally {
      if (isMountedRef.current) setIsLoading(false);
    }
  };

  if (triggerCaptureRef) {
    // eslint-disable-next-line no-param-reassign
    triggerCaptureRef.current = handleCapture;
  }

  // captureAreaBox size is relative to videoWidth, convert it relative to actual element width
  const videoWidth = videoElement?.video?.videoWidth || containerWidth;
  const videoHeight = videoElement?.video?.videoHeight || containerHeight;
  const { width: objectFitWidth } = objectFit(false)(
    containerWidth,
    containerHeight,
    videoWidth,
    videoHeight
  );
  const stretchedCaptureAreaBoxSize =
    captureAreaBoxSize * (objectFitWidth / videoWidth);

  const captureAreaBorderVertical = Math.max(
    0,
    (containerHeight - stretchedCaptureAreaBoxSize) / 2
  );
  const captureAreaBorderHorizontal = Math.max(
    0,
    (containerWidth - stretchedCaptureAreaBoxSize) / 2
  );

  return (
    <div
      ref={containerRef}
      className={clsx(
        styles.CameraContainer,
        videoConstraints?.facingMode === 'user' && styles.CameraFlipped,
        className
      )}
      style={style}
    >
      {errorMessage && (
        <Flex className={styles.CameraError} center>
          <div>
            <Card
              title="Error while capturing face"
              actions={[
                <Button
                  onClick={() => setErrorMessage('')}
                  danger
                  icon={<UndoOutlined />}
                >
                  Retake photo
                </Button>,
              ]}
              type="danger"
              style={{ marginBottom: 8 }}
            >
              {errorMessage}
            </Card>
            <Typography.Text className={styles.CameraErrorText}>
              This error will close automatically
            </Typography.Text>
          </div>
        </Flex>
      )}

      {!errorMessage &&
        (previewImage ? (
          <img src={previewImage} alt="" />
        ) : (
          <>
            <Webcam
              videoConstraints={videoConstraints}
              audio={false}
              audioConstraints={false}
              ref={(el: (Webcam & HTMLVideoElement) | null) => {
                setVideoElement(el);
              }}
              className={styles.CameraFeed}
              autoPlay
              muted
              playsInline
            />
            {!!captureAreaBoxSize && (
              <div
                className={styles.CameraCaptureArea}
                style={{
                  borderWidth: `${captureAreaBorderVertical}px ${captureAreaBorderHorizontal}px`,
                }}
              />
            )}
            {info && (
              <Typography.Text className={styles.CameraInfo}>
                {info}
              </Typography.Text>
            )}
            {children}
          </>
        ))}
      <Button
        style={hideCaptureButton ? { display: 'none' } : {}}
        loading={isLoading || loading}
        icon={previewImage ? <UndoOutlined /> : <CameraOutlined />}
        htmlType="button"
        disabled={!(videoElement || previewImage) || disabled}
        onClick={handleCapture}
        className={styles.CaptureButton}
      >
        {' '}
      </Button>
    </div>
  );
};
