import { FC, FormEvent } from 'react';
import {
  Button,
  MobileOutlined,
  RedoOutlined,
} from '@datapeace/1up-frontend-web-ui';
import { useRegisterContainer } from '@datapeace/vms-web-containers';
import {
  NavButtons,
  Layout,
  Content,
  Header,
  Form,
  DynamicForm,
  CustomStatusCard,
} from '@datapeace/vms-web-components';
import formStyles from '../custom-form-screen/custom-form-screen.module.scss';
import { getMaskedMobileNumber } from '@datapeace/1up-frontend-shared-api';
import { useProcessDataContext, useRouter } from '@datapeace/vms-web-models';
import { ROUTES } from '@datapeace/vms-web-utils';

export const Register: FC = () => {
  const {
    isLoading,
    detailsFormSchema,
    handleNotYouClicked,
    formData,
    setFormData,
    onSubmit,
    showNotYouButton,
    handleGoBack,
    customErrorScreen,
    retry,
  } = useRegisterContainer();
  const { personData, faceData } = useProcessDataContext();
  const { setCurrentRoute } = useRouter();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  if (customErrorScreen?.displayText && customErrorScreen?.title) {
    return (
      <CustomStatusCard
        status="error"
        title={`${personData?.firstName} ${formData?.lastName}`}
        meta={
          formData?.mobileNumber && (
            <small>
              <MobileOutlined />
              &nbsp;
              {getMaskedMobileNumber(formData?.mobileNumber)}
            </small>
          )
        }
        actions={[
          <Button icon={<RedoOutlined />} onClick={() => retry()}>
            Retry
          </Button>,
        ]}
        message={customErrorScreen?.title || ''}
        avatar={faceData ? faceData?.dataUrl : ''}
        handleGoBack={() => setCurrentRoute(ROUTES.HOME)}
        content={customErrorScreen?.displayText}
      />
    );
  }

  return (
    <Layout>
      <Header />
      <Content>
        <div className={formStyles.Form}>
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <DynamicForm
              values={formData}
              items={detailsFormSchema}
              onChange={setFormData}
            />

            {showNotYouButton && (
              <Button
                title="Click here to checkin/checkout using mobile number"
                onClick={handleNotYouClicked}
                size="small"
                type="ghost"
              >
                Not you?
              </Button>
            )}

            <NavButtons loading={isLoading} onBack={handleGoBack} />
          </Form>
        </div>
      </Content>
    </Layout>
  );
};
