/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CUSTOM_FIELD_TYPES,
  normalizeValueObject,
} from '@datapeace/vms-web-components';
import { COMPARISON_OPERATORS } from './constants';
import { ProcessType } from '@datapeace/vms-web-models';

function isValueEqual(value = '', valueToCompare: any) {
  if (Array.isArray(value)) {
    return value.includes(valueToCompare);
  }

  return value === valueToCompare;
}

function isConditionTrue(
  values: { [key: string]: any },
  {
    name,
    value,
    operator = COMPARISON_OPERATORS.EQUAL,
  }: {
    name?: string;
    value?: any;
    operator?: ValueOf<typeof COMPARISON_OPERATORS>;
  } = {}
) {
  switch (operator) {
    case COMPARISON_OPERATORS.EQUAL:
      return isValueEqual(values[name || ''], value);

    case COMPARISON_OPERATORS.NOT_EQUAL:
      return !isValueEqual(values[name || ''], value);

    default:
      return true;
  }
}

function shouldRenderField(
  id: string,
  values: { [key: string]: any },
  conditions: any[] = [],
  allFields: any = {},
  processType: ProcessType | null
) {
  if (allFields[id] && allFields[id].processType !== processType) {
    return false;
  }

  // check if conditions depend upon fields which have been deleted
  // and ignore those conditions
  const validConditions = conditions
    .map((condition) => {
      return condition.filter((subCondition: any) => {
        return (
          allFields[subCondition.name] !== undefined ||
          (allFields[subCondition.name] &&
            allFields[subCondition.name].isVisible === false)
        );
      });
    })
    .filter((condition) => condition.length !== 0);

  if (!validConditions.length) {
    return true;
  }

  // Checks if condition depends upon other (previously checked) fields which are not visible.
  const conditionFieldNames = Array.from(
    new Set(
      validConditions
        .reduce((a, b) => a.concat(b), [])
        .map((item: any) => item.name)
    )
  );

  // If all of the fields from same process type are hidden then do not show current field
  if (
    conditionFieldNames.every(
      (field) =>
        allFields[field as string] &&
        allFields[field as string].isVisible === false &&
        allFields[field as string].processType === processType
    )
  ) {
    return false;
  }

  /**
   * any of the items in outer array can be true (OR comparison)
   * all the items in inner array should be true (AND comparison)
   */
  return validConditions.some((condition) =>
    condition.every((i: any) => isConditionTrue(values, i))
  );
}

/**
 *
 * @param {*} returnAllFeilds will only 'set isVisible property' to all fields and skip filtering out of fields not visible
 */
export function getVisibleFields(
  initialFields: any[],
  values: { [key: string]: string },
  processType: ProcessType | null,
  returnAllFeilds = false
) {
  const fields: { [key: string]: any } = {};
  initialFields.forEach((field) => {
    fields[field.name as string] = {
      ...field,
      isVisible: true,
    };
  });

  initialFields.forEach((field) => {
    fields[field.name] = {
      ...field,
      isVisible: shouldRenderField(
        field.name,
        values,
        field.conditions,
        fields,
        processType
      ),
    };
  });

  const fieldsList = Object.values(fields);
  return returnAllFeilds
    ? fieldsList
    : fieldsList.filter((field) => field.isVisible);
}

function isEmptyValues(value: any) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

export function validateFields(
  fields: any[] = [],
  values: { [key: string]: any } | null
) {
  fields.forEach(({ name, label, required, type, fields: groupFields }) => {
    const value = (values || {})[name];
    if (type === CUSTOM_FIELD_TYPES.GROUP && value) {
      value.forEach((val: any) =>
        validateFields(groupFields, normalizeValueObject(val, groupFields))
      );
    } else if (required && isEmptyValues(value)) {
      throw new Error(`'${label}' is required.`);
    }
  });
}
