import {
  IOrganizationConfig,
  IOrganizationDetailed,
  IPaginationParams,
  IPaginationResponse,
  IPendingInvite,
  ISpace,
} from '../typings';
import { convertToSnakeCase, createPath } from '../utils';
import { instance } from './client';
import {
  apiMyOrganizationsPath,
  apiOrganizationConfigPath,
  apiOrganizationSpacesPath,
} from './constants';

export const getMyOrganizations = async () => {
  const res = await instance.client.get<{
    items: IOrganizationDetailed[];
    pendingInvitations: IPendingInvite[];
  }>(createPath(apiMyOrganizationsPath));
  return res;
};

export const getOrganizationConfig = async (organizationId: number) =>
  instance.client.get<IOrganizationConfig>(
    createPath(apiOrganizationConfigPath, { organizationId })
  );

export const getOrganizationSpaces = async (
  organizationId: number,
  params?: IPaginationParams & {
    active?: number;
  }
) =>
  await instance.client.get<IPaginationResponse<ISpace>>(
    createPath(apiOrganizationSpacesPath, { organizationId }),
    convertToSnakeCase({ params })
  );
