import { Content, Footer, Layout } from '@datapeace/vms-web-components';
import {
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Radio,
  Select,
  Switch,
  Typography,
  message,
} from 'antd';
import styles from './settings-screen.module.scss';
import {
  ArrowLeftOutlined,
  Button,
  CaretDownOutlined,
  CheckOutlined,
  CloseOutlined,
  DashboardOutlined,
  LogoutOutlined,
  QrcodeOutlined,
  ReloadOutlined,
  ShakeOutlined,
  SyncOutlined,
} from '@datapeace/1up-frontend-web-ui';
import { useConfig } from '@datapeace/vms-web-hooks';
import {
  BRAND_NAME,
  ISettings,
  ROUTES,
  UPGRADE_MESSAGE,
  VMS_MODES,
  defaultSettings,
  detectionScoreThresholdRange,
  getRestrictedVMSModeFromStorage,
  getSettingsFromLocalStorage,
  onLogout,
  redirectToLogout,
  saveSettingsIntoLocalStorage,
} from '@datapeace/vms-web-utils';
import { useProfileContext, useRouter } from '@datapeace/vms-web-models';
import {
  FREQUENCY,
  getRestrictedModeFromStorage,
} from '@datapeace/1up-frontend-shared-api';
import { DistanceCallibrator } from './distance-callibrator';
import { useEffect, useState } from 'react';
import {
  getDeviceGeolocation,
  getPermissionState,
} from '@datapeace/1up-frontend-web-utils';
import Paragraph from 'antd/lib/typography/Paragraph';

const { Text } = Typography;
const handleRefresh = () => window.location.reload();

const menu = (
  <Menu
    onClick={async () => {
      const hide = message.loading('Logging Out', 0);
      await onLogout();
      hide();
      redirectToLogout();
    }}
  >
    <Menu.Item key="1">
      <LogoutOutlined style={{ marginRight: '5px' }} />
      Logout
    </Menu.Item>
  </Menu>
);

export const Settings = () => {
  const [isCallibrationWindowOpen, setCallibrationWindowOpen] = useState(false);
  const [hasLocationPermission, setHasLocationPermission] = useState(true);
  const { email } = useProfileContext();
  const { setCurrentRoute } = useRouter();
  const [form] = Form.useForm();
  const restrictedMode = getRestrictedModeFromStorage();
  const restrictedModeVMSMode = getRestrictedVMSModeFromStorage();

  const {
    currentOrganization,
    clearCurrentOrganization,
    organizations,
    clearCurrentSpace,
    currentSpace,
    portalLogoUrl,
    visitInvitationEnabled,
    isMobilePlan,
    temperatureFrequency,
    visitorAppEnabled,
    temperatureDevices,
  } = useConfig();
  const features = currentOrganization?.features;

  // pre-fill existing settings from localstorage
  useEffect(() => {
    const settingData = getSettingsFromLocalStorage(email);

    form.setFieldsValue({
      cameraFacing: settingData.cameraFacing || defaultSettings.cameraFacing,
      compressImage: settingData.compressImage ?? true,
      vmsMode:
        restrictedMode && restrictedModeVMSMode === 'NORMAL'
          ? VMS_MODES.NORMAL
          : settingData.vmsMode || defaultSettings.vmsMode,
      detectionScoreThreshold:
        settingData.detectionScoreThreshold ||
        defaultSettings.detectionScoreThreshold,
      detectionDistance:
        settingData.detectionDistance || defaultSettings.detectionDistance,
      pmaCode: settingData.pmaCode || '',
      temperatureFetchMaxTime:
        settingData.temperatureFetchMaxTime ||
        defaultSettings.temperatureFetchMaxTime,
      successScreenAutoClose: settingData.successScreenAutoClose ?? true,
      temperatureDeviceId: settingData.temperatureDeviceId,
      successScreenAutoCloseTimeout:
        settingData.successScreenAutoCloseTimeout ||
        defaultSettings.successScreenAutoCloseTimeout,
      displaySpaceSelectionOnHomeScreen: !restrictedMode
        ? settingData.displaySpaceSelectionOnHomeScreen ?? false
        : false,
    });
  }, [
    form,
    email,
    visitInvitationEnabled,
    visitorAppEnabled,
    restrictedMode,
    restrictedModeVMSMode,
  ]);

  /* fetch location  permission status */
  useEffect(() => {
    async function getPermissionStatus() {
      const hasPermission =
        (await getPermissionState('geolocation')) === 'granted';
      setHasLocationPermission(hasPermission);
    }

    getPermissionStatus();
  }, []);

  useEffect(() => {
    const settingData = getSettingsFromLocalStorage(email) as ISettings;

    if (!temperatureDevices) {
      saveSettingsIntoLocalStorage(email, {
        ...settingData,
        temperatureDeviceId: undefined,
      });
      return;
    }

    if (
      !settingData.temperatureDeviceId &&
      temperatureDevices &&
      temperatureDevices.length === 1
    ) {
      saveSettingsIntoLocalStorage(email, {
        ...settingData,
        temperatureDeviceId: temperatureDevices[0].id,
      });
    } else if (
      !temperatureDevices.find(
        (device) => device.id === settingData.temperatureDeviceId
      )
    ) {
      saveSettingsIntoLocalStorage(email, {
        ...settingData,
        temperatureDeviceId: undefined,
      });
    }
  }, [email, temperatureDevices]);

  const handleSettingsChange = (settings: ISettings) => {
    // save only settings to state (received prop can have other values too)
    saveSettingsIntoLocalStorage(email, {
      cameraFacing: settings.cameraFacing,
      compressImage: settings.compressImage,
      detectionScoreThreshold: settings.detectionScoreThreshold,
      detectionDistance: settings.detectionDistance,
      vmsMode:
        restrictedMode && restrictedModeVMSMode === 'NORMAL'
          ? VMS_MODES.NORMAL
          : settings.vmsMode,
      pmaCode: settings.pmaCode,
      temperatureFetchMaxTime: settings.temperatureFetchMaxTime,
      successScreenAutoClose: settings.successScreenAutoClose,
      temperatureDeviceId: settings.temperatureDeviceId,
      successScreenAutoCloseTimeout: settings.successScreenAutoCloseTimeout,
      displaySpaceSelectionOnHomeScreen: !restrictedMode
        ? settings.displaySpaceSelectionOnHomeScreen
        : false,
    });
  };

  const handleDetetionCallibrationDone = (distance: number | null) => {
    saveSettingsIntoLocalStorage(email, {
      ...(getSettingsFromLocalStorage(email) as ISettings),
      detectionDistance: distance || 50,
    });
    form.setFieldsValue({
      ...form.getFieldsValue(),
      detectionDistance: distance || 50,
    });
    setCallibrationWindowOpen(false);
  };

  const handleGetLocationPermission = async () => {
    try {
      await getDeviceGeolocation();
      setHasLocationPermission(true);
    } catch (err) {
      message.error('Location permission has been blocked by you!');
      setHasLocationPermission(false);
    }
  };

  return (
    <Layout>
      <div className={styles.HeaderContent}>
        <div className={styles.HeaderLeft}>
          <img
            className={styles.HeaderBrandImage}
            src={portalLogoUrl}
            alt={BRAND_NAME}
          />
          <Text className={styles.HeaderText}>Settings</Text>
        </div>

        <div>
          <Text>{email}</Text>
          <Dropdown.Button
            onClick={handleRefresh}
            overlay={menu}
            trigger={['click', 'hover']}
            icon={<CaretDownOutlined />}
          >
            <ReloadOutlined />
            Refresh
          </Dropdown.Button>

          <Button onClick={() => setCurrentRoute(ROUTES.HOME)} type="primary">
            Close
            <CloseOutlined />
          </Button>
        </div>
      </div>

      <Content className={styles.Content}>
        <Form
          className={styles.Form}
          form={form}
          layout="horizontal"
          onValuesChange={(_, allValues) =>
            handleSettingsChange(allValues as ISettings)
          }
        >
          {!!organizations && !!currentOrganization && (
            <>
              <Form.Item label="Organization">
                <Text>
                  {currentOrganization.name}
                  &nbsp;
                  {organizations.length > 1 && !restrictedMode && (
                    <Button
                      type="link"
                      onClick={() => clearCurrentOrganization()}
                    >
                      Change
                    </Button>
                  )}
                </Text>
              </Form.Item>

              {!!currentSpace && (
                <Form.Item label="Space">
                  <Paragraph
                    ellipsis={{
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      suffix:
                        currentOrganization.spaces.length > 1 &&
                        !restrictedMode ? (
                          <Button
                            type="link"
                            onClick={() => clearCurrentSpace()}
                          >
                            Change
                          </Button>
                        ) : null,
                    }}
                    style={{ whiteSpace: 'normal' }}
                  >
                    {currentSpace.name}
                  </Paragraph>
                </Form.Item>
              )}
            </>
          )}

          <Form.Item
            name="successScreenAutoClose"
            label="Success screen autoclose"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) =>
              prev.successScreenAutoClose !== curr.successScreenAutoClose
            }
          >
            {({ getFieldValue }) => {
              return (
                <Form.Item
                  name="successScreenAutoCloseTimeout"
                  label="Success screen autoclose timeout (seconds)"
                  hidden={!getFieldValue('successScreenAutoClose')}
                >
                  <InputNumber
                    style={{ width: 100 }}
                    min={3}
                    max={15}
                    step={1}
                    placeholder="In Seconds"
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Divider />

          <Form.Item name="cameraFacing" label="Camera facing">
            <Radio.Group>
              <Radio.Button value="user">User (Front camera)</Radio.Button>
              <Radio.Button value="environment">
                Environment (Rear camera)
              </Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="compressImage"
            label="Compress image before uploading"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Divider />

          <Form.Item label="Detection Score Threshold">
            <Form.Item name="detectionScoreThreshold" noStyle>
              <InputNumber
                style={{ width: 80 }}
                min={detectionScoreThresholdRange.min}
                max={detectionScoreThresholdRange.max}
                step={5}
                placeholder={defaultSettings.detectionScoreThreshold.toString()}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item label="Calibrate detection distance">
            <Form.Item name="detectionDistance" noStyle>
              <InputNumber
                min={50}
                max={500}
                style={{ width: 80, marginRight: 10, opacity: 0.8 }}
                placeholder="Min Size Threshold"
              />
            </Form.Item>

            <Button onClick={() => setCallibrationWindowOpen(true)}>
              Calibrate
            </Button>
            {isCallibrationWindowOpen && (
              <DistanceCallibrator
                cameraFacing={form.getFieldValue('cameraFacing')}
                onCallibrate={handleDetetionCallibrationDone}
                onCancel={() => setCallibrationWindowOpen(false)}
              />
            )}
          </Form.Item>

          <Form.Item name="vmsMode" label="VMS mode">
            <Select
              style={{ textAlign: 'left', width: 220 }}
              disabled={restrictedMode && restrictedModeVMSMode === 'NORMAL'}
            >
              <Select.Option value={VMS_MODES.NORMAL}>
                <CheckOutlined />
                {' Normal Mode'}
              </Select.Option>
              <Select.Option
                title={features?.spacesVmsAppQuickMode ? '' : UPGRADE_MESSAGE}
                disabled={!features?.spacesVmsAppQuickMode}
                value={VMS_MODES.QUICK}
              >
                <DashboardOutlined />
                {` Quick Mode (${isMobilePlan ? 'Mobile' : 'Face'})`}
              </Select.Option>
              {!isMobilePlan && (
                <Select.Option
                  title={
                    features?.spacesVmsAppAutoCaptureMode ? '' : UPGRADE_MESSAGE
                  }
                  disabled={!features?.spacesVmsAppAutoCaptureMode}
                  value={VMS_MODES.AUTO_CAPTURE}
                >
                  <SyncOutlined />
                  {' Auto capture (Face)'}
                </Select.Option>
              )}
              {visitInvitationEnabled && (
                <Select.Option
                  title={
                    features?.spacesVmsAppInvitedOnlyMode ? '' : UPGRADE_MESSAGE
                  }
                  disabled={!features?.spacesVmsAppInvitedOnlyMode}
                  value={VMS_MODES.QR_ONLY}
                >
                  <QrcodeOutlined />
                  {' Invited only (QR) '}
                </Select.Option>
              )}
              {visitorAppEnabled && (
                <Select.Option
                  title={
                    features?.spacesVmsAppContactlessMode ? '' : UPGRADE_MESSAGE
                  }
                  disabled={!features?.spacesVmsAppContactlessMode}
                  value={VMS_MODES.CONTACT_LESS}
                >
                  <ShakeOutlined />
                  {' Contactless '}
                </Select.Option>
              )}
            </Select>
          </Form.Item>

          <Divider />
          <Form.Item name="pmaCode" label="PMA Code">
            <Input
              style={{ width: 250 }}
              placeholder="Put the same code as used in PMA"
            />
          </Form.Item>

          {temperatureFrequency !== FREQUENCY.NEVER &&
            features?.organizationsTemperatureDeviceManage && (
              <>
                <Divider />
                <Form.Item label="Waiting time for temperature capture (in sec)">
                  <Form.Item name="temperatureFetchMaxTime" noStyle>
                    <InputNumber
                      min={5}
                      style={{ width: 80, marginRight: 10, opacity: 0.8 }}
                    />
                  </Form.Item>
                </Form.Item>
                <Form.Item label="Temperature Device">
                  <Form.Item name="temperatureDeviceId" noStyle>
                    <Select
                      disabled={!temperatureDevices?.length}
                      placeholder={
                        temperatureDevices?.length
                          ? 'Select Device'
                          : 'No device found'
                      }
                      style={{ textAlign: 'left', width: 220 }}
                    >
                      {temperatureDevices?.map((device) => (
                        <Select.Option
                          key={`temperatureDevice_${device.id}`}
                          value={device.id}
                        >
                          {device.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form.Item>
              </>
            )}
          <Form.Item
            name="displaySpaceSelectionOnHomeScreen"
            label="Display Space Selection on Home Screen"
            valuePropName="checked"
            hidden={restrictedMode}
          >
            <Switch disabled={restrictedMode} />
          </Form.Item>

          {!hasLocationPermission && (
            <>
              <Divider />
              <Form.Item label="Grant GeoLocation Permission">
                <Button onClick={handleGetLocationPermission}>Grant</Button>
              </Form.Item>
            </>
          )}
        </Form>
      </Content>

      <Footer className={styles.Footer}>
        <Button
          title="Go back"
          onClick={() => setCurrentRoute(ROUTES.HOME)}
          shape="circle"
          icon={<ArrowLeftOutlined />}
        />
        <Text>{__VERSION__}</Text>
      </Footer>
    </Layout>
  );
};
