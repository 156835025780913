import React, { FC, useState, useEffect, useMemo } from 'react';
import { Input, Select } from 'antd';
import {
  getCountriesData,
  separateCallingCodeAndPhone,
  getLabelForCallingCode,
  getCallingCodeFromLabel,
  ICountryDetails,
} from '@datapeace/1up-frontend-shared-api';
import { IErrorResponse, toast } from '@datapeace/1up-frontend-web-ui';
import styles from './index-dynamic-field.module.scss';

export interface IPhoneNumberProps {
  name: string;
  onChange: (name: string, value: string) => void;
  defaultPrefix?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
}

const getLabelWithFlag = (flagURL: string, label = '') => {
  return (
    <span className={styles.LabelWithFlag}>
      <span>
        <img src={flagURL} alt="" />
      </span>
      <span>{label}</span>
    </span>
  );
};

export const PhoneNumber: FC<IPhoneNumberProps> = ({
  name,
  defaultPrefix = '91',
  placeholder,
  onChange,
  value,
  disabled,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [{ callingCodes, countriesByCallingCodes }, setCountriesData] =
    useState<{
      callingCodes: [string, string][];
      countriesByCallingCodes: { [key: string]: ICountryDetails };
    }>({ callingCodes: [], countriesByCallingCodes: {} });

  const [prefixLabel, phoneValue] = useMemo(() => {
    const [prefix, phone] = separateCallingCodeAndPhone(value || '');
    return [
      getLabelForCallingCode(prefix, callingCodes) ||
        getLabelForCallingCode(defaultPrefix, callingCodes) ||
        '',
      phone,
    ];
  }, [value, callingCodes, defaultPrefix]);

  const handlePrefixChange = (prefix: string) => {
    onChange(name, `+${getCallingCodeFromLabel(prefix)} ${phoneValue}`);
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/\D/g.test(e.target.value)) {
      // do not accept if contains non-numeric value
      return;
    }
    onChange(
      name,
      `+${getCallingCodeFromLabel(prefixLabel)} ${e.target.value}`
    );
  };

  useEffect(() => {
    async function fetchCountriesData() {
      setIsLoading(true);

      try {
        const data = await getCountriesData();
        setCountriesData({
          callingCodes: data.callingCodeChoices,
          countriesByCallingCodes: data.countriesByCallingCodes,
        });
      } catch (error) {
        toast.error(error as IErrorResponse);
      }

      setIsLoading(false);
    }

    fetchCountriesData();
  }, []);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const phoneLengthRange = (
    countriesByCallingCodes[getCallingCodeFromLabel(prefixLabel)] || {}
  ).mobileNumberNsnDigits;

  const hint = phoneLengthRange
    ? `${phoneLengthRange.min}${
        phoneLengthRange.max !== phoneLengthRange.min
          ? ` to ${phoneLengthRange.max}`
          : ''
      } digit mobile number`
    : 'mobile no. without prefix';

  return (
    <Input.Group className={styles.PhoneNumber} compact>
      <Select
        loading={isLoading}
        disabled={isLoading || disabled}
        className={styles.Dropdown}
        optionFilterProp="title"
        placeholder="Prefix"
        showSearch
        value={prefixLabel}
        onChange={handlePrefixChange}
        size="large"
        dropdownMatchSelectWidth={false}
        onDropdownVisibleChange={(change) => setIsDropdownOpen(change)}
      >
        {(callingCodes || []).map(([key, label]) => {
          const flagURL: string =
            (countriesByCallingCodes[key] || {}).flag || '';
          return (
            <Select.Option key={label} title={label} value={label}>
              {isDropdownOpen
                ? getLabelWithFlag(flagURL, label)
                : getLabelWithFlag(flagURL, `+${key}`)}
            </Select.Option>
          );
        })}
      </Select>
      <Input
        type="tel"
        className={styles.Text}
        disabled={isLoading || disabled}
        size="large"
        name={name}
        value={phoneValue}
        onChange={handleNumberChange}
        placeholder={placeholder || hint}
      />
    </Input.Group>
  );
};
