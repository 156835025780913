import React, { FC, FormEvent } from 'react';
import clsx from 'clsx';
import { useScreeningFormContainer } from '@datapeace/vms-web-containers';
import {
  Layout,
  Header,
  Content,
  NavButtons,
  Form,
  DynamicForm,
} from '@datapeace/vms-web-components';
import * as styles from '../custom-form-screen/custom-form-screen.module.scss';

export const ScreeningForm: FC = () => {
  const { title, values, onChange, onSubmit, handleGoBack, fields } =
    useScreeningFormContainer();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <Layout>
      <Header />

      <Content>
        <div className={clsx(styles.Form, styles.Large)}>
          {title && <h2>{title}</h2>}
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <DynamicForm values={values} items={fields} onChange={onChange} />
            <NavButtons onBack={handleGoBack} />
          </Form>
        </div>
      </Content>
    </Layout>
  );
};
