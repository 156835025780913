import { useState } from 'react';
import {
  useIsWindowFocused,
  useTimeout,
} from '@datapeace/1up-frontend-web-utils';
import { Modal, Alert, FaceCamera } from '@datapeace/1up-frontend-web-ui';
import {
  DETECTION_DOWNSCALING,
  defaultSettings,
} from '@datapeace/vms-web-utils';

interface Props {
  cameraFacing?: 'user' | 'environment';
  onCallibrate: (callibrationResult: number | null) => void;
  onCancel: () => void;
}

export const DistanceCallibrator = ({
  cameraFacing = 'user',
  onCallibrate,
  onCancel,
}: Props) => {
  const [isFaceDetected, setIsFaceDetected] = useState(false);
  const [faceSizeValue, setFaceSizeValue] = useState(
    defaultSettings.detectionDistance
  );

  const { detectionScoreThreshold } = defaultSettings;

  const handleCallibrate = () => {
    onCallibrate(faceSizeValue);
  };

  const isWindowFocused = useIsWindowFocused(true);

  useTimeout(() => setIsFaceDetected(false), isFaceDetected ? 200 : null);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Modal
      bodyStyle={{ padding: 4 }}
      visible
      onOk={handleCallibrate}
      okText="Callibrate"
      okButtonProps={{ disabled: !isFaceDetected }}
      onCancel={onCancel}
      centered
    >
      {isWindowFocused ? (
        <FaceCamera
          videoConstraints={{ facingMode: cameraFacing }}
          onDetection={(status, detection) => {
            setFaceSizeValue(
              Math.round(detection.scale * DETECTION_DOWNSCALING)
            );
            setIsFaceDetected(status === 'YES');
          }}
          hideCaptureButton
          captureAreaOffset={0}
          detectionScoreThreshold={detectionScoreThreshold}
          validRangeMinFaceSize={detectionScoreThreshold}
          captureAreaBoxSize={0}
          autoCapture={false}
          downscaleBy={DETECTION_DOWNSCALING}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
          }}
        >
          <Alert
            message="Tap on screen to see camera view!"
            description="This window is out of focus. Tap here to see the camera view."
            type="warning"
            showIcon
          />
        </div>
      )}
    </Modal>
  );
};
