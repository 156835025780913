import { FC, useState } from 'react';
import { Spin } from 'antd';
import {
  Button,
  Alert,
  Camera,
  FaceCamera,
} from '@datapeace/1up-frontend-web-ui';
import { SettingOutlined, LoadingOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import QRCode from 'qrcode.react';
import Media from 'react-media';
import { useContactLessWelcomeContainer } from '@datapeace/vms-web-containers';
import { Layout, Header, Content, Footer } from '@datapeace/vms-web-components';
import { useProfileContext } from '@datapeace/vms-web-models';
import {
  BRAND_NAME,
  DETECTION_DOWNSCALING,
  VISITOR_APP_DOMAIN_TEXT,
  VISITOR_APP_URL,
  getSettingsFromLocalStorage,
} from '@datapeace/vms-web-utils';
import captureStyles from '../capture-screen/capture-screen.module.scss';
import styles from './contact-less-welcome-screen.module.scss';
import { useConfig } from '@datapeace/vms-web-hooks';
import {
  useIsWindowFocused,
  useQrReader,
  useWindowSize,
} from '@datapeace/1up-frontend-web-utils';

export const ContactLessWelcome: FC = () => {
  const { email } = useProfileContext();
  const { cameraFacing } = getSettingsFromLocalStorage(email);

  const {
    portalTitle,
    onSettingsButtonClick,
    detectionDistance,
    detectionScoreThreshold,
    onFaceCapture,
    onQrFound,
    capturedImage,
    isLoading,
  } = useContactLessWelcomeContainer();

  const { currentSpace } = useConfig();
  const isWindowFocused = useIsWindowFocused(true);

  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(
    null
  );
  const [qrReaderCanvasElement, setQrReaderCanvasElement] =
    useState<HTMLCanvasElement | null>(null);

  useQrReader(videoElement, qrReaderCanvasElement, true, onQrFound);

  const { width, height } = useWindowSize();
  const canvasWidth = Math.round(width / 2);
  const canvasHeight = Math.round(height / 2);

  return (
    <Layout showBackground>
      <Header showHome={false} />
      {portalTitle && <h2 className={styles.Title}>{portalTitle}</h2>}
      <Content className={styles.Content}>
        <section className={styles.Section}>
          {isWindowFocused ? (
            <>
              <div className={clsx(captureStyles.Content, styles.QrScanner)}>
                {capturedImage && <img src={capturedImage.dataURL} alt="" />}

                {!capturedImage && (
                  <>
                    {!onFaceCapture ? (
                      <Camera
                        videoConstraints={{
                          facingMode: cameraFacing || 'user',
                        }}
                        captureAreaBoxSize={0}
                        videoElementRef={(el) => setVideoElement(el)}
                        info=""
                        hideCaptureButton
                      />
                    ) : (
                      <FaceCamera
                        videoConstraints={{
                          facingMode: cameraFacing || 'user',
                        }}
                        autoCapture
                        shouldDrawDetection
                        validRangeMinFaceSize={detectionDistance}
                        detectionScoreThreshold={detectionScoreThreshold}
                        downscaleBy={DETECTION_DOWNSCALING}
                        loading={isLoading}
                        onCapture={onFaceCapture}
                        captureAreaBoxSize={0}
                        videoElementRef={(el) => setVideoElement(el)}
                        hideCaptureButton
                      />
                    )}
                    <canvas
                      width={canvasWidth}
                      height={canvasHeight}
                      ref={setQrReaderCanvasElement}
                    />
                    {!onFaceCapture && (
                      <div
                        className={clsx(captureStyles.QrFrame, styles.QrFrame)}
                      />
                    )}
                  </>
                )}
              </div>

              <p className={styles.SectionInfo}>
                {isLoading ? (
                  <Spin indicator={<LoadingOutlined />} size="large" />
                ) : (
                  'Show QR Code for Checkin/Checkout'
                )}
              </p>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '1rem',
              }}
            >
              <Alert
                message="Tap on screen to see camera view!"
                description="This window is out of focus. Tap here to see the camera view."
                type="warning"
                showIcon
              />
            </div>
          )}
        </section>

        <section className={clsx(styles.QrImageSection, styles.Section)}>
          <Media query="(max-width: 768px)">
            {(matches) => (
              <QRCode
                value={`${VISITOR_APP_URL}${currentSpace?.id}`}
                size={matches ? 160 : 372}
                level="L"
                className={styles.QrImage}
                includeMargin
              />
            )}
          </Media>
          <p className={styles.SectionInfo}>
            Scan QR Code
            <span>OR</span>
            Open
            <strong
              className={styles.VisitUrl}
            >{` ${VISITOR_APP_DOMAIN_TEXT}`}</strong>
          </p>
        </section>
      </Content>
      <Footer>
        <div>
          <Button
            icon={<SettingOutlined />}
            shape="circle"
            onClick={onSettingsButtonClick}
          />
          <span>{`Powered by ${BRAND_NAME}`}</span>
        </div>
      </Footer>
    </Layout>
  );
};
