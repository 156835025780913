import React, { FC } from 'react';
import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons';
import { Button } from '@datapeace/1up-frontend-web-ui';
import clsx from 'clsx';
import { useRouter, RoutesValues } from '@datapeace/vms-web-models';
import { BRAND_NAME, ROUTES } from '@datapeace/vms-web-utils';
import { useConfig } from '@datapeace/vms-web-hooks';
import styles from './layout.module.scss';

interface Props {
  className?: string;
  backLink?: RoutesValues;
  showHome?: boolean;
}

export const Header: FC<Props> = ({ className, backLink, showHome = true }) => {
  const { portalLogoUrl } = useConfig();
  const { setCurrentRoute } = useRouter();

  return (
    <div className={clsx(styles.Header, className)}>
      {backLink && (
        <Button
          title="Go back"
          onClick={() => setCurrentRoute(backLink)}
          size="large"
          shape="circle"
          className={styles.BackButton}
          icon={<ArrowLeftOutlined />}
        />
      )}

      {portalLogoUrl && (
        <img
          className={styles.BrandImage}
          src={portalLogoUrl}
          alt={BRAND_NAME}
        />
      )}

      {showHome && (
        <Button
          title="Go to welcome page"
          onClick={() => setCurrentRoute(ROUTES.HOME)}
          size="large"
          shape="circle"
          className={styles.HomeButton}
          icon={<HomeOutlined />}
        />
      )}
    </div>
  );
};
