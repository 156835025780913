import { IFormField, IFormFieldData } from '@datapeace/1up-frontend-shared-api';
import { Col, Form, Row } from 'antd';
import { Button } from '@datapeace/1up-frontend-web-ui';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { DynamicForm } from '@datapeace/vms-web-components';
import {
  GroupInputProvider,
  useGroupInputContext,
} from './group-input-context-dynamic-field';
import styles from './index-dynamic-field.module.scss';
import { getValueObject, normalizeValueObject } from '.';

function GroupInput() {
  const { fields, value, handleAdd, handleChange, settings, handleRemove } =
    useGroupInputContext();
  return (
    <div className={styles.Group__Container}>
      <Row>
        <Col span={24}>
          {value &&
            value.map((val, i) => (
              <Row className={styles.Group__Item}>
                <Col span={24}>
                  <Row>
                    <Col span={24} className={styles.Group__Item__Header}>
                      <span style={{ marginLeft: '5px' }}>
                        {i + 1}
                        <span>.</span>
                      </span>
                      <div>
                        <Button
                          icon={<DeleteOutlined />}
                          type="link"
                          danger
                          onClick={() => handleRemove(i)}
                          disabled={!!(value.length <= settings.minItemsLimit)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form
                        layout="vertical"
                        className={styles.Group__Item__Form}
                      >
                        <DynamicForm
                          items={fields}
                          values={normalizeValueObject(val)}
                          onChange={(v) => {
                            handleChange(
                              i,
                              fields.map(
                                (field) =>
                                  getValueObject(
                                    field,
                                    v[field.name]
                                  ) as IFormFieldData
                              )
                            );
                          }}
                        />
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
        </Col>
        <Col span={24} className={styles.Group__AddBtnContainer}>
          <Button
            disabled={!!value && !!(value.length >= settings.maxItemsLimit)}
            type="dashed"
            icon={<PlusOutlined />}
            onClick={handleAdd}
          >
            Add
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export interface IGroupProps {
  name: string;
  fields: IFormField[];
  settings: {
    minItemsLimit: number;
    maxItemsLimit: number;
  };
  value: {
    data: IFormFieldData[];
  }[];
  onChange: (
    name: string,
    value: {
      data: IFormFieldData[];
    }[]
  ) => void;
}

export function Group(props: IGroupProps) {
  return (
    <GroupInputProvider {...props}>
      <GroupInput />
    </GroupInputProvider>
  );
}
